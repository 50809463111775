import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public userData: any;
  public isAdmin = false;
  private adminEmails = [
    'acordoba@diagnosticsolutionsgroup.com',
    'jscrocco@diagnosticsolutionsgroup.com',
    'kweller@diagnosticsolutionsgroup.com'
  ];
  constructor(private router: Router) { }

  async ngOnInit() {
    try {
      this.userData = await Auth.currentAuthenticatedUser()
      if (this.adminEmails.includes(this.userData.attributes.email)) {
        this.isAdmin = true;
      } 
    }
    catch(err) {
      console.log(err);
      this.router.navigate(['']);
    }
  }

  redirect(path) {
    const newPath = path.split('/');
    this.router.navigate(newPath);
  }

}
