import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PaymentMethod } from 'src/app/models/customer.model';
import { CreditCardValidator } from 'src/app/services/card.validators';

@Component({
  selector: 'app-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.scss']
})
export class PaymentDetailsComponent implements OnInit, OnDestroy {

  @Input() newMethodLabel = '';
  @Input() allowBankAccounts = false;
  @Input() allowCreditCards = true;
  @Input() formType?: "card" | "bankAccount" = null;
  @Input() showCancelButton = false;
  @Input() cardCodeDisabled = false;
  @Input() allowUpdateDefaultPaymentMethod = true;
  @Input() billingAddress = null;
  @Input() reset$ = new Subject<void>();
  @Output()onPaymentMethodDetailsChanged = new EventEmitter<{valid: boolean, details: PaymentMethod}>();

  detailsForm = new FormGroup({
    method: new FormGroup({}),
    isDefault: new FormControl(false),
    billTo: new FormGroup({
      zip: new FormControl('', { validators: [Validators.required, Validators.minLength(5), Validators.maxLength(5)] })
    }),
    type: new FormControl('')
  })
  destroy$ = new Subject<void>();

  constructor() { }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  ngOnInit(): void {
    if(!this.allowBankAccounts && !this.allowCreditCards) throw new Error('Both payment methods types can\'t be disabled. Set allowCreditCards or allowBankAccounts to true');
    if(this.formType) this.changeFormType(this.formType);

    this.reset$.pipe(takeUntil(this.destroy$)).subscribe({
      next: () => this.formType = null
    });

    this.detailsForm.valueChanges.subscribe({
      next: v => {
        this.onPaymentMethodDetailsChanged.emit({valid: this.detailsForm.touched && this.detailsForm.valid, details: v});
      }
    });

    if(this.billingAddress) {
      this.detailsForm.get('billTo.zip').patchValue(this.billingAddress.zip);
    }
  }

  changeFormType(type) {
    this.formType = type;
    if (this.detailsForm.get('method')) this.detailsForm.removeControl('method', { emitEvent: false });
    if (this.formType == 'card') {
      this.detailsForm.registerControl('method', new FormGroup({
        cardNumber: new FormControl('', [CreditCardValidator.validateCardNumber]),
        cardExpiryDate: new FormControl('', [CreditCardValidator.validateCardExpiry]),
        cardCode: new FormControl({value:'', disabled: this.cardCodeDisabled}, [CreditCardValidator.validateCardCvc]),
        holderName: new FormControl('', Validators.compose([Validators.required, Validators.minLength(2)]))
      }));
      this.detailsForm.get('billTo.zip').setValidators([Validators.required, Validators.minLength(5), Validators.maxLength(5)]);
    } else {
      this.detailsForm.registerControl('method', new FormGroup({
        accountType: new FormControl('', [Validators.required]),
        routingNumber: new FormControl('', [Validators.required, Validators.minLength(9), Validators.maxLength(9)]),
        accountNumber: new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(17)]),
        holderName: new FormControl('', Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(22)]))
      }));
      
      this.detailsForm.get('billTo.zip').setValidators([]);
    }
    this.detailsForm.get('type').setValue(type);
    this.detailsForm.updateValueAndValidity();
  }

  isInvalid(controlName) {
    return this.detailsForm.get(controlName).dirty && this.detailsForm.get(controlName)?.invalid;
  }

}
