<div class="columns is-centered">
    <div class="column">
        <div class="field is-horizontal">
            <div class="">
                <label class="label mr-6">{{newMethodLabel}}</label>
            </div>
            <div class="field-body">
                <div class="field">
                    <div class="control has-icons-right">
                        <label class="radio" *ngIf="allowCreditCards">
                            <input type="radio" name="paymentMethod" value="card" [checked]="formType == 'card'" [(ngModel)]="formType"
                                (ngModelChange)="changeFormType($event)">
                           Credit Card
                        </label>
                        <label class="radio" *ngIf="allowBankAccounts">
                            <input type="radio" name="paymentMethod" value="bankAccount"
                                [(ngModel)]="formType" [checked]="formType == 'bankAccount'" (ngModelChange)="changeFormType($event)">
                           Bank Account
                        </label>
                    </div>
                </div>
                <button *ngIf="showCancelButton && formType" class="button is-danger is-small"
                (click)="formType = null;detailsForm.reset()">Cancel</button>
            </div>
        </div>
    </div>
</div>
<div class="columns">
    <div class="column" *ngIf="formType =='card'" [formGroup]="detailsForm">
        <div class="field is-horizontal" [formGroupName]="'method'">
            <div class="field-body">
                <div class="field">
                    <div class="control has-icons-right">
                        <input class="input" type="text" #creditCardName formControlName="holderName"
                            placeholder="Cardholder's name" />
                        <span class="icon is-small is-right icon-red" *ngIf="isInvalid('method.holderName')">
                            <i class="fas fa-exclamation-triangle"></i>
                        </span>
                        <span *ngIf="isInvalid('method.holderName')" class="help is-danger">Name is
                            required</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="field is-horizontal">
            <div class="field-body">
                <div class="field" [formGroupName]="'method'">
                    <div class="control  has-icons-right">
                        <input class="input" type="tel" class="input" formControlName="cardNumber"
                            placeholder="0000 0000 0000 0000" autocomplete="cc-number" id="cc-number" ccNum>
                        <span class="icon is-small is-right icon-red" *ngIf="isInvalid('method.cardNumber')">
                            <i class="fas fa-exclamation-triangle"></i>
                        </span>
                        <span *ngIf="isInvalid('method.cardNumber')" class="help is-danger">Card number is
                            invalid</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="field is-horizontal">
            <div class="field-body">
                <div class="field" [formGroupName]="'method'">
                    <div class="control  has-icons-right">
                        <input class="input" type="tel" class="input" type="tel"
                            formControlName="cardExpiryDate" placeholder="Expiry Date" autocomplete="cc-exp"
                            id="cc-exp" ccExp>
                        <span class="icon is-small is-right icon-red"
                            *ngIf="isInvalid('method.cardExpiryDate')">
                            <i class="fas fa-exclamation-triangle"></i>
                        </span>
                        <span *ngIf="isInvalid('method.cardExpiryDate')" class="help is-danger">Expiry date is
                            invalid</span>
                    </div>
                </div>
                <div class="field" [formGroupName]="'method'" [hidden]="cardCodeDisabled">
                    <div class="control  has-icons-right">
                        <input class="input" type="password" class="input" formControlName="cardCode"
                            placeholder="CCV/CVC" autocomplete="cc-cvc" id="cc-csc" ccCvc>
                        <span class="icon is-small is-right icon-red" *ngIf="isInvalid('method.cardCode')">
                            <i class="fas fa-exclamation-triangle"></i>
                        </span>
                        <span *ngIf="isInvalid('method.cardCode')" class="help is-danger">Code is
                            required</span>
                    </div>
                </div>
                <div class="field" [formGroupName]="'billTo'">
                    <div class="control  has-icons-right">
                        <input class="input" type="tel" class="input" type="tel" formControlName="zip"
                            placeholder="ZIP">
                        <span class="icon is-small is-right icon-red" *ngIf="isInvalid('billTo.zip')">
                            <i class="fas fa-exclamation-triangle"></i>
                        </span>
                        <span *ngIf="isInvalid('billTo.zip')" class="help is-danger">ZIP is
                            required</span>

                    </div>
                </div>
            </div>
        </div>
        <div class="field"  *ngIf="allowUpdateDefaultPaymentMethod">
            <div class="control">
                <label class="checkbox">
                    <input type="checkbox" formControlName="isDefault">
                   {{mode =='payment'? 'Save and make ' : 'Make '}} this as the new default customer payment method
                </label>
            </div>
        </div>
    </div>
    <div class="column" *ngIf="formType =='bankAccount'" [formGroup]="detailsForm">
        <div class="field is-horizontal" [formGroupName]="'method'">
            <div class="field-body">
                <div class="field">
                    <div class="control has-icons-right">
                        <input class="input" type="text" placeholder="Name on Account" #creditCardName formControlName="holderName"
                            placeholder="Name on account" />
                        <span class="icon is-small is-right icon-red" *ngIf="isInvalid('method.holderName')">
                            <i class="fas fa-exclamation-triangle"></i>
                        </span>
                        <span *ngIf="isInvalid('method.holderName')" class="help is-danger">Name is
                            required</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="field is-horizontal">
            <div class="field-body">
                <div class="field" [formGroupName]="'method'">
                    <div class="control  has-icons-right">
                        <input class="input" type="tel" class="input" [mask]="'99999999999999999'" formControlName="accountNumber" placeholder="Bank Account Number"
                             >
                        <span class="icon is-small is-right icon-red" *ngIf="isInvalid('method.accountNumber')">
                            <i class="fas fa-exclamation-triangle"></i>
                        </span>
                        <span *ngIf="isInvalid('method.accountNumber')" class="help is-danger">Account number is
                            invalid</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="field is-horizontal">
            <div class="field-body">
                <div class="field" [formGroupName]="'method'">
                    <div class="control  has-icons-right">
                        <input class="input" type="tel" class="input" [mask]="'000000000'" formControlName="routingNumber" placeholder="Bank Routing Number"
                             >
                        <span class="icon is-small is-right icon-red" *ngIf="isInvalid('method.routingNumber')">
                            <i class="fas fa-exclamation-triangle"></i>
                        </span>
                        <span *ngIf="isInvalid('method.routingNumber')" class="help is-danger">Routing number is
                            invalid</span>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="field is-horizontal">
            <div class="field-body">
                <div class="field" [formGroupName]="'method'">
                    <div class="control  has-icons-right">
                             <select class="select" formControlName="accountType" placeholder="Account type">
                                <option value="businessChecking">Business Checking</option>
                                <option value="savings">Savings</option>
                                <option value="checking">Checking</option>
                             </select>
                        <span class="icon is-small is-right icon-red" *ngIf="isInvalid('method.accountType')">
                            <i class="fas fa-exclamation-triangle"></i>
                        </span>
                        <span *ngIf="isInvalid('method.accountType')" class="help is-danger">Account type is required</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="field" *ngIf="allowUpdateDefaultPaymentMethod">
            <div class="control">
                <label class="checkbox">
                    <input type="checkbox" formControlName="isDefault">
                   {{mode =='payment'? 'Save and make ' : 'Make '}} this as the new default customer payment method
                </label>
            </div>
        </div>
    </div>
</div>