import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { SwiperComponent } from 'ngx-useful-swiper';
import { from, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Product } from 'src/app/models/product.model';
import { Rep } from 'src/app/models/rep.model';
import { CustomersService } from 'src/app/services/customers.service';
import { ProductsService } from 'src/app/services/products.service';
import { RepsService } from 'src/app/services/reps.service';
import { UsersService } from 'src/app/services/users.service';
import { SwiperOptions } from 'swiper';
import { Customer } from '../../../models/customer.model';

@Component({
  selector: 'app-order-entry-form',
  templateUrl: './order-entry-form.component.html',
  styleUrls: ['./order-entry-form.component.scss']
})
export class OrderEntryFormComponent implements OnInit {

  @ViewChild('select') customersSelectComponent: NgSelectComponent;
  @ViewChild('swiper') swiperComponent: SwiperComponent;

  @Input() nextButtonText = 'Next';
  @Input() hideBaseCost = false;
  @Input() hideCommission = false;
  @Input() showRepsCustomerList = false;
  @Input() showOnlyShortProductText = false;
  @Input() showAllProducts = true;

  @Output() customerChange = new EventEmitter<Customer>();
  @Output() orderEntryReady = new EventEmitter<{}>();

  customer: Customer = null;
  customers$: Observable<Customer[]>;

  config: SwiperOptions = {
    autoHeight: false,
    allowTouchMove: true,
    centeredSlides: true,
    breakpoints: {
      1024: {
        slidesPerView: 4
      },
      500: {
        slidesPerView: 3
      },
      400: {
        slidesPerView: 2
      },
      300: {
        slidesPerView: 1
      }
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    loop: false,
    on: {
      click: (s, e) => {
        if(s.clickedIndex == undefined) return;
        this.highlightProduct(s.clickedIndex);
        console.log('click ', s.clickedIndex);
      },
      slideChangeTransitionEnd: (s) => this.highlightProduct(s.activeIndex)
    }
  };

  selectedProduct: any;
  products: Product[] = [];

  form = new FormGroup({
    reference: new FormControl(''),
    orderProducts: new FormArray([], {validators: this.hasAtleastOneProduct}),
    orderExtension: new FormControl(0, { validators: [Validators.required] }),
    orderCommission: new FormControl(0),
    orderBaseCost: new FormControl(0)
  });

  destroy$ = new Subject<void>();
  repEmail: string;
  rep: Rep;
  
  constructor(private productsService: ProductsService,
              private customersService: CustomersService,
              private repService: RepsService,
              private usersService: UsersService) { }

  async ngOnInit() {
    this.products = await this.productsService.getActiveProducts();
    this.selectedProduct = this.products[0];
    
    if(this.showRepsCustomerList) this.customers$ = from(this.getCustomers());

    this.initProductsForm();

    this.form.get('orderProducts').valueChanges.subscribe(products => {
      let ex = 0;
      let com = 0;
      let bc = 0;
      products.forEach(product => {
        ex += product.sellingPriceExtension;
        com += product.commission;
        bc += (product.baseCost * product.sellingQuantity);
      });
      this.form.get('orderExtension').patchValue(ex, {emitEvent:false});
      this.form.get('orderCommission').patchValue(com, {emitEvent:false});
      this.form.get('orderBaseCost').patchValue(bc, {emitEvent:false});
    });

    this.repEmail = await this.usersService.getCurrentUserEmail();
    this.rep = await this.repService.getRep(this.repEmail);
  }

  getProducts() {
    return this.showAllProducts ? this.products : this.products; //TODO maybe introduce a flag to a product to show different products on different form?
  }

  async getCustomers() {
    const userId = (await this.usersService.getCurrentUser()).getUsername();
    const isAdmin = await this.usersService.isAdmin();
    const customers = await this.customersService.list(userId);
    return isAdmin ? customers : customers.filter(c => c.active);
  }

  initProductsForm() {
    const productsForm = <FormArray>this.form.get('orderProducts');
    productsForm.clear();

    let sellingPriceValidators = { validators: [Validators.required, Validators.min(1)] };
    if(!this.hideBaseCost) sellingPriceValidators = { validators: [this.validatePrice] };

    this.getProducts().forEach(product => {
      //ignore products without pricing tiers
      if(!product.tiers || product.tiers.length === 0) return;
      const initialPricing = product.tiers[0];
      const quantity = initialPricing.packageType === 'Box' ? product.itemsPerBox : product.itemsPerMasterCarton;

      const group = new FormGroup({
        id: new FormControl(product.id, { validators: [Validators.required] }),
        name:  new FormControl(product.name, { validators: [Validators.required] }),
        sellingQuantity: new FormControl(0, { validators: [this.validateQuantity] }),
        quantity: new FormControl(quantity, { validators: [Validators.required] }),
        //quantityStep: new FormControl(quantity, { validators: [Validators.required] }),
        sellingPrice: new FormControl(0, sellingPriceValidators),
        baseCost: new FormControl(initialPricing.baseCost, { validators: [Validators.required] }),
        sellingPriceExtension: new FormControl(0, { validators: [Validators.required] }),
        commission: new FormControl(0, { validators: [Validators.required] }),
        wmsSku: new FormControl('')
      });
      
      productsForm.push(group);
      group.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(productRow => {
        if(!productRow.name) return;
        const currentProduct = this.products.find(s => s.id == productRow.id);
        let currentPricingTier = null;
        if(!productRow.sellingQuantity || productRow.sellingQuantity < currentProduct.itemsPerBox) currentPricingTier = currentProduct.tiers[0];
        else currentPricingTier = currentProduct.tiers.find(t => productRow.sellingQuantity >= t.minQuantity && productRow.sellingQuantity <= t.maxQuantity) 
         ?? currentProduct.tiers.slice(-1)[0];
         productRow.wmsSku = currentProduct.wmsSku;
        if(!this.rep?.allowOneTimeDiscout) {
          productRow.baseCost = currentPricingTier.baseCost;
         }
        productRow.sellingPriceExtension = productRow.sellingQuantity * productRow.sellingPrice;
        productRow.commission = productRow.sellingPrice > 0 ? productRow.sellingPriceExtension - (productRow.sellingQuantity * productRow.baseCost) : 0;
        group.patchValue(productRow, {emitEvent:false, });
      });
    });
  }

  async customerChanged(c) {
    if (!c) {
      return;
    }
    this.customer = c;
    this.customerChange.next(c);
  }


  validateQuantity(c: FormControl) {
    return c.value % c.parent?.get('quantity')?.value == 0 ? null : {
      validateQuantity: {
        valid: false
      }
    };
  }

  validatePrice(c: FormControl) {
    return c.value >= c.parent?.get('baseCost')?.value ? null : {
      validatePrice: {
        valid: false
      }
    };
  }

  hasAtleastOneProduct(c: FormArray) {
    return !c.invalid 
          && c.controls.map(g => g.get('sellingQuantity')?.value).reduce((p, c)=> c+=p, []) > 0 ? null : {
      hasAtleastOneProduct: {
        valid: false
      }
    };
  }

  formHasAtleastOneProduct() {
    const products = this.form.get('orderProducts') as FormArray;
    const validProducts = products.controls.filter(c => c.get('sellingQuantity').value > 0);
    return validProducts.length > 0 && validProducts.every(c => !c.invalid);
  }

  highlightProduct(i) {
    this.selectedProduct = this.products[i];
    this.swiperComponent.swiper?.slideTo(i);

    const sellQunatity = this.form.get('orderProducts')['controls'][i].get('sellingQuantity');
    if(sellQunatity.value == 0) sellQunatity.patchValue(null);

    
    const sellPrice = this.form.get('orderProducts')['controls'][i].get('sellingPrice');
    if(sellPrice.value == 0) sellPrice.patchValue(null);
  }

  isProductInvalid(rowIndex, controlName) {
    const group = this.form.get('orderProducts')['controls'][rowIndex];
    const control = group?.get(controlName);
    const quantity = group?.get('sellingQUantity') as FormControl;
    return quantity?.value ? (control?.disabled && !control?.value) || control?.invalid : false;
  }

  productHasError(rowIndex, controlName, error) {
    const group = this.form.get('orderProducts')['controls'][rowIndex];
    const control = group?.get(controlName) as FormControl;
    const quantity = group?.get('sellingQuantity') as FormControl;
    return quantity?.value && control?.hasError(error);
  }

  next() {
    const form = this.form.getRawValue();
    this.orderEntryReady.next({
      reference: form.reference,
      orderExtension: form.orderExtension,
      orderCommission: form.orderCommission,
      orderBaseCost: form.orderBaseCost,
      orderProducts: form.orderProducts.filter(p => p.sellingQuantity > 0)});
  }

}
