import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from 'aws-amplify';
import { AmplifyService } from 'aws-amplify-angular';
import { from, Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { ProductInfo, ShippingDetails } from '../models/order.model';
import { Product } from '../models/product.model';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  constructor(private http: HttpClient, private amplify: AmplifyService) { }

  async getProducts(): Promise<Product[]> {
    return await this.amplify.api().get('CXProductsAPI', `/products`, {});
  }

  async getActiveProducts(): Promise<Product[]> {
    return await this.amplify.api().get('CXProductsAPI', `/products/active`, {});
  }

  async put(product: Product) {
    return await this.amplify.api().put('CXProductsAPI', '/products', { body: product });
  }


  async delete(productId: string) {
    await this.amplify.api().del('CXProductsAPI', `/products/object/${productId}`, {});
  }
}
