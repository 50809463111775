import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API } from 'aws-amplify';
import { PaymentMethod } from '../models/customer.model';
import { from, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { v5 as uuidv5, v4 as uuidv4 } from 'uuid';
import { UsersService } from './users.service';

@Injectable({ providedIn: 'root' })
export class PaymentsService {
  private MY_NAMESPACE = 'a2590f29-2ec4-4865-bb8c-7108060b7c9a';
  constructor(private usersService: UsersService) { }

  async payWithCardOnFile(id, cardCode, amount) {
    try {
      const paymentResponse = await API.post('CXPaymentsAPI', '/payment',
        {
          body: { id, cardCode, amount: new Number(amount).toFixed(2) },
          headers: {
            'Idempotency-Key': id
          }
        });
      return paymentResponse;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async pay(orderId, paymentDetails, amount) {
    try {
      //const userId = (await this.usersService.getCurrentUser())?.getUsername() ?? uuidv4();
      const method = this.cleanData(paymentDetails.method);
      //const key = uuidv5(`${userId}-${orderId}-${method.cardNumber}`, this.MY_NAMESPACE);
      var body = {
        card: {
          method: paymentDetails.type == 'card' ? method : paymentDetails.method,
          billTo: paymentDetails.billTo,
          type: paymentDetails.type
        },
        cardCode: paymentDetails.method.cardCode,
        amount: new Number(amount).toFixed(2)
      };
      if(orderId) body['orderId'] =  orderId;
      const paymentResponse = await API.post('CXPaymentsAPI', '/payment', {
        body: body
      });
      return paymentResponse;
    } catch (error) {
      console.error(error);
      return { unknown: true };
    }
  }
  
  cleanData(cardData) {
    const date = cardData.cardExpiryDate.split('/');
    cardData.cardNumber = cardData.cardNumber.replace(/[^\d]/g, '');
    cardData.cardExpiryMonth = date[0].replace(/[^\d]/g, '');
    cardData.cardExpiryYear = date[1].replace(/[^\d]/g, '');
    cardData.cardExpiryYear = cardData.cardExpiryYear.length < 4 ? `20${cardData.cardExpiryYear}` : cardData.cardExpiryYear;
    return {
      cardNumber: cardData.cardNumber,
      cardExpiryYear: cardData.cardExpiryYear,
      cardExpiryMonth: cardData.cardExpiryMonth,
      cardHolderName: cardData.holderName
    };
  }

  getPaymentMethods(customerId: string, allowCards, allowBankAccounts): Observable<PaymentMethod[]> {
    if (!customerId) return of([]);
    const result = from(API.get('CXPaymentsAPI', `/payment/methods/${customerId}`, {}));
    //return result;
    return result.pipe(switchMap(methods => {
      //TODO move filtering to the backend
      const cards = methods.filter(m => m.type == 'card');
      const accounts = methods.filter(m => m.type == 'bankAccount');
      const result = [];
      if (allowBankAccounts) result.push(...accounts);
      if (allowCards) result.push(...cards);
      return of(result);
    }));
  }

  async deleteCard(id, customerId: string) {
    return await API.del('CXPaymentsAPI', `/payment/methods/${customerId}/method/${id}`, {});
  }

  async updateCustomerDefaultPaymentMethod(id, customerId: string) {
    return await API.put('CXPaymentsAPI', `/payment/methods/${customerId}/method/${id}`, {});
  }

  async add(customerId: string, methodData: any) {
    try {
      const paymentResponse = await API.post('CXPaymentsAPI', `/payment/methods/${customerId}`, {
        body: {
          method: methodData.type == 'card' ? this.cleanData(methodData.method) : methodData.method,
          billTo: methodData.billTo,
          type: methodData.type,
          isDefault: methodData.isDefault
        }
      });
      return paymentResponse;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}