import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, } from '@angular/router';
import { Auth, Hub } from 'aws-amplify';
import { Spinkit, SpinnerVisibilityService } from 'ng-http-loader';
import { LogRocketService } from './services/logrocket.service';
import { UsersService } from './services/users.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export default class AppComponent implements OnInit, OnDestroy {
  title = 'DSG Xpress Ordering Portal';
  private spinkit = Spinkit;

  spinnerType = this.spinkit.skThreeBounce;
  isLogin: boolean;
  isLoggedIn: any;
  isAdmin: boolean;
  isSubRepOrder: boolean;
  isHcp = false;

  constructor(private usersService: UsersService, private logRocket: LogRocketService, private spinner: SpinnerVisibilityService, private router: Router) {

    document.addEventListener('DOMContentLoaded', () => {

      // Get all "navbar-burger" elements
      const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

      // Check if there are any navbar burgers
      if ($navbarBurgers.length > 0) {

        // Add a click event on each of them
        $navbarBurgers.forEach(el => {
          el.addEventListener('click', () => {

            // Get the target from the "data-target" attribute
            const target = el.dataset.target;
            const $target = document.getElementById(target);

            // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
            el.classList.toggle('is-active');
            $target.classList.toggle('is-active');

          });
        });
      }
    });
  }
  ngOnDestroy(): void {
    //Hub.remove('api', () => { });
  }

  async ngOnInit() {
    this.router.events.subscribe(async e => {
      if (e instanceof NavigationEnd) {
        this.isHcp = window.location.pathname.includes('hcp');
        this.isLogin = window.location.pathname.includes('login');
        this.isSubRepOrder = window.location.pathname.startsWith('/order');
        this.isLoggedIn = !! await this.usersService.getCurrentUser();
        this.isAdmin = await this.usersService.isAdmin();
      }
    });
    await this.logRocket.identifyUser();
  }

  async logout() {
    await Auth.signOut();
    this.router.navigate(['login']);
  }
}
