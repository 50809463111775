import { Component, Input, OnInit } from '@angular/core';
import { DialogService } from '@ngneat/dialog';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { Observable, of, Subject } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { Product, ProductTier } from 'src/app/models/product.model';
import { ProductsService } from 'src/app/services/products.service';

@Component({
  selector: 'app-products-list',
  templateUrl: './products-list.component.html',
  styleUrls: ['./products-list.component.scss']
})
export class ProductsListComponent implements OnInit {

  products: Product[];
  activeProducts: Product[];
  inactiveProducts: Product[];

  currentProduct: Product;

  constructor(private productsService: ProductsService,
    private dialog: DialogService,
    private spinner: SpinnerVisibilityService) { }

  async ngOnInit() {
    await this.getProducts();
  }

  selectProduct(p = null) {
    if (!p) p = <Product>{ id: '', title: '', name: '', shortText: '', text: '', wmsSku: '', image: '', active:true, itemsPerBox: 0, itemsPerMasterCarton: 0, tiers: [<ProductTier>{ baseCost: 0, maxQuantity: 0, minQuantity: 0, packageType: 'Box' }] };
    this.currentProduct = p;
  }

  async onProductUpdated(product: Product) {
    this.spinner.show();
    const response = await this.productsService.put(product);
    product.id = response.id;
    await this.getProducts();
    this.selectProduct(product);
    this.spinner.hide();
  }

  async getProducts() {
    this.spinner.show();
    //this.products = this.productsService.getProductsDetails();
    this.products = await this.productsService.getProducts();
    this.activeProducts = this.products.filter(p => p.active);
    this.inactiveProducts = this.products.filter(p => !p.active);
    this.spinner.hide();
  }

  async toggle(product) {
    this.spinner.show();
    product.active = !product.active;
    await this.productsService.put(product);
    this.selectProduct(product);
    this.getProducts();
    
  }

}
