import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import exportFromJSON from 'export-from-json';
import * as moment from 'moment';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { OrdersService } from 'src/app/services/orders.service';
import { RepsService } from 'src/app/services/reps.service';

@Component({
  selector: 'app-reps-commissions',
  templateUrl: './reps-commissions.component.html',
  styleUrls: ['./reps-commissions.component.scss']
})
export class RepsCommissionsComponent implements OnInit {
  filterByVal = '';
  monthFilter = 0;
  filtered = false;
  repsList = null;

  public originalOrdersList = null;
  public order = null;
  public ordersList;
  original: any;
  repsEmails: any[] = [];
  commissions: [] = [];


  constructor(
    private router: Router,
    private spinner: SpinnerVisibilityService,
    private activatedRoute: ActivatedRoute,
    private orderService: OrdersService,
    private _repService: RepsService) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(async (params) => {
      console.log(params);
      try {
        this.getCommissions();

        this.spinner.hide();
      }
      catch (err) {
        console.log(err);
        this.spinner.hide();
        this.router.navigate(['']);
      }
    })
  }

  async getCommissions() {
    const reps = await this._repService.getReps();
    this.repsList = reps.filter(r => this.repsEmails.includes(r.email));
    this.commissions = await this.orderService.getLastMonthCommissions();
  }

  getRepName(order) {
    const rep = this.repsList?.find(r => r.email == order.repEmail);
    return `${rep?.firstName} ${rep?.lastName}`;
  }

  getCommission(order) {
    return order.billShipmentTo ?
      order.orderCommission :
      //Old schema support :( order.billShipmentTo == 'customer' ? totalOrder : order.orderExtension
      ((order.orderProducts[0].quantity * order.orderProducts[0].sellingQuantity * order.orderProducts[0].sellingPrice) - (order.orderProducts[0].quantity * order.orderProducts[0].sellingQuantity * order.orderProducts[0].cost))
  }

  getCurrentMonth() {
    return moment().format('MMMM')
  }

  filterOrders() {
    this.ordersList = this.original.filter(order => order.repEmail === this.filterByVal)
    this.filtered = true;
  }

  clearFilter() {
    this.ordersList = Object.assign([], this.original);
    this.filterByVal = '';
    this.filtered = false;
  }

  payRep(item) {
    //TODO pay to the rep via QBO online(create bill+bill payment)
  }
}
