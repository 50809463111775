import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @Input() modalTitle = '';
  @Input() okButtonText: string;
  @Input() isOkButtonEnable = true;
  @Input() cancelButtonText = 'Cancel';
  @Input() showFooter = true;
  @Input() showOkButton = true;
  @Input() showCancelButton = true;
  @Input() isSmallHeightModal: boolean = false;
  @Input() isBigHeightModal: boolean = false;
  @Input() isSmallWidthModal: boolean = false;
  @Output() private onOk = new EventEmitter();
  @Output() private onCancel = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  ok() {
    this.onOk.emit();
  }

  cancel() {
    this.onCancel.emit();
  }
}
