import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { BillingAddress } from 'src/app/models/customer.model';


@Component({ template: '' })
export class BasePaymentComponent implements OnInit, OnDestroy {
    protected destroy$ = new Subject<void>();
    customerId = '';

    constructor(protected spinner: SpinnerVisibilityService) { }


    ngOnInit(): void {
        this.customerId$.pipe(tap(id => this.customerId = id), takeUntil(this.destroy$)).subscribe();        
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    @Input() allowBankAccounts = false;
    @Input() allowCreditCards = true;
    @Input() showMethodSecretCode = false;

    @Input() customerId$ = new Subject<string>();
    @Input() reset$ = new Subject<void>();
    
    @Input() refresh$ = new Subject<void>();
    @Input() billingAddress: BillingAddress;
}