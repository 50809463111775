<div class="columns is-multiline is-centered">
  <div class="column is-full is-narrow">
    <swiper #swiper [config]="config">

      <div class="swiper-wrapper">

        <div class="swiper-slide" *ngFor="let product of getProducts()">
          <img style="height:200px" [src]="product.image" />
        </div>

      </div>

      <!-- Add Pagination -->
      <div class="swiper-pagination"></div>

    </swiper>
    <!-- Add Arrows -->
    <div class="swiper-button-next"></div>
    <div class="swiper-button-prev"></div>

  </div>
  <div *ngIf="selectedProduct?.title" class="column is-8">
    <h1 class="title has-text-centered">{{selectedProduct.title}}</h1>
    <pre class="subtitle" style="padding:40px">{{showOnlyShortProductText ? selectedProduct.shortText : selectedProduct.text}}</pre>
  </div>
</div>

<div class="columns">
  <div class="column is-full">
    <div class="title has-text-centered">
      Order Entry
    </div>
  </div>
</div>
<div *ngIf="showRepsCustomerList" class="columns is-centered">
  <div class="column is-4">
    <div class="field">
      <ng-select #select [items]="customers$|async" [(ngModel)]="customer" [editableSearchTerm]="true"
        (ngModelChange)="customerChanged($event)" dropdownPosition="auto" labelForId="id"
        [loading]="!(customers$|async)" placeholder="Select a customer" [virtualScroll]="true">

        <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
          <div><span [ngOptionHighlight]="search">{{item.billingAddress.firstName}}
              {{item.billingAddress.lastName}} ({{item.billingAddress.zip}})</span></div>
        </ng-template>
        <ng-template ng-label-tmp let-item="item">
          {{item.billingAddress.firstName}}
          {{item.billingAddress.lastName}} ({{item.billingAddress.zip}})
        </ng-template>
      </ng-select>
    </div>
  </div>
</div>
<div class="columns is-centered">
  <div class="column is-4">
    <div class="field" [formGroup]="form">
      <div class="control">
        <input type="text" formControlName="reference" class="input" placeholder="Reference" />
      </div>
    </div>
  </div>
</div>

<div [hidden]="showRepsCustomerList ? customer == null: false">
  <div class="columns is-full has-text-weight-bold is-centered">
    <div class="column is-2 has-text-centered">Name</div>
    <div class="column is-2 has-text-centered" [ngClass]="hideBaseCost ? 'is-4' : 'is-2'">Quantity</div>
    <div [hidden]="hideBaseCost" class="column is-2 has-text-centered">Base Cost</div>
    <div class="column is-2 has-text-centered">Selling Price</div>
    <div class="column is-2 has-text-centered" [ngClass]="hideBaseCost ? 'is-4' : 'is-2'">
      Selling Price Extension
    </div>
    <div [hidden]="hideCommission" class="column is-2 has-text-centered">
      Commission
    </div>
  </div>
  <div class="columns is-multiline is-centered " [formGroup]="form">
    <div class="column is-full" *ngFor="let item of form.get('orderProducts')['controls']; let i = index"
      formArrayName="orderProducts">
      <div class="field is-horizontal" [formGroupName]="i">
        <span class="column is-2 has-text-weight-bold">
          {{item.get('name').value}}
        </span>
        <input type="hidden" readonly formControlName="name" />
        <div class="field-body">
          <div class="field">
            <div class="control has-icons-left">
              <input class="input has-text-right" (focus)="highlightProduct(i)"  type="number" min="0"
                formControlName="sellingQuantity"
                [attr.step]="form.get('orderProducts')['controls'][i].get('quantity').value" placeholder="Quantity">
              <span class="icon is-small is-left icon-red" *ngIf="isProductInvalid(i, 'sellingQuantity')">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
            </div>
            <!--<p class="help is-danger" *ngIf="productHasError(i, 'sellingQuantity', 'min')" class="help is-danger">Min quantity is {{form.get('orderProducts')['controls'][i].get('quantity').value}} tests</p>-->
            <p class="help is-danger" *ngIf="productHasError(i, 'sellingQuantity', 'validateQuantity')"
              class="help is-danger">Quantity should be a multiple of
              {{form.get('orderProducts')['controls'][i].get('quantity').value}}</p>
          </div>
          <div [hidden]="hideBaseCost" class="field">
            <div class="control has-icons-left">
              <input class="input has-text-right" (focus)="highlightProduct(i)" currencyMask min="0" prefix="$" formControlName="baseCost" [attr.readonly]="rep?.allowOneTimeDiscout != undefined ? null: true"
                placeholder="Base Cost">
            </div>
          </div>
          <div class="field">
            <div class="control  has-icons-left">
              <input class="input has-text-right" (focus)="highlightProduct(i)" currencyMask min="0" min="0" formControlName="sellingPrice"
                [attr.min]="item.baseCost" placeholder="Selling Price">

              <span class="icon is-small is-left icon-red" *ngIf="isProductInvalid(i, 'sellingPrice')">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
            </div>
            <p class="help is-danger" *ngIf="productHasError(i, 'sellingPrice', 'validatePrice')"
              class="help is-danger">
              {{ 'Selling Price shouldn\'t be less than the base cost of $' +
              form.get('orderProducts')['controls'][i].get('baseCost').value}}</p>
            <p class="help is-danger" *ngIf="productHasError(i, 'sellingPrice', 'required')" class="help is-danger">
              {{hideBaseCost? 'Selling Price is required' : 'Selling Price shouldn\'t be less than the base cost of $' +
              form.get('orderProducts')['controls'][i].get('baseCost').value}}</p>
          </div>
          <div class="field">
            <div class="control ">
              <input class="input has-text-right" min="0" step="0.01" lang="en-US" currencyMask
                formControlName="sellingPriceExtension" readonly placeholder="Extension">

            </div>
          </div>

          <div [hidden]="hideCommission" class="field">
            <div class="control">
              <input class="input  has-text-right" min="0" step="0.01" lang="en-US" currencyMask
                formControlName="commission" readonly placeholder="Commission">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="columns">
    <div class="column" [ngClass]="hideBaseCost && hideCommission ? 'is-9' : 'is-8'"></div>
    <div class="column is-2">
      <div class="is-pulled-right has-text-weight-bold">{{form.get('orderExtension')?.value|currency}}</div>
    </div>
    <div [hidden]="hideCommission" class="column is-pulled-right has-text-weight-bold">
      <div class="is-pulled-right">{{form.get('orderCommission')?.value|currency}}</div>
    </div>
  </div>
  <div class="columns is-centered">
    <div class="column is-narrow">
      <button class="button is-success" (click)="next()"
        [disabled]="!formHasAtleastOneProduct() || (showRepsCustomerList && !customer)"
        type="button success">{{nextButtonText}}</button>
    </div>
  </div>
</div>