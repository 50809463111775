<div class="columns">
    <div class="column">
        <app-payment-details [newMethodLabel]="newMethodLabel" [allowBankAccounts]="allowBankAccounts"
            [allowCreditCards]="allowCreditCards" [showCancelButton]="showCancelButton"
            [formType]="formType"
            [cardCodeDisabled]="cardCodeDisabled" [billingAddress]="billingAddress" [reset$]="reset$"
            [allowUpdateDefaultPaymentMethod]="allowUpdateDefaultPaymentMethod"
            (onPaymentMethodDetailsChanged)="onPaymentMethodSelectedOrChanged($event)"></app-payment-details>

        <button *ngIf="position == 'middle'" [disabled]="canShowActionButton()? null : true" class="button is-success is-fullwidth mt-4"
            (click)="onActionButtonClick()">{{actionButtonLabel}}</button>

        <app-payment-methods-list *ngIf="showCustomerPaymentMethods" [customerId$]="customerId$" [allowBankAccounts]="allowBankAccounts"
            [allowCreditCards]="allowCreditCards" [showDeleteButton]="showDeleteButton"
            [showMethodSecretCode]="showMethodSecretCode" [refresh$]="refresh$"
            [resetPaymentMethod$]="resetExistingPaymentMethod$"
            (onPaymentMethodSelected)="onPaymentMethodSelectedOrChanged($event)"></app-payment-methods-list>

            <button *ngIf="position == 'bottom'" [ngClass]="{'is-light': !canShowActionButton(), 'is-success': canShowActionButton()}" [disabled]="canShowActionButton()? null : true" class="button is-fullwidth mt-4"
            (click)="onActionButtonClick()">{{actionButtonLabel}}</button>
    </div>
</div>