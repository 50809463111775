import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { EMPTY, from, Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Product, ProductTier, ProductPackageType } from 'src/app/models/product.model';
import { AccountingService } from 'src/app/services/accounting.service';
import { OrdersService } from 'src/app/services/orders.service';
import { BaseFormComponent } from '../../shared/base-form/base-form.component';

@Component({
  selector: 'app-product-editor',
  templateUrl: './product-editor.component.html',
  styleUrls: ['./product-editor.component.scss']
})
export class ProductEditorComponent extends BaseFormComponent implements OnInit {

  qboProducts$:  Observable<{id: string, name: string, active: boolean}[]> = EMPTY;
  private _product: Product;
  @Input()
  get product(): Product { return this._product; }
  set product(p: Product) {
    this._product = p;
    if(!p.qbItemId) {p.qbItemId = '';}
    this.setTiers(p);
    this.form.setValue(p);
  }

  @Output() onUpdate = new EventEmitter<Product>();
  
  form = new FormGroup({
    id: new FormControl(''),
    name: new FormControl('', Validators.required),
    qbItemId: new FormControl(''),
    wmsSku: new FormControl('', [Validators.maxLength(50)]),
    active: new FormControl(undefined, [Validators.required]),
    title: new FormControl('', [Validators.required]),
    text: new FormControl('', [Validators.required]),
    shortText: new FormControl('', [Validators.required]),
    image: new FormControl('', [Validators.required]),
    itemsPerBox: new FormControl(0, [Validators.required, Validators.min(0)]),
    itemsPerMasterCarton: new FormControl(0, [Validators.required, Validators.min(0)]),
    tiers: new FormArray([], [Validators.required])
  });
  
  constructor(protected ordersService: OrdersService, private qboService: AccountingService) {
    super(ordersService);

    this.form.get('itemsPerBox').valueChanges.subscribe({
      next: v => {
        this.setTiers(this.form.getRawValue());
      }
    })
  }

  ngOnInit(): void {
    this.form.get('active').valueChanges.subscribe(active => {
      if(!active) {
        this.form.disable({onlySelf:true, emitEvent: false});
      } else {
        this.form.enable({onlySelf:true, emitEvent: false});
      }
    })
    //this.qboProducts$ = from(this.qboService.getProducts()).pipe(map(r => r.items));
  }

  searchQBProduct(term, item){
    return item.name.includes(term);
  }

  setTiers(product: Product) {
    const tiersArray = <FormArray>this.form.get('tiers');
    tiersArray.clear();
    this.form.updateValueAndValidity();
    product.tiers.forEach(tier => {
      const tierFromGroup = this.createTier(product, tier);
      tierFromGroup.setParent(tiersArray);
      tiersArray.push(tierFromGroup);
    });
    this.form.updateValueAndValidity();
  }

  addTier() {
    const tiersArray = <FormArray>this.form.get('tiers');
    tiersArray.push(this.createTier(this.product))
  }
  

  createTier(product, tier?: ProductTier) {
    const quantity = tier.packageType == 'Box' ? product.itemsPerBox : product.itemsPerMasterCarton;
    const minValidator = Validators.min(quantity);
    const maxValidator = Validators.min(this.product.itemsPerMasterCarton);



    return new FormGroup({
      packageType: new FormControl(tier?.packageType, Validators.required),
      minQuantity: new FormControl(tier?.minQuantity, [Validators.required, minValidator, c => this.validateQuantity(<FormControl>c, product.itemsPerBox)]),
      maxQuantity: new FormControl(tier?.maxQuantity, [Validators.required, maxValidator, , c => this.validateQuantity(<FormControl>c, product.itemsPerBox)]),
      baseCost:  new FormControl(tier?.baseCost, [Validators.required, Validators.min(1)]),
    });
  }

  removeTier(index) {
    const tiersArray = <FormArray>this.form.get('tiers');
    tiersArray.removeAt(index);
    this.form.updateValueAndValidity();
  }

  onSave() {
    this.onUpdate.emit(this.form.value);
  }

}
