import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API } from 'aws-amplify';
import { Customer } from '../models/customer.model';
import { AmplifyService } from 'aws-amplify-angular';

@Injectable({providedIn: 'root'})
export class CustomersService {
    constructor(private amplify: AmplifyService) { }

    async add(customer: Customer){
        await this.amplify.api().post('CXCUstomersAPI', '/customers', {body: customer});
    }

    async put(customer: Customer){
        return await this.amplify.api().put('CXCUstomersAPI', '/customers', {body: customer});
    }

    async list(repId: string = '') {
        return await this.amplify.api().get('CXCUstomersAPI', `/customers/${repId}`, {});
    }
    
    async delete(customer: Customer){
        await this.amplify.api().del('CXCUstomersAPI', `/customers/object/${customer.id}/${customer.rep}`, {});
    }
}