<div class="rep-registrattion-form" *ngIf="repData">
  <h1 class="title is-1 has-text-centered">Rep Registration</h1>
  <div class="tile is-ancestor">
    <div class="tile is-parent" style="justify-content: space-evenly;" *ngIf="currentState === repRegState.RepDataForm">
      <div class="tile is-6 is-child box">
        <div class="field is-horizontal">
          <div class="field-body">
            <div class="field">
              <div class="control has-icons-right">
                <input class="input" type="text" placeholder="First Name" [(ngModel)]="repData.firstName">
                <span class="icon is-small is-right" [ngStyle]="{'color': repData.firstName !== '' ? 'green': 'red'}">
                  <i class="fas" [ngClass]="{'fa-exclamation-triangle': repData.firstName === '', 'fa-check': repData.firstName !== ''}"></i>
                </span>
              </div>
            </div>
            <div class="field">
              <div class="control has-icons-right">
                <input class="input" type="text" placeholder="Last Name" [(ngModel)]="repData.lastName">
                <span class="icon is-small is-right" [ngStyle]="{'color': repData.lastName !== '' ? 'green': 'red'}">
                  <i class="fas" [ngClass]="{'fa-exclamation-triangle': repData.lastName === '', 'fa-check': repData.lastName !== ''}"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      
        <div class="field is-horizontal">
          <div class="field-body">
            <div class="field">
              <div class="control has-icons-right">
                <input class="input" type="text" placeholder="Address" [(ngModel)]="repData.address">
                <span class="icon is-small is-right" [ngStyle]="{'color': repData.address !== '' ? 'green': 'red'}">
                  <i class="fas" [ngClass]="{'fa-exclamation-triangle': repData.address === '', 'fa-check': repData.address !== ''}"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      
        <div class="field is-horizontal">
          <div class="field-body">
            <div class="field" *ngIf="repData.zip.length >= 5">
              <div class="control is-expanded has-icons-right">
                <input class="input" type="text" readonly="readonly" placeholder="City" [(ngModel)]="repData.city">
                <span class="icon is-small is-right" [ngStyle]="{'color': repData.city !== '' ? 'green': 'red'}">
                  <i class="fas" [ngClass]="{'fa-exclamation-triangle': repData.city === '', 'fa-check': repData.city !== ''}"></i>
                </span>
              </div>
            </div>
      
            <div class="field" *ngIf="repData.zip.length >= 5">
              <div class="control has-icons-right">
                <input class="input" type="text" readonly="readonly" placeholder="State" [(ngModel)]="repData.state">
                <!-- <div class="select is-fullwidth">
                  <select [(ngModel)]="repData.state">
                    <option selected="selected" value="">State</option>
                    <option *ngFor="let item of stateList" value="{{item}}">{{item}}</option>
                  </select>
                </div> -->
                <span class="icon is-small is-right" [ngStyle]="{'color': repData.state !== '' ? 'green': 'red'}">
                  <i class="fas" [ngClass]="{'fa-exclamation-triangle': repData.state === '', 'fa-check': repData.state !== ''}"></i>
                </span>
              </div>
            </div>
      
            <div class="field">
              <div class="control has-icons-right">
                <input class="input" type="text" placeholder="ZIP" maxlength="5" [(ngModel)]="repData.zip"
                  (keyup)="checkZipCode()">
                <span class="icon is-small is-right" [ngStyle]="{'color': repData.zip !== '' ? 'green': 'red'}">
                  <i class="fas" [ngClass]="{'fa-exclamation-triangle': repData.zip === '', 'fa-check': repData.zip !== ''}"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      
        <div class="field is-horizontal">
          <div class="field-body">
            <div class="field has-addons">
              <p class="control">
                <a class="button is-static">+1</a>
              </p>
              <div class="control is-expanded has-icons-right">
                <input class="input" type="text" placeholder="Cell" [mask]="'(000) 000-0000'" [(ngModel)]="repData.cell">
                <span class="icon is-small is-right" [ngStyle]="{'color': repData.cell !== '' ? 'green': 'red'}">
                  <i class="fas" [ngClass]="{'fa-exclamation-triangle': repData.cell === '', 'fa-check': repData.cell !== ''}"></i>
                </span>
              </div>
            </div>
            <div class="field">
              <div class="control has-icons-right">
                <input class="input" type="text" placeholder="Email" [(ngModel)]="repData.email">
                <span class="icon is-small is-right" [ngStyle]="{'color': repData.email !== '' ? 'green': 'red'}">
                  <i class="fas" [ngClass]="{'fa-exclamation-triangle': repData.email === '', 'fa-check': repData.email !== ''}"></i>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-body">
            <div class="field has-addons">
              <div class="control is-expanded has-icons-right">
                <input class="input" type="{{ showPassword ? 'password' : 'text' }}" placeholder="Password" [(ngModel)]="password" >
                <span class="icon is-small is-right" [ngStyle]="{'color': validatePassword() ? 'green': 'red'}">
                  <i class="fas" [ngClass]="{'fa-exclamation-triangle': !validatePassword(), 'fa-check': validatePassword()}"></i>
                </span>
                
              </div>
              <p class="control">
                <a class="button">
                  <!-- @gmail.com -->
                  <span class="icon is-small is-right" (click)="showPassword = !showPassword" style=" color: black; cursor: pointer; z-index: 0;">
                    <i class="fas" [ngClass]="{'fa-eye': !showPassword, 'fa-eye-slash': showPassword}"></i>
                  </span>
                </a>
              </p>
            </div>

            <div class="field">
              <div class="control has-icons-right">
                <input class="input" type="{{ showPassword ? 'password' : 'text' }}" placeholder="Confirm Password" [(ngModel)]="password2">
                <span class="icon is-small is-right" [ngStyle]="{'color': validatePassword2() ? 'green': 'red'}">
                  <i class="fas" [ngClass]="{'fa-exclamation-triangle': !validatePassword2(), 'fa-check': validatePassword2()}"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <p style="font-style: italic; font-size: 12px;">Password must be at least 8 characters long, alphanumeric, contain at least one non-alphanumeric character and one uppercase character.</p>
        </div>
      
        <div class="field is-horizontal">
          <div class="field-body">
            <div class="field">
              <div class="control has-icons-right">
                <input class="input" type="text" placeholder="Trading-as Name or LLC Name (if none, enter 'None')" [(ngModel)]="repData.tradingLlcName">
                <span class="icon is-small is-right" [ngStyle]="{'color': repData.tradingLlcName !== '' ? 'green': 'red'}">
                  <i class="fas" [ngClass]="{'fa-exclamation-triangle': repData.tradingLlcName === '', 'fa-check': repData.tradingLlcName !== ''}"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      
        <div class="field is-horizontal">
          <div class="field-body">
            <div class="field">
              <div class="control has-icons-right">
                <input class="input" type="text" placeholder="SS# or EIN" [(ngModel)]="repData.ssnEin">
                <span class="icon is-small is-right" [ngStyle]="{'color': repData.ssnEin !== '' ? 'green': 'red'}">
                  <i class="fas" [ngClass]="{'fa-exclamation-triangle': repData.ssnEin === '', 'fa-check': repData.ssnEin !== ''}"></i>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="field">
          <label class="label">All payments to reps are via ACH.</label>
          <div class="field">
            <div class="control has-icons-right">
              <input class="input" type="text" placeholder="Bank routing number" [(ngModel)]="repData.bankRoutingNumber">
              <span class="icon is-small is-right" [ngStyle]="{'color': repData.bankRoutingNumber !== '' ? 'green': 'red'}">
                <i class="fas" [ngClass]="{'fa-exclamation-triangle': repData.bankRoutingNumber === '', 'fa-check': repData.bankRoutingNumber !== ''}"></i>
              </span>
            </div>
          </div>
          <div class="field">
            <div class="control has-icons-right">
              <input class="input" type="text" placeholder="Bank account number" [(ngModel)]="repData.bankAccountNumber">
              <span class="icon is-small is-right" [ngStyle]="{'color': repData.bankAccountNumber !== '' ? 'green': 'red'}">
                <i class="fas" [ngClass]="{'fa-exclamation-triangle': repData.bankAccountNumber === '', 'fa-check': repData.bankAccountNumber !== ''}"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-container  *ngIf="currentState === repRegState.RepDetailsForm">
      <div class="tile is-parent" style="justify-content: space-around;">
        <div class="tile is-child box rep-details" [ngClass]="{'is-6': repData.territoryScope !== 'Regional'}">
          <div class="field is-horizontal">
            <div class="field-label">
              <label class="label">Years in healthcare</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control has-icons-right">
                  <label class="radio">
                    <input type="radio" name="healthcare" [(ngModel)]="repData.yearsInHealthcare" value="0-4">
                    0 - 4
                  </label>
                  <label class="radio">
                    <input type="radio" name="healthcare" [(ngModel)]="repData.yearsInHealthcare" value="5-10">
                    5 - 10
                  </label>
                  <label class="radio">
                    <input type="radio" name="healthcare" [(ngModel)]="repData.yearsInHealthcare" value="11-20">
                    11 - 20
                  </label>
                  <label class="radio">
                    <input type="radio" name="healthcare" [(ngModel)]="repData.yearsInHealthcare" value="21+">
                    21+
                  </label>
  
                  <span class="icon is-small is-right" [ngStyle]="{'color': repData.yearsInHealthcare !== '' ? 'green': 'red'}">
                    <i class="fas" [ngClass]="{'fa-exclamation-triangle': repData.yearsInHealthcare === '', 'fa-check': repData.yearsInHealthcare !== ''}"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        
          <div class="field is-horizontal">
            <div class="field-label">
              <label class="label">Level of experience with diagnostics</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control has-icons-right">
                  <label class="radio">
                    <input type="radio" name="diagnostics" [(ngModel)]="repData.experienceDiagnostics" value="Low">
                    Low
                  </label>
                  <label class="radio">
                    <input type="radio" name="diagnostics" [(ngModel)]="repData.experienceDiagnostics" value="Medium">
                    Medium
                  </label>
                  <label class="radio">
                    <input type="radio" name="diagnostics" [(ngModel)]="repData.experienceDiagnostics" value="High">
                    High
                  </label>
  
                  <span class="icon is-small is-right" [ngStyle]="{'color': repData.experienceDiagnostics !== '' ? 'green': 'red'}">
                    <i class="fas" [ngClass]="{'fa-exclamation-triangle': repData.experienceDiagnostics === '', 'fa-check': repData.experienceDiagnostics !== ''}"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        
          <div class="field is-horizontal">
            <div class="field-label">
              <label class="label">Level of experience with COVID-19</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control has-icons-right">
                  <label class="radio">
                    <input type="radio" name="covid19" [(ngModel)]="repData.experienceCovid" value="Low">
                    Low
                  </label>
                  <label class="radio">
                    <input type="radio" name="covid19" [(ngModel)]="repData.experienceCovid" value="Medium">
                    Medium
                  </label>
                  <label class="radio">
                    <input type="radio" name="covid19" [(ngModel)]="repData.experienceCovid" value="High">
                    High
                  </label>
  
                  <span class="icon is-small is-right" [ngStyle]="{'color': repData.experienceCovid !== '' ? 'green': 'red'}">
                    <i class="fas" [ngClass]="{'fa-exclamation-triangle': repData.experienceCovid === '', 'fa-check': repData.experienceCovid !== ''}"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="field is-horizontal ">
            <p class="control">
              <a class="button" style="font-weight: bold; padding-left: 0; border: none;">I have sold </a>
            </p>
            <div class="control has-icons-right" style="width: 200px">
              <div class="select is-fullwidth">
                <select [(ngModel)]="repData.relatedTests">
                  <option selected="selected" value="">Select one option</option>
                  <option value="0">0</option>
                  <option value="1 - 500">1 - 500</option>
                  <option value="501 - 5000">501 - 5,000</option>
                  <option value="5001 - 50001">5,001 - 50,000</option>
                  <option value="50001 - 500000">50,001 - 500,000</option>
                  <option value="500000+">500,000+</option>
                </select>
              </div>

              <span class="icon is-small is-right" [ngStyle]="{'color': repData.relatedTests !== '' ? 'green': 'red'}">
                <i class="fas" [ngClass]="{'fa-exclamation-triangle': repData.relatedTests === '', 'fa-check': repData.relatedTests !== ''}"></i>
              </span>
            </div>
            <p class="control">
              <a class="button" style="font-weight: bold; border: none">COVID-related tests to date</a>
            </p>
          </div>
        
          <div class="field is-horizontal">
            <div class="field-label">
              <label class="label">USA territory scope</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control has-icons-right">
                  <label class="radio">
                    <input type="radio" name="territory" [(ngModel)]="repData.territoryScope" value="National" (change)="updateTerritoryScope('National')">
                    National
                  </label>
                  <label class="radio">
                    <input type="radio" name="territory" [(ngModel)]="repData.territoryScope" value="Regional" (change)="updateTerritoryScope('Regional')">
                    Regional
                  </label>
  
                  <span class="icon is-small is-right" [ngStyle]="{'color': repData.territoryScope !== '' ? 'green': 'red'}">
                    <i class="fas" [ngClass]="{'fa-exclamation-triangle': repData.territoryScope === '', 'fa-check': repData.territoryScope !== ''}"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        
          <div class="field">
            <label class="label">Types of accounts</label>
            <div class="field">
              <div class="control has-icons-right">
                <ul>
                  <li *ngFor="let item of accountTypes">
                    <label class="checkbox">
                      <input type="checkbox" id="{{item}}" name="{{item}}" [checked]="repData.typeAccount.includes(item)" (change)="updateAccounts(item)">
                      {{item}}
                    </label>
                  </li>
                  <li>
                    <div class="field">
                      <div class="control">
                        <input class="input" type="text" placeholder="" [disabled]="!repData.typeAccount.includes('Others')" [(ngModel)]="other1">
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="field">
                      <div class="control">
                        <input class="input" type="text" placeholder="" [disabled]="!repData.typeAccount.includes('Others')" [(ngModel)]="other2">
                      </div>
                    </div>
                  </li>
                </ul>
                
                <span class="icon is-small is-right" [ngStyle]="{'color': repData.typeAccount.length > 0 ? 'green': 'red'}">
                  <i class="fas" [ngClass]="{'fa-exclamation-triangle': repData.typeAccount.length <= 0, 'fa-check': repData.typeAccount.length > 0}"></i>
                </span>
              </div>
            </div>
          </div>
        
          <div class="field is-horizontal">
            <div class="field-label">
              <label class="label" style="padding-top: 7px;">How many reps do you manage?</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control has-icons-right">
                  <!-- <label class="radio">
                    <input type="radio" name="structure" [(ngModel)]="repData.structure" value="Sole">
                    Sole
                  </label>
                  <label class="radio">
                    <input type="radio" name="structure" [(ngModel)]="repData.structure" value="Rep Group">
                    Rep Group
                  </label> -->

                  <!-- <div class="field"> -->
                    <!-- <div class="control has-icons-right"> -->
                      <div class="select reps">
                        <select [(ngModel)]="repData.structure">
                          <option selected="selected" value="">Select one option</option>
                          <option value="0">0</option>
                          <option value="1 - 25">1 - 25</option>
                          <option value="26 - 50">26 - 50</option>
                          <option value="51 - 75">51 - 75</option>
                          <option value="75+">75+</option>
                        </select>
                      </div>
                    <!-- </div> -->
                  <!-- </div> -->
  
                  <span class="icon is-small is-right" [ngStyle]="{'color': repData.structure !== '' ? 'green': 'red'}">
                    <i class="fas" [ngClass]="{'fa-exclamation-triangle': repData.structure === '', 'fa-check': repData.structure !== ''}"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
  
          <div class="field is-horizontal">
            <div class="field-label">
              <label class="label">Reach</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control has-icons-right">
                  <label class="radio">
                    <input type="radio" name="reach" [(ngModel)]="repData.reach" value="USA only">
                    USA only
                  </label>
                  <label class="radio">
                    <input type="radio" name="reach" [(ngModel)]="repData.reach" value="International">
                    International (Including USA)
                  </label>
  
                  <span class="icon is-small is-right" [ngStyle]="{'color': repData.reach !== '' ? 'green': 'red'}">
                    <i class="fas" [ngClass]="{'fa-exclamation-triangle': repData.reach === '', 'fa-check': repData.reach !== ''}"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        
        </div>
      </div>
  
      <div class="tile is-6 is-parent" *ngIf="repData.territoryScope === 'Regional'">
        <div class="tile is-child box">
          <h5 class="title is-5">Please select your top states:</h5>
          <div class="field">
            <div class="control">
              <ul class="state-list">
                <li *ngFor="let item of stateList">
                  <label class="checkbox">
                    <input type="checkbox" id="{{item}}" name="{{item}}" [checked]="repData.regionalTerritoryList.includes(item)" (change)="updateTerritoryList(item)" />
                    {{item}}
                  </label>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="tile is-parent" style="justify-content: space-evenly;" *ngIf="currentState === repRegState.RepInternationalForm">
      <div class="tile is-8 is-child box">
        <h3 class="title is-3">International Reach</h3>
        <div class="field">
          <div class="control">
            <ul class="country-list">
              <li *ngFor="let item of countryList">
                <label class="checkbox">
                  <input type="checkbox" id="{{item}}" name="{{item}}" [checked]="repData.internationalReach.includes(item)" (change)="updateCountryList(item)" />
                  {{item}}
                </label>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="buttons field is-grouped is-grouped-centered" style="margin-top: 30px">
  <button class="button is-danger" (click)="back()" *ngIf="currentState !== repRegState.RepDataForm">
    <span class="icon">
      <i class="fas fa-chevron-left"></i>
    </span>
    <span>Back</span>
  </button>
  <button class="button is-primary" (click)="next()">
    <span>{{getButtonLabel()}}</span>
    <span class="icon" *ngIf="getButtonLabel() !== 'Register'">
      <i class="fas fa-chevron-right"></i>
    </span>
  </button>
</div>

<app-modal
  *ngIf="showRegisterResultModal"
  okButtonText="Ok"
  [isSmallWidthModal]=true
  [showCancelButton]=false
  (onOk)="okRegisterModal()"
>
  <ng-container *ngIf="registerResult">
    <h1 class="title has-text-centered">Successfully registered</h1>
    <p>You will receive an email with link to confirm your registration.</p>
    <p>To login you will need to enter your email address and your password.</p>
    <p>Click 'Ok' to be redirected to the login page.</p>
  </ng-container>
  <ng-container *ngIf="!registerResult">
    <h1 class="title has-text-centered">Error with register</h1>
    <p>There was an error with the register process.</p>
    <p style="margin: 15px; font-style: italic;">{{errorMessage}}</p>
    <p>Hit the 'Ok' button and try again!</p>
  </ng-container>
  
</app-modal>
