import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WizardComponent } from 'angular-archwizard';
import { Observable, of, Subject } from 'rxjs';
import { Customer } from 'src/app/models/customer.model';
import { OrderEntry } from 'src/app/models/view/order-entry-viewmodel';
import { EmailService } from 'src/app/services/email.service';
import { RepsService } from 'src/app/services/reps.service';

@Component({
  selector: 'app-customer-order-form',
  templateUrl: './customer-order-form.component.html',
  styleUrls: ['./customer-order-form.component.scss']
})
export class CustomerOrderFormComponent implements OnInit {
  @ViewChild('wizard') wizardComponent: WizardComponent;
  orderEntry: OrderEntry;
  repEmail: any;

  orderEntry$$ = new Subject<OrderEntry>();
  shipDetails: any;

  addPaymentToInvoice = false;
  paymentResults: any;
  resetPaymentMethod$ = new Subject<void>();

  constructor(private activatedRoute: ActivatedRoute, private emailService: EmailService, private repService: RepsService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(async (params) => {
      try {
        this.repEmail = params['repId'];
        if (!this.repEmail || !this.isValidEmail(this.repEmail)) throw new Error("Broken link");
        //this.rep = await this.repService.getRep(params['repId']); 
      } catch (error) {
        alert('Broken link');
        window.location.href = window.location.origin; //TODO show error message or redirect to main page
      }
    });
  }

  async onOrderReady(order) {
    this.orderEntry = order;
    this.orderEntry$$.next(order);
    this.wizardComponent.goToNextStep();
  }

  goToPayment(shipDetails) {
    this.shipDetails = shipDetails;
    this.wizardComponent.goToStep(this.lessThan15000() ? 2 : 3);
  }

  calculateOrderAmount() {
    const shipCost = this.shipDetails?.shipmentRate?.shippingAmount?.amount;
    return this.orderEntry?.orderExtension + (this.shipDetails?.billShipmentTo == 'customer' ? shipCost : 0);
  }

  lessThan15000() {
    return this.shipDetails?.billShipmentTo == 'customer'
      ? (this.orderEntry?.orderExtension + this.shipDetails?.shipmentRate?.shippingAmount?.amount <= 15000)
      : this.orderEntry?.orderExtension <= 15000;
  }

  async paymentCompletedHandler(paymentResult) {
    this.paymentResults = paymentResult;
    if (this.paymentResults?.result.messages.resultCode == 'Ok'
      && this.paymentResults?.result.transactionResponse?.responseCode == 1
      && !this.paymentResults?.result.transactionResponse?.errors) {
      await this.submitOrder();
      this.wizardComponent.goToStep(4);
    }
  }

  async submitOrder() {
    try {
      await this.emailService.sendOrderRequestToRep(this.repEmail, this.shipDetails, this.orderEntry, this.paymentResults, this.addPaymentToInvoice);
    } catch (error) {
      console.error(error);
    }
  }

  finish() {
    window.location.reload();
    //TODO reset state of child components
    //    this.orderEntry = null;
    //    this.shipDetails = null;
    //    this.paymentResults = null;
    //    this.addPaymentToInvoice = false;
    //    this.resetPaymentMethod$.next();
  }

  isValidEmail(input) {
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return input.match(validRegex) != null;
  }
}
