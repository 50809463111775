<div class="is-title-bar" style="height: 50px;">
  <div class="level">
    <div class="level-left">
      <h1 class="title is-4">Customers List</h1>
    </div>
    <div class="level-right">
      <div class="info" style="margin-right: 120px;">
        <div class="field has-addons">
          <p class="control">
            <span class="select">
              <select [(ngModel)]="filterByVal" *ngIf="repsList">
                <option value="">Filter by...</option>
                <option *ngFor="let item of repsList" [value]="item.email">{{item.firstName + ' ' + item.lastName}} ({{item.email}})</option>
              </select>
            </span>
          </p>
          
          <p class="control">
            <a class="button is-info" >
              Filter
            </a>
          </p>
          <p class="control" *ngIf="filtered">
            <a class="button is-danger">
              Clear
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>