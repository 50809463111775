import { Component, OnInit } from '@angular/core';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { Product } from 'src/app/models/product.model';
import { ProductsService } from 'src/app/services/products.service';

@Component({
  selector: 'app-product-info',
  templateUrl: './product-info.component.html',
  styleUrls: ['./product-info.component.scss']
})
export class ProductInfoComponent implements OnInit {

  products: Product[] = [];
  constructor(private productsService: ProductsService, private spinner: SpinnerVisibilityService) { }

  async ngOnInit() {  
    this.spinner.show();
    this.products = await this.productsService.getActiveProducts();
    this.spinner.hide();
  }

  divide(a, b) {
    return Math.round(a/b);
  }

  getPackageRange(items, min, max) {
    const count = this.divide(min, items);
    const maxCount = this.divide(max, items);
    return `${count>=1 ? count : ''}${count >= 1 ? '-' :''}${maxCount}`;
  }

}
