import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Order } from '../models/order.model';
import { environment } from '../../environments/environment';
import { UsersService } from './users.service';
import { API } from 'aws-amplify';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  constructor(private http: HttpClient, private users: UsersService) { }

  processZipCode(zipCode) {
    const url = `https://api.zip-codes.com/ZipCodesAPI.svc/1.0/QuickGetZipCodeDetails/${zipCode}?key=${environment.zipCodeApiKey}`;
    // const url = `https://api.zip-codes.com/ZipCodesAPI.svc/1.0/QuickGetZipCodeDetails/${zipCode}?key=DEMOAPIKEY`;
		return this.http.get(url).toPromise();
  }

  processOrder(order: Order) {
    return API.post('CXOrdersAPI', '/orders', {body: order});
  }

  processHcpOrder(order) {
    return API.post('CXHcpOrdersAPI', '/orders', {body: order});
  }

  getHcpDetails(email) {
    return API.get('CXHcpOrdersAPI', `/orders/potential/${email}`, {});
  }

  async sendHcpOrderEmail(orderData) {
    const emailHtml = await this.generateHcpOrderEmail(orderData);
    const url = `${environment.apiUrl}/email`;
    return this.http.post(url, {to: orderData.customer.billingAddress.email, cc: environment.recepients, html: emailHtml}).toPromise();
  }

  async sendEmailOrder(orderData: Order) {
    const emailHtml = await this._generateFormOrderEmail(orderData);
    const url = `${environment.apiUrl}/email`;
    const to = await this.users.getCurrentUserEmail();
    return this.http.post(url, {to: to, cc: environment.recepients, html: emailHtml}).toPromise();
  }

  private async generateHcpOrderEmail(order) {
    try {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });
        let totalOrder = 0;
        let subTotalOrder = 0;
        let shippingSubTotal = 0;
        const d = new Date(order.date);
        const orderDate = d.toLocaleDateString();
        
        order.orderProducts.forEach(item => {
          totalOrder += (item.sellingQuantity * item.sellingPrice);
          subTotalOrder += (item.sellingQuantity * item.sellingPrice);
          // shippingSubTotal += (item.shippingPrice * 1);
          // totalOrder += (item.shippingPrice * 1);
        });
        shippingSubTotal = order.shipmentRate.shippingAmount.amount;
        totalOrder += shippingSubTotal;
        console.log(totalOrder);
        const totalBill = order.billShipmentTo == 'customer' ? totalOrder : order.orderExtension
        
        let template = `
        <table class="table is-fullwidth" style="border: none; width: 100%;">
          <tr>
            <td  style="border: 1px solid black; padding: 10px;"><img src="https://d336e2ayaeatn5.cloudfront.net/assets/dsg_logo.png"></td>
          </tr>
          <tr>
            <td  style="border: 1px solid black; padding: 10px;">
            In case of any questions please contact your DSG specialist at (212) 385 4547 or via email: jscrocco@diagnosticsolutionsgroup.com<br>
              <h1 class="title is-3">Order Confirmation</h1>
              <h3 class="title is-3">Order #${order.orderNumber}</h3>
            </td>
          </tr>
        </table>
        <table class="table is-fullwidth" style="width: 100%;border-collapse: collapse;border: 1px solid black;">
          <tr style="background-color: #ccc">
            <th>Order Date</th>
            <th>Shipping Method</th>
            <th>Ship Via</th>
          </tr>
          <tr>
            <td style="border: 1px solid black; padding: 10px;">${orderDate}</td>
            <td style="border: 1px solid black; padding: 10px;">${order.shipmentRate.carrierFriendlyName ?? ''}</td>
            <td style="border: 1px solid black; padding: 10px;">${order.shipmentRate.serviceType ?? ''}</td>
          </tr>
        </table>
        <br/>
        <br/>
        <table class="table is-fullwidth" style="width: 100%;border-collapse: collapse;border: 1px solid black;">
          <tr style="background-color: #ccc">
            <th>Ship To</th>
            <th>Bill To (Customer)</th>
            <th>Payment</th>
          </tr>
          <tr>
            <td style="border: 1px solid black; padding: 10px;">
              ${order.shipping.firstName + ' ' + order.shipping.lastName}<br />
              ${order.shipping.address }<br />
              ${order.shipping.city + ', ' + order.shipping.state + ' ' + order.shipping.zip }<br>
              USA<br />
              ${order.shipping.phone}
            </td>
            <td style="border: 1px solid black; padding: 10px;">
              ${order.customer.billingAddress.firstName + ' ' + order.customer.billingAddress.lastName}<br />
              ${order.customer.billingAddress.address }<br />
              ${order.customer.billingAddress.city + ', ' + order.customer.billingAddress.state + ' ' + order.customer.billingAddress.zip }<br>
              USA<br />
              ${order.customer.billingAddress.phone}<br />
              ${order.customer.billingAddress.email}<br />
            </td>
            <td style="border: 1px solid black; padding: 10px;">
              ${order.addPaymentToInvoice ? 'Credit card payment via the invoice': ''}<br />
              ${order?.payment?.result?.transactionResponse && !order.addPaymentToInvoice ? 'Card Type: ' + order?.payment?.result?.transactionResponse?.accountType : ''}<br />
              ${order?.payment?.result?.transactionResponse && !order.addPaymentToInvoice ? 'Card Number: ' + order?.payment?.result?.transactionResponse?.accountNumber : ''}<br />
              ${order?.payment?.result?.transactionResponse && !order.addPaymentToInvoice ? 'Amount Charged:' + formatter.format(totalBill) : '' }
            </td>
          </tr>
        </table>
        <br/><br/>
        <table class="table is-fullwidth" style="width: 100%;border-collapse: collapse;border: 1px solid black;">
          <tr style="background-color: #ccc">
            <th style="text-align: center;" colspan="3" class="has-text-centered">Product</th>
            <th style="text-align: center;" class="has-text-centered">Qty</th>
            <!--<th style="text-align: center;" class="has-text-centered">Price</th>-->
            <th style="text-align: center;" class="has-text-centered" style="width: 150px">Total</th>
          </tr>`;
          order.orderProducts.forEach(item => {
            template += `
            <tr *ngFor="let item of order.orderProducts">
              <td colspan="3" style="vertical-align: middle;text-align: right;" class="has-text-centered">${item.name} Box of ${item.quantity}</td>
              <td style="vertical-align: middle;text-align: center;" class="has-text-centered">${item.sellingQuantity/item.quantity}</td>
              <!--<td style="vertical-align: middle;text-align: center;" class="has-text-centered">${formatter.format(item.sellingPrice)}</td>-->
              <td style="vertical-align: middle;text-align: center;" class="has-text-centered">${formatter.format((item.sellingQuantity * item.sellingPrice))}</td>
            </tr>`  
          });
          
          template += `
          <tr style="background-color: #ccc">
            <td colspan="5"><br></td>
          </tr>
          <tr>
            <th colspan="4" style="text-align: right" class="has-text-right">Subtotal:</th>
            <td class="has-text-right" style="text-align: right;">${formatter.format(subTotalOrder)}</td>
          </tr>
          <tr>
            <td colspan="3"></td>
            <th style="background-color: #ccc; text-align: right" class="has-text-right">Shipping:</th>
            <td style="background-color: #ccc; text-align: right" class="has-text-right">${formatter.format(shippingSubTotal)}</td>
          </tr>
          <tr>
          <td colspan="3"></td>
          <th style="text-align: right" class="has-text-right">Tax</th>
          <td style="text-align: right" class="has-text-right">${formatter.format(0)}</td>
        </tr>
          <tr>
            <td colspan="3"></td>
            <th style="text-align: right" class="has-text-right">Total</th>
            <td style="text-align: right" class="has-text-right">${formatter.format(totalOrder)}</td>
          </tr>
          <tr>
          <td colspan="3"></td>
          <th style="text-align: right" class="has-text-right">Balance owed</th>
          <td style="text-align: right" class="has-text-right">${order.addPaymentToInvoice ? formatter.format(totalOrder) : formatter.format(0)}</td>
        </tr>
          <tr>
            <td colspan="5">
              <h1 style="text-align: center" class="title is-2 has-text-centered">Thank you for your order!</h1>
            </td>
          </tr>
        </table> `;
      
        // template += "</table>";
      
        return template;
    }
    catch(err) {
      throw(err);
    }
  }

  private async _generateFormOrderEmail(order) {
    try {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });
        let totalOrder = 0;
        let subTotalOrder = 0;
        let shippingSubTotal = 0;
        const d = new Date(order.date);
        const orderDate = d.toLocaleDateString();
        
        order.orderProducts.forEach(item => {
          totalOrder += (item.sellingQuantity * item.sellingPrice);
          subTotalOrder += (item.sellingQuantity * item.sellingPrice);
          // shippingSubTotal += (item.shippingPrice * 1);
          // totalOrder += (item.shippingPrice * 1);
        });
        shippingSubTotal = order.shipmentRate.shippingAmount.amount;
        totalOrder += shippingSubTotal;
        console.log(totalOrder);
        const totalBill = order.billShipmentTo == 'customer' ? totalOrder : order.orderExtension
        
        let template = `
        <table class="table is-fullwidth" style="border: none; width: 100%;">
          <tr>
            <td  style="border: 1px solid black; padding: 10px;"><img src="https://d336e2ayaeatn5.cloudfront.net/assets/dsg_logo.png"></td>
          </tr>
          <tr>
            <td  style="border: 1px solid black; padding: 10px;">
            In case of any questions please contact your DSG specialist at (212) 385 4547 or via email: jscrocco@diagnosticsolutionsgroup.com<br>
              <h1 class="title is-3">Order Confirmation</h1>
              <h3>Reference:${order.reference ?? ''}</h3>
            </td>
          </tr>
        </table>
        <table class="table is-fullwidth" style="width: 100%;border-collapse: collapse;border: 1px solid black;">
          <tr style="background-color: #ccc">
            <th>Order Date</th>
            <th>Shipping Method</th>
            <th>Ship Hours</th>
            <th>Shipment Type</th>
            <th>Ship Via</th>
            <th>Bill Shipment to</th>
          </tr>
          <tr>
            <td style="border: 1px solid black; padding: 10px;">${orderDate}</td>
            <td style="border: 1px solid black; padding: 10px;">${order.shipmentRate.carrierFriendlyName ?? ''}</td>
            <td style="border: 1px solid black; padding: 10px;">${order.shipping.hours}</td>
            <td style="border: 1px solid black; padding: 10px; color: ${order.shipmentType != 'Single'? 'red' : 'green'}">${order.shipmentType}</td>
            <td style="border: 1px solid black; padding: 10px;">${order.shipmentRate.serviceType ?? ''}</td>
            <td style="border: 1px solid black; padding: 10px;">${order.billShipmentTo == 'customer' ? 'CUSTOMER' : 'REP'}</td>
          </tr>
        </table>
        <br/>
        <br/>
        <table class="table is-fullwidth" style="width: 100%;border-collapse: collapse;border: 1px solid black;">
          <tr style="background-color: #ccc">
            <th>Ship To</th>
            <th>Bill To (Customer)</th>
            <th>Payment</th>
          </tr>
          <tr>
            <td style="border: 1px solid black; padding: 10px;">
              ${order.shipping.firstName + ' ' + order.shipping.lastName}<br />
              ${order.shipping.address }<br />
              ${order.shipping.city + ', ' + order.shipping.state + ' ' + order.shipping.zip }<br>
              USA<br />
              ${order.shipping.phone}
            </td>
            <td style="border: 1px solid black; padding: 10px;">
              ${order.customer.billingAddress.title + ' ' + order.customer.billingAddress.firstName + ' ' + order.customer.billingAddress.lastName}<br />
              ${order.customer.billingAddress.address }<br />
              ${order.customer.billingAddress.city + ', ' + order.customer.billingAddress.state + ' ' + order.customer.billingAddress.zip }<br>
              USA<br />
              ${order.customer.billingAddress.phone}<br />
              ${order.customer.billingAddress.email}<br />
            </td>
            <td style="border: 1px solid black; padding: 10px;">
              ${totalBill > 15000  ? 'ACH' : ''}
              ${totalBill <= 15000 && order.addPaymentToInvoice ? 'Credit card payment via the invoice': ''}<br />
              ${totalBill <= 15000 && order?.payment?.result?.transactionResponse && !order.addPaymentToInvoice ? 'Card Type: ' + order?.payment?.result?.transactionResponse?.accountType : ''}<br />
              ${totalBill <= 15000 && order?.payment?.result?.transactionResponse && !order.addPaymentToInvoice ? 'Card Number: ' + order?.payment?.result?.transactionResponse?.accountNumber : ''}<br />
              ${totalBill <= 15000 && order?.payment?.result?.transactionResponse && !order.addPaymentToInvoice ? 'Amount Charged:' + formatter.format(totalBill) : '' }
            </td>
          </tr>
        </table>
        <br/><br/>
        <table class="table is-fullwidth" style="width: 100%;border-collapse: collapse;border: 1px solid black;">
          <tr style="background-color: #ccc">
            <th style="text-align: center;" colspan="2" class="has-text-centered">Product</th>
            <th style="text-align: center;" class="has-text-centered">Qty</th>
            <th style="text-align: center;" class="has-text-centered">Selling Price Extension</th>
            <th style="text-align: center;" class="has-text-centered" style="width: 150px">Total</th>
          </tr>`;
          order.orderProducts.forEach(item => {
            template += `
            <tr *ngFor="let item of order.orderProducts">
              <td colspan="2" style="vertical-align: middle;text-align: center;" class="has-text-centered">${item.name} Box of ${item.quantity}</td>
              <td style="vertical-align: middle;text-align: center;" class="has-text-centered">${item.sellingQuantity/item.quantity}</td>
              <td style="vertical-align: middle;text-align: center;" class="has-text-centered">${formatter.format(item.sellingPrice)}</td>
              <td style="vertical-align: middle;text-align: center;" class="has-text-centered">${formatter.format((item.sellingQuantity * item.sellingPrice))}</td>
            </tr>`  
          });
          
          template += `
          <tr style="background-color: #ccc">
            <td colspan="5"><br></td>
          </tr>
          <tr>
            <th colspan="4" style="text-align: right" class="has-text-right">Subtotal:</th>
            <td class="has-text-right" style="text-align: right;">${formatter.format(subTotalOrder)}</td>
          </tr>
          <tr>
            <td colspan="3"></td>
            <th style="background-color: #ccc; text-align: right" class="has-text-right">Shipping:</th>
            <td style="background-color: #ccc; text-align: right" class="has-text-right">${formatter.format(shippingSubTotal)}</td>
          </tr>
          <tr>
            <td colspan="3"></td>
            <th style="text-align: right" class="has-text-right">Total</th>
            <td style="text-align: right" class="has-text-right">${formatter.format(totalOrder)}</td>
          </tr>
          <tr>
            <td colspan="5">
              <h1 style="text-align: center" class="title is-2 has-text-centered">Thank you for your order!</h1>
            </td>
          </tr>
        </table> `;
      
        // template += "</table>";
      
        return template;
    }
    catch(err) {
      throw(err);
    }
  }

  private async _getEmailTemplate() {
    try {
       const url = 'http://localhost:4200/assets/email.form.html';
      //const url = 'https://d336e2ayaeatn5.cloudfront.net/assets/email.form.html';
      const emailTemplate = await this.http.get(url, {responseType: 'text'}).toPromise();
      return emailTemplate;
    }
    catch(err) {
      console.log(err);
    }
  }

  getOrders(month = 0) {
    return API.get('CXOrdersAPI', `/orders?last_month=${month}`, {});
  }

  
  getLastMonthCommissions() {
    return API.get('CXOrdersAPI', `/orders/commissions`, {});
  }

  getOrderByEmail(email: string) {
    return API.get('CXOrdersAPI', `/orders?email=${email}`, {});
  }

  getHcpOrderById(id: string) {
    return API.get('CXHcpOrdersAPI', `/orders/${id}`, {});
  }

  public newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
}
