import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Rep } from '../models/rep.model';
import { API } from 'aws-amplify';


@Injectable({
  providedIn: 'root'
})
export class RepsService {

  constructor(private http: HttpClient) { }

  createRep(repData: Rep) {
    return API.post('CXRepsAPI', '/reps', {body: repData});
  }

  updateRep(repData: Rep) {
    return API.post('CXRepsAPI', '/reps', {body: repData});
  }

  getReps(): Promise<any[]> {
    return API.get('CXRepsAPI', '/reps', {});
  }

  getRep(email) {
    return API.get('CXRepsAPI', `/reps/${email}`, {});
  }

  createNote(noteData) {
    noteData.id = this._newGuid();
    return API.post('CXRepsAPI', '/reps/notes', {body: noteData});
  }

  getNotes() {
    return API.get('CXRepsAPI', '/reps/notes', {});
  }

  private _newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
}
