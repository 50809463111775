<div class="modal is-active">
  <div class="modal-background"></div>
  <div class="modal-card" [ngClass]="{'is-big-height': isBigHeightModal, 'is-small-height': isSmallHeightModal, 'is-small-width': isSmallWidthModal}">
    <header class="modal-card-head" *ngIf="modalTitle !== ''">
      <p class="modal-card-title">{{modalTitle}}</p>
    </header>
    <section class="modal-card-body">
      <ng-content></ng-content>
    </section>
    <footer *ngIf="showFooter" class="modal-card-foot buttons">
      <button *ngIf="showCancelButton" class="button white is-warning" (click)="cancel()">{{ cancelButtonText }}</button>
      <button *ngIf="showOkButton" [disabled]="!isOkButtonEnable" class="button ok white is-success" (click)="ok()">{{ okButtonText }}</button>
    </footer>
  </div>
</div>