import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Customer } from 'src/app/models/customer.model';
import * as _ from 'lodash';
import { ShippingService } from 'src/app/services/shipping.service';
import { EMPTY, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { BaseFormComponent } from '../../shared/base-form/base-form.component';
import { OrdersService } from 'src/app/services/orders.service';
import { CustomersService } from 'src/app/services/customers.service';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { OrderEntry } from 'src/app/models/view/order-entry-viewmodel';
import emailMask from 'text-mask-addons/dist/emailMask';

@Component({
  selector: 'app-customer-shipping-details',
  templateUrl: './customer-shipping-details.component.html',
  styleUrls: ['./customer-shipping-details.component.scss']
})
export class CustomerShippingDetailsComponent extends BaseFormComponent implements OnInit {

  @Input() nextButtonLabel = 'Submit Order';
  @Input() isNewCustomer = false;
  @Input() allowPickShipmentService = false;
  @Input() allowPickShippingPayer = true;
  @Input() showCommission = false;
  @Input() customer$: Observable<Customer>;
  @Input() orderEntry$: Observable<OrderEntry>;

  @Output() onShipDetailsReady = new EventEmitter<any>();

  form: FormGroup;

  showUpdateShippingCheckbox = false;
  confirmUpdateShipping = false;
  customer: Customer;
  sellingQuantity: number = 0;
  rates$: Observable<any>;
  destroy$ = new Subject<void>();
  orderEntry: OrderEntry;
  mask = emailMask;
  toggleWarning = true;

  constructor(private shippingService: ShippingService, private spinner: SpinnerVisibilityService, orders: OrdersService, private customersService: CustomersService) {
    super(orders);
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      id: new FormControl(''),
      clia: new FormControl({ value: '', disabled: !this.isNewCustomer }, { validators: [Validators.required] }),
      legalName: new FormControl({ value: '', disabled: !this.isNewCustomer }, { validators: [Validators.required] }),
      dba: new FormControl(''),
  
      billingAddress: new FormGroup({
        firstName: new FormControl({ value: '', disabled: !this.isNewCustomer }, { validators: [Validators.required] }),
        lastName: new FormControl({ value: '', disabled: !this.isNewCustomer }, { validators: [Validators.required] }),
        title: new FormControl({ value: '', disabled: !this.isNewCustomer }, { validators: [Validators.required] }),
        address: new FormControl({ value: '', disabled: !this.isNewCustomer }, { validators: [Validators.required] }),
        city: new FormControl({ value: '', disabled: !this.isNewCustomer }, { validators: [Validators.required] }),
        state: new FormControl({ value: '', disabled: !this.isNewCustomer }, { validators: [Validators.required] }),
        zip: new FormControl({ value: '', disabled: !this.isNewCustomer }, { validators: [Validators.required, Validators.minLength(5), Validators.maxLength(5)] }),
        phone: new FormControl({ value: '', disabled: !this.isNewCustomer }, { validators: [Validators.required, Validators.minLength(10), Validators.maxLength(11)] }),
        email: new FormControl({ value: '', disabled: !this.isNewCustomer }, { validators: [Validators.required, Validators.email] }),
        shippingIsTheSame: new FormControl('')
      }),
  
      shippingAddress: new FormGroup({
        firstName: new FormControl('', { validators: [Validators.required] }),
        lastName: new FormControl('', { validators: [Validators.required] }),
        address: new FormControl('', { validators: [Validators.required] }),
        city: new FormControl({ value: '', disabled: false }, { validators: [Validators.required] }),
        state: new FormControl({ value: '', disabled: false }, { validators: [Validators.required] }),
        zip: new FormControl('', { validators: [Validators.required, Validators.minLength(5), Validators.maxLength(5)] }),
        phone: new FormControl('', { validators: [Validators.required, Validators.minLength(10), Validators.maxLength(11)] }),
        hours: new FormControl('9-5', { validators: [Validators.required] }),
      }),
      shipmentType: new FormControl('Single', { validators: [Validators.required] }),
      shipmentRate: new FormControl(undefined, { validators: [Validators.required] }),
      billShipmentTo: new FormControl('customer', { validators: [Validators.required] })
    });

    this.form.get('shippingAddress').valueChanges.pipe(debounceTime(500)).subscribe(c => {
      const current = this.form.getRawValue().shippingAddress;
      this.showUpdateShippingCheckbox = !this.isNewCustomer && this.customer ? !_.isEqual(this.customer.shippingAddress, current) : false;
      if (this.form.get('shippingAddress').valid && this.allowPickShipmentService) {
        this.getRates();
        this.form.get('shipmentRate').patchValue(null);
      }
    });
    
    if (this.customer$) {
      this.customer$.pipe(takeUntil(this.destroy$)).subscribe({
        next: c => {
          this.customer = c;
          this.form.patchValue(this.customer);
        }
      });
    }

    this.orderEntry$.subscribe({
      next: o => {
        this.orderEntry = o;
        this.sellingQuantity = o.orderProducts.map(p => p.sellingQuantity).reduce((c, current) => current += c);
        if(this.form.get('shippingAddress').valid) this.getRates();
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  getRates() {
    const form = this.form.getRawValue();
    if (!this.allowPickShipmentService || this.sellingQuantity == 0 || (!this.form.get('shippingAddress').valid)) {
      this.rates$ = EMPTY;
      return;
    }

    this.spinner.show();
    this.rates$ = this.shippingService.getRates({
      legalName: form.legalName,
      shippingAddress: form.shippingAddress,
      billingAddress: form.billingAddress,
      order: { sellingQuantity: this.sellingQuantity }
    })
      .pipe(
        map(r => r['rateResponse'].rates.sort((a, b) => a.estimatedDeliveryDate > b.estimatedDeliveryDate ? 1 : -1)),
        map(r => {
          r.forEach(rate => {
            const baseCost = rate?.otherAmount?.amount + rate?.shippingAmount?.amount;
            rate.shippingAmount.amount = baseCost + baseCost * 0.15;
          });
          return r;
        }),
        map(rates => rates.filter(r => r.serviceCode.includes("fedex_2day"))), //TODO make it configurable
        tap(() => {
          this.spinner.hide();
          this.form.updateValueAndValidity();
        }),
        catchError(s => {
          this.spinner.hide();
          return of({ error: s.message });
        }),
        take(1));
  }

  async updateCustomerIfShippingAddressChanged() {
    if (!this.showUpdateShippingCheckbox || !this.confirmUpdateShipping) return Promise.resolve();
    const customer = Object.assign(this.customer);
    customer.shippingAddress = this.form.getRawValue().shippingAddress;
    await this.customersService.put(customer);
  }

  round(v) {
    return Math.max(v, 0);
  }

  canGoNext() {
    if(this.allowPickShipmentService) {
      return !this.isInvalid('billingAddress')
      && !this.isInvalid('shippingAddress')
      && !this.isInvalid('shipmentRate')
      && !this.isInvalid('shipmentType')
      && !this.isInvalid('billShipmentTo')
      && !this.isInvalid('clia')
      && !this.isInvalid('legalName')
      && (this.form.value.billShipmentTo == 'rep' 
          ? (this.orderEntry?.orderCommission - this.form.value.shipmentRate?.shippingAmount?.amount) >= 1 
          : (this.isNewCustomer ? true : this.orderEntry?.orderCommission >= 0));
    }
    return !this.isInvalid('billingAddress') && !this.isInvalid('shippingAddress');
  }

  next() {
    this.onShipDetailsReady.emit(this.form.getRawValue());
  }
}
