<div class="columns  is-multiline" [formGroup]="form">
    <div class="column is-full">
        <div class="field">
            <label class="label">Product name</label>
            <div class="control has-icons-right">
                <input class="input" type="text" autofocus placeholder="Product Name" formControlName="name">
                <span class="icon is-small is-right icon-red" *ngIf="isInvalid('name')">
                    <i class="fas fa-exclamation-triangle"></i>
                </span>
                <span class="icon is-small is-right icon-green" *ngIf="!isInvalid('name')">
                    <i class="fas fa-check"></i>
                </span>
            </div>
            <p class="help is-danger" *ngIf="isInvalid('name')" class="help is-danger">Name is required
            </p>
        </div>
    </div>
    <!--<div class="column is-full">
        <div class="field">
            <label class="label">QuickBooks Online Product Mapping</label>
            <div class="control has-icons-right">
                <ng-select #select [items]="qboProducts$|async"
                [editableSearchTerm]="true"
                [bindValue]="'id'"
                [searchable]="true"
                formControlName="qbItemId" dropdownPosition="auto" 
                labelForId="id"
                [loading]="!(qboProducts$|async)" 
                [searchFn]="searchQBProduct"
                placeholder="Select a QBO product" [virtualScroll]="true">
        
                <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                  <div>
                    <span [ngOptionHighlight]="search">{{item.name}} {{item ? (item.active ? ' (Active)' : ' (Disabled)') : ''}}</span>
                </div>
                </ng-template>
                <ng-template ng-label-tmp let-item="item">
                  {{item.name}} {{item ? (item.active ? ' (Active)' : ' (Disabled)') : ''}}
                </ng-template>
              </ng-select>
                <span class="icon is-small is-right icon-red" *ngIf="isInvalid('qbItemId')">
                    <i class="fas fa-exclamation-triangle"></i>
                </span>
                <span class="icon is-small is-right icon-green" *ngIf="!isInvalid('qbItemId')">
                    <i class="fas fa-check"></i>
                </span>
            </div>
            <p class="help is-danger" *ngIf="isInvalid('qbItemId')" class="help is-danger">QB Product Id is required
            </p>
        </div>
    </div>-->
    <div class="column is-full">
        <div class="field">
            <label class="label">Warehouse SKU</label>
            <div class="control has-icons-right">
                <input class="input" type="text" autofocus placeholder="SKU in Warehouse Management System" formControlName="wmsSku">
                <span class="icon is-small is-right icon-red" *ngIf="isInvalid('wmsSku')">
                    <i class="fas fa-exclamation-triangle"></i>
                </span>
                <span class="icon is-small is-right icon-green" *ngIf="!isInvalid('wmsSku')">
                    <i class="fas fa-check"></i>
                </span>
            </div>
            <p class="help is-danger" *ngIf="isInvalid('wmsSku')" class="help is-danger">WMS SKU is required and it should has less than 50 characters
            </p>
        </div>
    </div>
    <div class="column is-full">
        <div class="field">
            <label class="label">Quantities</label>
        </div>
        <div class="column is-full">
            <div class="field is-horizontal">
                <div class="field-body">
                    <div class="field has-addons">
                        <p class="control">
                            <a class="button is-static">
                                Per Box
                            </a>
                        </p>
                        <div class="control has-icons-right">
                            <input class="input" type="number" placeholder="Quantity per box"
                                formControlName="itemsPerBox">
                            <span class="icon is-small is-right icon-red" *ngIf="isInvalid('itemsPerBox')">
                                <i class="fas fa-exclamation-triangle"></i>
                            </span>
                            <span class="icon is-small is-right icon-green" *ngIf="!isInvalid('itemsPerBox')">
                                <i class="fas fa-check"></i>
                            </span>
                            <p class="help is-danger" *ngIf="isInvalid('itemsPerBox')" class="help is-danger">Quantity
                                per
                                box is
                                required
                            </p>
                        </div>
                    </div>
                    <div class="field has-addons">
                        <p class="control">
                            <a class="button is-static">
                                Per MC
                            </a>
                        </p>
                        <div class="control has-icons-right">
                            <input class="input" type="number" placeholder="Quantity per MC"
                                formControlName="itemsPerMasterCarton">
                            <span class="icon is-small is-right icon-red" *ngIf="isInvalid('itemsPerMasterCarton')">
                                <i class="fas fa-exclamation-triangle"></i>
                            </span>
                            <span class="icon is-small is-right icon-green" *ngIf="!isInvalid('itemsPerMasterCarton')">
                                <i class="fas fa-check"></i>
                            </span>
                            <p class="help is-danger" *ngIf="isInvalid('itemsPerMasterCarton')" class="help is-danger">
                                Quantity per MC is required
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="column is-full" [formGroupName]="'tiers'"
        *ngFor="let tier of form.get('tiers')['controls']; let i = index">
        <div class="field">
            <label class="">Tier {{i+1}}
                <button class="button is-danger is-small is-rounded" (click)="removeTier(i)">
                    <span class="icon">
                        <i class="fas fa-times-circle"></i>
                    </span>
                </button>
            </label>
        </div>
        <div class="column is-full">
            <div class="field is-horizontal">
                
                <div class="field-body" [formGroupName]="i">
                    <div class="field  has-addons is-horizontal">
                        <div class="control  has-icons-left">
                            <div class="select">
                                <select placeholder="Package Type" formControlName="packageType">
                                    <option *ngFor="let type of ['Box', 'Master Carton']" [ngValue]="type">{{type}}</option>
                                </select>
                            </div>
                            <span class="icon is-small is-left icon-red" *ngIf="arrayItemHasError(i, 'tiers', 'packageType', ['required'])">
                                <i class="fas fa-exclamation-triangle"></i>
                            </span>
                            <span class="icon is-small is-left icon-green" *ngIf="!arrayItemHasError(i, 'tiers', 'packageType', ['required'])">
                                <i class="fas fa-check"></i>
                            </span>
                            <p class="help is-danger" *ngIf="arrayItemHasError(i, 'tiers', 'packageType', ['required'])" class="help is-danger">Package type
                                is required
                            </p>
                        </div>
                    </div>
                    <div class="field has-addons">
                        <p class="control">
                            <a class="button is-static">
                               Min
                            </a>
                        </p>
                        <div class="control has-icons-right">
                            <input class="input" type="number" placeholder="Min" formControlName="minQuantity" >

                            <span class="icon is-small is-right icon-red" *ngIf="arrayItemHasError(i, 'tiers', 'minQuantity', ['required', 'min', 'quantity'])">
                                <i class="fas fa-exclamation-triangle"></i>
                            </span>
                            <span class="icon is-small is-right icon-green" *ngIf="!arrayItemHasError(i, 'tiers', 'minQuantity', ['required', 'min', 'quantity'])">
                                <i class="fas fa-check"></i>
                            </span>

                            <p *ngIf="arrayItemHasError(i, 'tiers', 'minQuantity', ['required'])" class="help is-danger">
                                Min quantity  is required
                            </p>
                                                        
                            <p *ngIf="arrayItemHasError(i, 'tiers', 'minQuantity', ['quantity'])" class="help is-danger">
                                Tier min. quantity should be multiple of items for the selected package type
                            </p>
                        </div>
                    </div>
                    <div class="field  has-addons is-horizontal">
                        <p class="control">
                            <a class="button is-static">
                               Max
                            </a>
                        </p>
                        <div class="control  has-icons-right">
                            <input class="input" type="number" size="5" placeholder="Max" formControlName="maxQuantity">
                            <span class="icon is-small is-right icon-red" *ngIf="arrayItemHasError(i, 'tiers', 'maxQuantity', ['required', 'min', 'quantity'])">
                                <i class="fas fa-exclamation-triangle"></i>
                            </span>
                            <span class="icon is-small is-right icon-green" *ngIf="!arrayItemHasError(i, 'tiers', 'maxQuantity', ['required', 'min', 'quantity'])">
                                <i class="fas fa-check"></i>
                            </span>
                            <p class="help is-danger" *ngIf="arrayItemHasError(i, 'tiers', 'maxQuantity', ['required'])" class="help is-danger">Max quantity
                                is required
                            </p>

                            <p *ngIf="arrayItemHasError(i, 'tiers', 'maxQuantity', ['quantity'])" class="help is-danger">
                               Tier max. quantity should be multiple of items for the selected package type
                            </p>
                        </div>
                    </div>
                    <div class="field  has-addons is-horizontal">
                        <p class="control">
                            <a class="button is-static">
                               Bas Cost $
                            </a>
                        </p>
                        <div class="control has-icons-right">
                            <input class="input" type="number" size="5" placeholder="Base Cost"
                                formControlName="baseCost">
                            <span class="icon is-small is-right icon-red" *ngIf="arrayItemHasError(i, 'tiers', 'baseCost', ['required', 'min'])">
                                <i class="fas fa-exclamation-triangle"></i>
                            </span>
                            <span class="icon is-small is-right icon-green" *ngIf="!arrayItemHasError(i, 'tiers', 'baseCost', ['required', 'min'])">
                                <i class="fas fa-check"></i>
                            </span>
                            <p class="help is-danger" *ngIf="arrayItemHasError(i, 'tiers', 'baseCost', ['required', 'min'])" class="help is-danger">Base Cost is
                                invalid
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="column is-full">
        <button class="button is-success" (click)="addTier()">
            <span class="icon">
                <i class="fas fa-plus"></i>
            </span>
            <span>Add Tier</span>
        </button>
    </div>
    <div class="column is-full">
        <div class="field">
            <label class="label">Product title(Ordering form header)</label>
            <div class="control has-icons-right">
                <input class="input" type="text" placeholder="Product Title" formControlName="title">
                <span class="icon is-small is-right icon-red" *ngIf="isInvalid('title')">
                    <i class="fas fa-exclamation-triangle"></i>
                </span>
                <span class="icon is-small is-right icon-green" *ngIf="!isInvalid('title')">
                    <i class="fas fa-check"></i>
                </span>
            </div>
            <p class="help is-danger" *ngIf="isInvalid('title')" class="help is-danger">Title is required
            </p>
        </div>
    </div>
    <div class="column is-full">
        <div class="field">
            <label class="label">Image URL</label>
            <div class="control has-icons-right">
                <input class="input" type="text" placeholder="Image URL" formControlName="image">
                <span class="icon is-small is-right icon-red" *ngIf="isInvalid('image')">
                    <i class="fas fa-exclamation-triangle"></i>
                </span>
                <span class="icon is-small is-right icon-green" *ngIf="!isInvalid('image')">
                    <i class="fas fa-check"></i>
                </span>
            </div>
            <p class="help is-danger" *ngIf="isInvalid('image')" class="help is-danger">Image URL is required
            </p>
        </div>
    </div>
    <div class="column is-full">
        <div class="field">
            <label class="label">Description</label>
            <div class="control  has-icons-right">
                <textarea class="textarea"
                    style="resize: none; height: 250px !important;max-height: 100%;min-height: 100%;"
                    placeholder="Product description" formControlName="text"></textarea>
                <span class="icon is-small is-right icon-red" *ngIf="isInvalid('text')">
                    <i class="fas fa-exclamation-triangle"></i>
                </span>
                <span class="icon is-small is-right icon-green" *ngIf="!isInvalid('text')">
                    <i class="fas fa-check"></i>
                </span>
            </div>
            <p class="help is-danger" *ngIf="isInvalid('text')" class="help is-danger">Product description is required
            </p>
        </div>


        <div class="field">
            <label class="label">Short description(for Dan Kline's form)</label>
            <div class="control has-icons-right">
                <input class="input" type="text" placeholder="Short product description" formControlName="shortText">
                <span class="icon is-small is-right icon-red" *ngIf="isInvalid('shortText')">
                    <i class="fas fa-exclamation-triangle"></i>
                </span>
                <span class="icon is-small is-right icon-green" *ngIf="!isInvalid('shortText')">
                    <i class="fas fa-check"></i>
                </span>
            </div>
            <p class="help is-danger" *ngIf="isInvalid('shortText')" class="help is-danger">Short Product Description is
                required
            </p>
        </div>
    </div>
    <div class="column is-full">
        <button class="button is-success" [disabled]="form.invalid || form.disabled" (click)="onSave()">
            <span class="icon">
                <i class="fas fa-save"></i>
            </span>
            <span>Save</span>
        </button>
    </div>
</div>