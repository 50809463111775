import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import exportFromJSON from 'export-from-json'
import { SpinnerVisibilityService } from 'ng-http-loader';

import { Order } from 'src/app/models/order.model';
import { OrdersService } from '../../../services/orders.service';
import { RepsService } from 'src/app/services/reps.service';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent implements OnInit {
  filterByVal = '';
  monthFilter = 0;
  filtered = false;
  repsList = null;

  public originalOrdersList = null;
  public order = null;
  public ordersList;
  original: any;
  repsEmails: any[] = [];


  constructor(
    private router: Router,
    private spinner: SpinnerVisibilityService,
    private activatedRoute: ActivatedRoute,
    private orderService: OrdersService,
    private _repService: RepsService) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(async (params) => {
      console.log(params);
      const email = params['email'] || null;
      try {
        this.getOrdersList();

        this.spinner.hide();
      }
      catch (err) {
        console.log(err);
        this.spinner.hide();
        this.router.navigate(['']);
      }
    })
  }

  export() {
    var ctx = this;
    exportFromJSON({
      data: this.getExportData(),
      fileName: 'DSG_Orders',
      exportType: exportFromJSON.types.csv
    })
  }

  private getExportData() {
    let items = [];
    this.ordersList.map(o => {
      o.orderProducts.forEach(p => {
        items.push({
          orderId: o.id,
          paid: o.addPaymentToInvoice ? 'NO' : 'YES',
          orderDate: moment(o.date).locale('en').format('MM/DD/YYYY'),
          customer: `${o.customer.billingAddress.firstName} ${o.customer.billingAddress.lastName}`,
          repName: this.getRepName(o),
          repEmail: o.repEmail,
          product: p.name,
          sellingPrice: p.sellingPrice,
          baseCost: p.baseCost,
          sellingQuantity: p.sellingQuantity,
          orderBaseCost: o.orderProducts.length === 1 
                            ? o.orderBaseCost
                            : p.baseCost * p.sellingQuantity,
          repCommission: o.orderProducts.length === 1 
                          ? (o.billShipmentTo == 'customer' ? o.orderCommission : o.orderCommission - o.shipmentRate?.shippingAmount?.amount)
                          : Math.max(p.sellingPriceExtension - (p.baseCost * p.sellingQuantity), 0),
          orderExtention: o.orderExtension,
          shippingTotal: o.shippingTotal,
          trackingDetails: o.trackingDetails?.trackingURL,
          wmsId: o.fullfilmentData?.wmsId,
          wmsOrderId: o.fullfilmentData?.wmsOrderId,
          paymentDetails: o.payment
        });
      });
    });
    return items;
  }

  async getOrdersList() {
    const ordersList = await this.orderService.getOrders(this.monthFilter);
    this.original = [...ordersList.sort((a, b) => b.date - a.date).filter(o => o.customer?.customerId || o.customer?.id)]; //To get rid of old orders
    this.ordersList = [...this.original];
    this.repsEmails = this.ordersList.map(o => o.repEmail);
    const reps = await this._repService.getReps();
    this.repsList = reps.filter(r => this.repsEmails.includes(r.email));
  }

  getRepName(order) {
    const rep = this.repsList?.find(r => r.email == order.repEmail);
    return `${rep?.firstName} ${rep?.lastName}`;
  }

  getTotalTestsCount(order) {
    let total = 0;
    //Old schema support :(
    if (!order.billShipmentTo) return order.orderProducts[0].sellingQuantity * 25;
    order.orderProducts.forEach(i => {
      total += i.sellingQuantity;
    });
    return total;
  }

  getCommission(order) {
    return order.billShipmentTo ?
      (order.billShipmentTo == 'customer' ? order.orderCommission : order.orderCommission - order.shipmentRate?.shippingAmount?.amount) :
      //Old schema support :(
      ((order.orderProducts[0].quantity * order.orderProducts[0].sellingQuantity * order.orderProducts[0].sellingPrice) - (order.orderProducts[0].quantity * order.orderProducts[0].sellingQuantity * order.orderProducts[0].cost))
  }

  getOrderExtention(order) {
    return order.billShipmentTo
      ? order.orderExtension
      //Old schema support :(
      : (order.orderProducts[0].quantity * order.orderProducts[0].sellingQuantity * order.orderProducts[0].sellingPrice);
  }

  getOrderLineTotal(customer, orderpedProduct) {
    return customer?.id
      ? (orderpedProduct.sellingPriceExtension)
      //Old schema support :(
      : (orderpedProduct.sellingQuantity * orderpedProduct.sellingPrice * orderpedProduct.quantity);
  }

  getFormatedOrderTotal(order) {
    let totalOrder = 0;
    let subTotalOrder = 0;
    let shippingSubTotal = 0;
    order.orderProducts.forEach(item => {
      totalOrder += (item.sellingQuantity * item.sellingPrice);
      subTotalOrder += (item.sellingQuantity * item.sellingPrice);
    });
    shippingSubTotal = order.shipmentRate.shippingAmount.amount;
    totalOrder += shippingSubTotal;
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    });
    return formatter.format(order.billShipmentTo == 'customer' ? totalOrder : order.orderExtension)
  }

  openOrder(selectedOrder) {
    this.order = selectedOrder;
  }

  closeOrder() {
    this.order = null;
  }

  filterOrders() {
    this.ordersList = this.original.filter(order => order.repEmail === this.filterByVal)
    this.filtered = true;
  }

  clearFilter() {
    this.ordersList = Object.assign([], this.original);
    this.filterByVal = '';
    this.filtered = false;
  }

}
