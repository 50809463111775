<div class="container is-fluid">
  <div class="columns">
  <div class="column">
    <button class="button is-success"(click)="addNewCustomer()" >+ ADD</button>
  </div>
  </div>
  <div class="columns">
    <div class="column is-full">
      <ngx-datatable style="height: 80vh; " #myTable class="material expandable" [columnMode]="ColumnMode.force"
        [headerHeight]="50" [footerHeight]="50" [rowHeight]="50" [scrollbarV]="true" [rows]="customers"
        (page)="onPage($event)">
        <!-- Row Detail Template -->
        <ngx-datatable-row-detail [rowHeight]="400" #myDetailRow (toggle)="onDetailToggle($event)">
          <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
            <div style="padding-left:35px;">
              <div><strong>Address</strong></div>
              <div>{{row.shippingAddress.zip}} {{ row.shippingAddress.city }}, {{ row.shippingAddress.state }}</div>
              <div>{{ row.shippingAddress.address }}</div>
              <div>{{ row.shippingAddress.email }}</div>
            </div>
            <!--<div style="padding-left:35px;">
              <div><strong>Order Details</strong></div>
              <div *ngFor="let item of row.orderProducts">
                <div>{{item.name}}, Price:{{item.cost}} Quantity:{{item.quantity}}</div>
              </div>
            </div>-->
          </ng-template>
        </ngx-datatable-row-detail>
        <!-- Column Templates -->
        <ngx-datatable-column [width]="50" [resizeable]="false" [sortable]="false" [draggable]="false"
          [canAutoResize]="false">
          <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
            <a href="javascript:void(0)" [class.datatable-icon-right]="!expanded" [class.datatable-icon-down]="expanded"
              title="Expand/Collapse Row" (click)="toggleExpandRow(row)">
            </a>
          </ng-template>
        </ngx-datatable-column>
        <!-- <ngx-datatable-column name="ID" [width]="200">
          <ng-template let-id="row.id" ngx-datatable-cell-template>
            {{ id }}
          </ng-template>
        </ngx-datatable-column> -->
        <ngx-datatable-column name="Customer Name" [width]="80">
          <ng-template let-first="row.billingAddress.firstName" let-last="row.billingAddress.lastName" ngx-datatable-cell-template>
            {{ first + ' ' + last }}
          </ng-template>
        </ngx-datatable-column>
        <!-- <ngx-datatable-column name="Last" [width]="120">
          <ng-template let-last="row.contactInfo.lastName" ngx-datatable-cell-template>
            {{ last }}
          </ng-template>
        </ngx-datatable-column> -->
        <ngx-datatable-column name="Company Name" [width]="120">
          <ng-template let-legal="row.legalName" ngx-datatable-cell-template>
            {{ legal }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="DBA" [width]="120">
          <ng-template let-dba="row.dba" ngx-datatable-cell-template>
            {{ dba }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Type" [width]="120">
          <ng-template let-customerType="row.customerType" ngx-datatable-cell-template>
            {{ customerType }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="CLIA" [width]="120">
          <ng-template let-clia="row.clia" ngx-datatable-cell-template>
            {{ clia }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Pending CLIA?" [width]="85">
          <ng-template let-isCliaPending="row.isCliaPending" ngx-datatable-cell-template>
            <span [class.clia-pending]="isCliaPending" [class.clia-not-pending]="!isCliaPending">
              {{ isCliaPending ? 'YES' : 'NO' }}
            </span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column *ngIf="isAdmin" name="Active" [width]="80">
          <ng-template let-active="row.active" ngx-datatable-cell-template >
            {{ active ? 'YES' : 'NO' }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Edit" >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <a class="button  is-link is-outlined is-success is-small is-fullwidth"  (click)="goToEdit(row)" >EDIT</a>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Delete" >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <a class="button  is-link is-outlined is-danger is-small is-fullwidth" (click)="delete(row)">DELETE</a>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>

  </div>
</div>