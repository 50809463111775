import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, NavigationEnd, NavigationStart, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { SpinnerVisibilityService } from 'ng-http-loader';

import * as moment from 'moment';
import { OrdersService } from '../../../services/orders.service';
import { ProductsService } from '../../../services/products.service';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { CognitoUser } from '@aws-amplify/auth';
import { UsersService } from '../../../services/users.service';

import { CustomersService } from '../../../services/customers.service';
import { BehaviorSubject, combineLatest, EMPTY, from, interval, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, delay, filter, isEmpty, map, retry, startWith, take, takeUntil, tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { ShippingService } from '../../../services/shipping.service';

import { NgSelectComponent } from '@ng-select/ng-select';
import { BaseFormComponent } from '../../shared/base-form/base-form.component';
import { Customer } from '../../../models/customer.model';
import emailMask from 'text-mask-addons/dist/emailMask';
import { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'ngx-useful-swiper';
import { environment } from 'src/environments/environment';
import { API } from 'aws-amplify';
import { basename } from 'path';
import { PaymentsService } from 'src/app/services/payments.service';
import { ArchwizardModule, WizardComponent } from 'angular-archwizard';
import { CustomerShippingDetailsComponent } from '../customer-shipping-details/customer-shipping-details.component';
import { OrderEntry, OrderEntry as OrderEntryViewModel } from 'src/app/models/view/order-entry-viewmodel';




@Component({
  selector: 'app-rep-order-wizard',
  templateUrl: './rep-order-wizard.component.html',
  styleUrls: ['./rep-order-wizard.component.scss']
})
export class RepOrderWizardComponent extends BaseFormComponent implements OnInit {
  mask = emailMask;
  

  @ViewChild('wizard') wizardComponent: WizardComponent;
  @ViewChild('shipDetails') shipDetailsComponent: CustomerShippingDetailsComponent;

  loading = false;
  user: CognitoUser;
  userId: string;
  isAdmin: boolean;
  customers$: Observable<any[]>;
  order: any;
  updateShipping: boolean;

  lastURL: string;
  rates$: Observable<any>;
  destroy$ = new Subject<void>();
  resetPaymentMethod$ = new Subject<void>();


  form = new FormGroup({
    id: new FormControl(''),
    addPaymentToInvoice: new FormControl(false),
  });
  card: any;
  paymentResults: any;
  showPaymentResult: boolean;
  customerId$ = new BehaviorSubject<string>(null);

  orderEntry: OrderEntryViewModel;
  shipDetails: any;
  customer$$ = new Subject<Customer>();
  orderId$$ = new Subject<string>();
  orderEntry$$ = new Subject<OrderEntry>();


  constructor(
    private productService: ProductsService,
    protected ordersService: OrdersService,
    private spinner: SpinnerVisibilityService,
    private customersService: CustomersService,
    private userService: UsersService,
    private shippingService: ShippingService,
    private paymentsService: PaymentsService,
    private router: Router) {
    super(ordersService);
  }

  ngOnInit() {

  }

  async customerChanged(c) {
    if (!c) {
      return;
    }

    this.customer$$.next(c);
    this.form.patchValue({
      id: this.ordersService.newGuid()
    });
    this.form.updateValueAndValidity();
  }

  onOrderReady(orderEntryData){
    this.orderEntry = orderEntryData;
    this.orderEntry$$.next(orderEntryData);
    this.wizardComponent.goToNextStep();
    //this.shipDetailsComponent.getRates();
  }

  onShipDetailsReady(shipDetails) {
    this.shipDetails = shipDetails;
  }


  ngOnDestroy() {
    this.destroy$.next();
    this.finish();
  }

  async finish() {
    this.form.reset({addPaymentToInvoice: false});
    //this.customersSelectComponent.handleClearClick();
    this.order = null;
    this.paymentResults = null;
    this.showPaymentResult = false;
  }

  goToPayment(shipDetails) {
    this.shipDetails = shipDetails;
    this.wizardComponent.goToStep(this.lessThan15000() ? 2 : 3);
    //this.orderId$$.next(this.form.get('id').value); 
  }

  async createOrder() {
    this.spinner.show();
    //await this.updateCustomerIfShippingAddressChanged();
    const form = this.form.getRawValue();
    this.order = {
      id: form.id,
      date: parseInt(moment().format('x')),
      shippingTotal: this.shipDetails.shipmentRate.shippingAmount.amount,
      shipping: this.shipDetails.shippingAddress,
      customer: {
        id: this.shipDetails.id,
        billingAddress: this.shipDetails.billingAddress
      },
      orderProducts: this.orderEntry.orderProducts,
      shipmentType: this.shipDetails.shipmentType,
      shipmentRate: this.shipDetails.shipmentRate ?? {shippingAmount: {amount: 0}},
      repEmail: await this.userService.getCurrentUserEmail(),
      repId: (await this.userService.getCurrentUser()).getUsername(),
      billShipmentTo: this.shipDetails.billShipmentTo,
      addPaymentToInvoice: form.addPaymentToInvoice,
      orderExtension: this.orderEntry.orderExtension,
      orderCommission: this.orderEntry.orderCommission,
      orderBaseCost: this.orderEntry.orderBaseCost,
      payment: this.paymentResults,
      reference: this.orderEntry.reference
    };

    try {
      this.order = await this.ordersService.processOrder(this.order);
      await this.ordersService.sendEmailOrder(this.order);
      setTimeout(() => {
        this.spinner.hide();
      }, 5000);
    }
    catch (err) {
      console.log(err);
      this.spinner.hide();
    }
  }

  calculateOrderAmount() {
    const form = this.form.value;
    const shipCost = this.shipDetails?.shipmentRate?.shippingAmount?.amount;
    return this.orderEntry?.orderExtension + ( this.shipDetails?.billShipmentTo == 'customer' ? shipCost : 0);
  }

  lessThan15000() {
    const form = this.form.getRawValue();
    return  this.shipDetails?.billShipmentTo == 'customer'
      ? (this.orderEntry?.orderExtension +  this.shipDetails?.shipmentRate?.shippingAmount?.amount <= 15000) 
      : this.orderEntry?.orderExtension <= 15000;
  }

  isDisabled() {
    return this.form.value.addPaymentToInvoice ? false : !this.paymentResults?.success;
  }

  async paymentCompletedHandler(paymentResult) {
    this.paymentResults = paymentResult;
    if(this.paymentResults?.result.messages.resultCode == 'Ok' 
        && this.paymentResults?.result.transactionResponse?.responseCode == 1
        && !this.paymentResults?.result.transactionResponse?.errors) {
      await this.createOrder();
      this.resetPaymentMethod$.next();
      this.wizardComponent.goToStep(4);
    }
  }
}
