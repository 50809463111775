import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { Subject } from 'rxjs';
import { tap, takeUntil } from 'rxjs/operators';
import { PaymentsService } from 'src/app/services/payments.service';
import { BasePaymentComponent } from '../base-payment-methods/base-payment.component';

@Component({
  selector: 'app-payment-form',
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.scss']
})
export class PaymentFormComponent extends BasePaymentComponent implements OnInit {
  @Input() amount: 0;
  @Input() orderId$ = new Subject<string>();
  @Input() showCustomerPaymentMethods = true;
  @Input() allowUpdateDefaultPaymentMethod = true;
  @Input() formType = null;
  @Output() onPaymentCompleted = new EventEmitter<any>();

  paymentResult: any = null;
  paymentMethod = null;
  showErrorsDetails = false;
  resetExistingPaymentMethod$ = new Subject<void>();
  orderId: string;

  constructor(private paymentsService: PaymentsService, protected spinner: SpinnerVisibilityService) {
    super(spinner);
   }

  ngOnInit(): void {
    super.ngOnInit();
    this.orderId$.pipe(tap(id => this.orderId = id), takeUntil(this.destroy$)).subscribe(); 
    //this.reset$.pipe(tap(() => {
    //    this.paymentMethod = null;
    //    this.paymentResult = null;
    //    this.billingAddress = null;
    //    this.showErrorsDetails = false;
    //}), takeUntil(this.destroy$)).subscribe();     

  }

  hasValidPaymentMethod() {
    if(!this.paymentMethod) return false;

    const isValidMethod = !!(this.paymentMethod?.valid || this.paymentMethod?.details.id);
    return (this.paymentMethod?.details?.type == 'card' || this.paymentMethod?.type == 'card')
    ? (this.isValidCardCode(this.paymentMethod.secret ?? this.paymentMethod.details.method.cardCode) && isValidMethod) 
    : isValidMethod;
  }

  toggleErrorsDetails() {
    this.showErrorsDetails = !this.showErrorsDetails;
  }

  isValidCardCode(cardCode) {
    return cardCode?.length >= 3;
  }

  onPaymentMethodDetailsChanged(paymentMethod) {
    if(!paymentMethod) return;
    this.paymentMethod = paymentMethod;
    if(this.paymentMethod?.details.id) {
      this.reset$.next();
    } else {
      this.resetExistingPaymentMethod$.next();
    }
  }

  async pay(details) {
    this.paymentResult = null;
    
    //For ACH testing
    //this.amount = !environment.production ? 10 : this.amount;
    
    this.spinner.show();
    this.paymentResult = this.paymentMethod?.details.id 
        ? await this.paymentsService.payWithCardOnFile(this.paymentMethod.details.id, this.paymentMethod.secret, this.amount)
        : await this.paymentsService.pay(this.orderId, this.paymentMethod.details, this.amount);

    if(!this.paymentResult?.unknown) this.onPaymentCompleted.emit(this.paymentResult);
    this.spinner.hide();
  }
}
