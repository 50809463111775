<div class="is-title-bar" style="height: 50px;">
  <div class="columns">
    <aside class="menu column is-2">
      <p class="menu-label">
        Active products
      </p>
      <ul class="menu-list">
        <li *ngFor="let p of activeProducts" (click)="selectProduct(p)">
          <a [ngClass]="{'is-active': currentProduct?.id === p.id}">
            {{p.name}}
            <span class="icon is-small is-right icon-red is-right" (click)="toggle(p)">
              <i class="fas fa-times-circle"></i>
            </span>
          </a>
        </li>
        <li *ngIf="!activeProducts || activeProducts?.length == 0">No active products</li>
      </ul>
      <button class="button is-info m-3" (click)="selectProduct()">Add new</button>

      <p class="menu-label">
        Inactive products
      </p>
      <ul class="menu-list">
        <li *ngFor="let p of inactiveProducts" (click)="selectProduct(p)">
          <a [ngClass]="{'is-active': currentProduct?.id === p.id}">
            {{p.name}}
            <span class="icon is-right icon-red is-right" (click)="toggle(p)">
              <i class="fas fa-check"></i>
            </span>
          </a>

        </li>
        <li *ngIf="!inactiveProducts || inactiveProducts?.length == 0">No inactive products</li>
      </ul>

    </aside>

    <div class="column">
      <app-product-editor *ngIf="currentProduct && currentProduct.active; else activeProductMessage"
        [product]="currentProduct" (onUpdate)="onProductUpdated($event)"></app-product-editor>
      <ng-template #activeProductMessage>
        <div [ngSwitch]="currentProduct?.active">
          <div *ngSwitchCase="false">Please activate a product first</div>
          <div *ngSwitchDefault>Please pick a product first</div>
        </div>

      </ng-template>
    </div>
  </div>