import { Component, OnInit } from '@angular/core';
import { a, Auth } from 'aws-amplify';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LogRocketService } from '../../../services/logrocket.service';
import { UsersService } from 'src/app/services/users.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public loading = false;
  public userName: string;
  public password: string;
  public showNewPassword = false;
  public showForgotPassword = false;
  public showSMSMFA = false;
  public newPassword: string;
  public newPasswordConfirm: string;
  public phoneNumber: string;
  public validationCode: string;
  showPassword = true;
  public showNextForgotPassword = false;
  public forgotMsgDetails: any;

  private userData: any;
  showResendCode: boolean;

  constructor(private router: Router, private usersService: UsersService, private logRocket: LogRocketService, private _toastr: ToastrService,) { }

  async ngOnInit() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      this.router.navigate(['./']);
    }
    catch (err) {
      console.log(err);
    }
  }

  async loginWithCognito() {
    try {
      this.userData = await Auth.signIn(this.userName.toString(), this.password.toString());
      console.log(this.userData);
      this.validateCognito();
      this.loading = false;
    } catch (error) {
      console.log(error);
      this.loading = false;
      this._toastr.error('User Authentication failed');
    }
  }

  async updatePassword() {
    try {
      const response = await Auth.completeNewPassword(this.userData, this.newPassword);
      this.validateCognito();
    }
    catch (error) {
      console.log(error);
    }
  }

  async validateSMS() {
    try {
      this.userData = await Auth.confirmSignIn(this.userData, this.validationCode, this.userData.challengeName);
      this.validateCognito();
    }
    catch(error) {
      console.log(error);
    }
  }

  async forgotPasswordSendEmail() {
    try {
      this.forgotMsgDetails = await Auth.forgotPassword(this.userName);
      this.validationCode = '';
      this.newPassword = '';
      this.showNextForgotPassword = true;
    }
    catch(err) {
      this._toastr.error('There\'s no user with that email! Please check and try again!');
      console.log(err);
    }
  }

  async forgotPassword() {
    try {
      this.showResendCode = false;
      const test = await Auth.forgotPasswordSubmit(this.userName, this.validationCode, this.newPassword);
      
      this._toastr.success('Password updated successfully!');
      this.showForgotPassword = false;
      this.validationCode = '';
    }
    catch(err) {
      this.validationCode = '';
      this.showResendCode = true;
      this._toastr.error(err?.message ?? 'There was an error updating your password! Please contact your administrator!');
      console.log(err);
    }
  }

  async validateCognito() {
    const tokens = this.userData.signInUserSession;
    if (tokens !== null) {
      console.log('User authenticated');
      await this.logRocket.identifyUser();
      this.router.navigate(['']);
    } else {
      switch(this.userData.challengeName) {
        case 'NEW_PASSWORD_REQUIRED':
          this.showNewPassword = true;
          break;
        case 'SMS_MFA':
          this.phoneNumber = this.userData.challengeParam.CODE_DELIVERY_DESTINATION
          this.showSMSMFA = true;
          break;
      }
    }
  } 

}
