<div class="container is-fluid">
  <div class="columns">
    <div class="column is-full">
      <div class="row divider">PRODUCTS INFORMATION</div>
      <div class="columns is-centered">
        <div class="column is-4-fullhd is-4-desktop is-6-tablet has-text-weight-bold" *ngFor="let product of products">
          <div class="card">
            <div class="card-content">
              <p class="subtitle is-4">{{product.name}}</p>
              <table class="table" *ngFor="let tier of product.tiers; let idx = index">
                <tr>
                  <th rowspan="4" class="has-background-info has-text-white">
                    <div class="tier">
                      <p>Tier {{idx+1}}</p>
                    </div>
                  </th>
                  <th>Tests</th>
                  <td>{{tier.minQuantity|number}} - {{tier.maxQuantity|number}}</td>
                </tr>
                <tr>
                  <th>Boxes</th>
                  <td>{{getPackageRange(product.itemsPerBox, tier.minQuantity, tier.maxQuantity) }}</td>
                </tr>
                <tr>
                  <th>Master Cartons</th>
                  <td>{{getPackageRange(product.itemsPerMasterCarton, tier.minQuantity, tier.maxQuantity) }}</td>
                </tr>
                <tr>
                  <th>Base cost</th>
                  <td>{{tier.baseCost|currency}}</td>
                </tr>
              </table>
            </div>
          </div>
          <!-- <table class="table  is-bordered is-narrow is-hoverable is-fullwidth">
            <thead>
              <tr>
                <th colspan="6" style="text-align: center;">{{product.name}} <br/> {{product.text}}</th>
              </tr>
              <tr>
                <th></th>
                <th>Tests</th>
                <th>Boxes</th>
                <th>Master Cartons</th>
                <th>Base cost</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let tier of product.tiers; let idx = index">
                <td>Tier {{idx+1}}</td>
                <td>{{tier.minQuantity|number}} - {{tier.maxQuantity|number}}</td>
                <td>{{getPackageRange(product.itemsPerBox, tier.minQuantity, tier.maxQuantity) }}</td>

                <td>{{getPackageRange(product.itemsPerMasterCarton, tier.minQuantity, tier.maxQuantity) }}</td>
                <td>{{tier.baseCost|currency}}</td>
              </tr>
            </tbody>
          </table> -->
        </div>
      </div>
    </div>
  </div>
  <!--<div class="columns is-centered">
    <div class="column">
      <div class="row divider">FDA LINKS</div>
      <table>
        <thead>
          <tr>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td width="200">EUA:</td>
            <td> <a href="https://www.fda.gov/media/145694/download"
                target="_blank">https://www.fda.gov/media/145694/download</a></td>

          </tr>
          <tr>
            <td width="200">HCP Fact Sheet:</td>
            <td><a href="https://www.fda.gov/media/145695/download"
                target="_blank">https://www.fda.gov/media/145695/download</a>
            </td>

          </tr>
          <tr>
            <td width="200">Patient Fact Sheet:</td>
            <td> <a href="https://www.fda.gov/media/145696/download"
                target="_blank">https://www.fda.gov/media/145696/download</a></td>

          </tr>
          <tr>
            <td width="200">Instructions For Use (IFU):</td>
            <td> <a href="https://www.fda.gov/media/145697/download"
                target="_blank">https://www.fda.gov/media/145697/download</a></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="column">
      <div class="row divider">ADDITIONAL HELPFUL LINKS</div>
      <table>
        <thead>
          <tr>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td> <a
                href="https://www.lifesignmed.com/sites/default/files/Status_Covid_flu_updated_expiration_letter.pdf"
                target="_blank">Expiration Dating Memo</a></td>

          </tr>
          <tr>
            <td><a
                href="https://www.lifesignmed.com/sites/default/files/SellSheet_80645_Lifesign_Status%20Flu%20Covid.1.6.9.pdf"
                target="_blank">LifeSign Sell Sheet</a>
            </td>

          </tr>
          <tr>
            <td><a
                href="https://www.lifesignmed.com/sites/default/files/video-thumbnails/2021-02/StatusCOVID-19_FluA%26B_720.mp4"
                target="_blank">How to administer video</a></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="column">
      <div class="is-text-centered">
        <figure class="image" style="max-width:380px">
          <img class="img" style=" margin-left: auto;  margin-right: auto;" src="assets/presentation.png" />
        </figure>
      </div>

    </div>
  </div>-->
</div>