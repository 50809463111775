<div class="box">
    <div class="columns" [formGroup]="form">
        <div class="column">
            <div class="field">
                <div class="control has-icons-right">
                    <input class="input" type="text" autofocus placeholder="Company Name" formControlName="legalName">
                    <span class="icon is-small is-right icon-red" *ngIf="isInvalid('legalName')">
                        <i class="fas fa-exclamation-triangle"></i>
                    </span>
                    <span class="icon is-small is-right icon-green" *ngIf="!isInvalid('legalName')">
                        <i class="fas fa-check"></i>
                    </span>
                </div>
                <p class="help is-danger" *ngIf="isInvalid('legalName')" class="help is-danger">Company name is required
                </p>
            </div>
            <div class="field">
                <div class="control has-icons-right">
                    <input class="input" type="text" autofocus placeholder="DBA, if any" formControlName="dba">
                </div>
                <div class="divider">BILLING INFORMATION</div>
                <div [formGroupName]="'billingAddress'">
                    <div class="field is-horizontal">
                        <div class="field-body">
                            <div class="field">
                                <div class="control has-icons-right">
                                    <input class="input" type="text" placeholder="First name"
                                        formControlName="firstName">
                                    <span class="icon is-small is-right icon-red"
                                        *ngIf="isInvalid('billingAddress.firstName')">
                                        <i class="fas fa-exclamation-triangle"></i>
                                    </span>
                                    <span class="icon is-small is-right icon-green"
                                        *ngIf="!isInvalid('billingAddress.firstName')">
                                        <i class="fas fa-check"></i>
                                    </span>
                                </div>
                                <p class="help is-danger" *ngIf="isInvalid('billingAddress.firstName')"
                                    class="help is-danger">
                                    First name is required</p>
                            </div>
                            <div class="field">
                                <div class="control has-icons-right">
                                    <input class="input" type="text" placeholder="Last name" formControlName="lastName">
                                    <span class="icon is-small is-right icon-red"
                                        *ngIf="isInvalid('billingAddress.lastName')">
                                        <i class="fas fa-exclamation-triangle"></i>
                                    </span>
                                    <span class="icon is-small is-right icon-green"
                                        *ngIf="!isInvalid('billingAddress.lastName')">
                                        <i class="fas fa-check"></i>
                                    </span>
                                </div>
                                <p class="help is-danger" *ngIf="isInvalid('billingAddress.lastName')"
                                    class="help is-danger">
                                    Last name is required</p>
                            </div>
                        </div>
                    </div>
                    <div class="field">
                        <div class="control has-icons-right">
                            <input class="input" type="text" placeholder="Title" formControlName="title">
                            <span class="icon is-small is-right icon-red" *ngIf="isInvalid('billingAddress.title')">
                                <i class="fas fa-exclamation-triangle"></i>
                            </span>
                            <span class="icon is-small is-right icon-green" *ngIf="!isInvalid('billingAddress.title')">
                                <i class="fas fa-check"></i>
                            </span>
                        </div>
                        <p class="help is-danger" *ngIf="isInvalid('billingAddress.title')" class="help is-danger">Title
                            is
                            required
                        </p>
                    </div>
                    <div class="field">
                        <div class="control has-icons-right">
                            <input class="input" type="text" placeholder="Address" formControlName="address">
                            <span class="icon is-small is-right icon-red" *ngIf="isInvalid('billingAddress.address')">
                                <i class="fas fa-exclamation-triangle"></i>
                            </span>
                            <span class="icon is-small is-right icon-green"
                                *ngIf="!isInvalid('billingAddress.address')">
                                <i class="fas fa-check"></i>
                            </span>
                        </div>
                        <p class="help is-danger" *ngIf="isInvalid('billingAddress.address')" class="help is-danger">
                            Adress
                            is required</p>
                    </div>
                    <div class="field is-horizontal">
                        <div class="field-body">
                            <div class="field" *ngIf="form.value.billingAddress.zip.length >= 5">
                                <div class="control has-icons-right">
                                    <input class="input" type="text" readonly placeholder="City" formControlName="city">
                                    <span class="icon is-small is-right icon-red"
                                        *ngIf="isInvalid('billingAddress.city')">
                                        <i class="fas fa-exclamation-triangle"></i>
                                    </span>
                                    <span class="icon is-small is-right icon-green"
                                        *ngIf="!isInvalid('billingAddress.city')">
                                        <i class="fas fa-check"></i>
                                    </span>
                                </div>
                                <p class="help is-danger" *ngIf="isInvalid('billingAddress.city')"
                                    class="help is-danger">
                                    City
                                    is required</p>
                            </div>
                            <div class="field" *ngIf="form.value.billingAddress.zip.length >= 5">
                                <div class="control has-icons-right">
                                    <input class="input" type="text" readonly placeholder="State"
                                        formControlName="state">
                                    <span class="icon is-small is-right icon-red"
                                        *ngIf="isInvalid('billingAddress.state')">
                                        <i class="fas fa-exclamation-triangle"></i>
                                    </span>
                                    <span class="icon is-small is-right icon-green"
                                        *ngIf="!isInvalid('billingAddress.state')">
                                        <i class="fas fa-check"></i>
                                    </span>
                                </div>
                                <p class="help is-danger" *ngIf="isInvalid('billingAddress.state')"
                                    class="help is-danger">
                                    State is required</p>
                            </div>
                            <div class="field">
                                <div class="control has-icons-right">
                                    <input class="input" type="text" placeholder="ZIP" formControlName="zip"
                                        [mask]="'00000'"
                                        (input)="checkZipCode('billingAddress.zip', 'billingAddress.city', 'billingAddress.state')">
                                    <span class="icon is-small is-right icon-red"
                                        *ngIf="isInvalid('billingAddress.zip')">
                                        <i class="fas fa-exclamation-triangle"></i>
                                    </span>
                                    <span class="icon is-small is-right icon-green"
                                        *ngIf="!isInvalid('billingAddress.zip')">
                                        <i class="fas fa-check"></i>
                                    </span>
                                </div>
                                <p class="help is-danger" *ngIf="isInvalid('billingAddress.zip')"
                                    class="help is-danger">
                                    ZIP
                                    is required</p>
                            </div>

                        </div>

                    </div>
                    <div class="field is-horizontal">
                        <div class="field-body">
                            <div class="field">
                                <div class="control has-icons-right">
                                    <input class="input" type="text" [mask]="'000 000-0000'" placeholder="Cell"
                                        formControlName="phone">
                                    <span class="icon is-small is-right icon-red"
                                        *ngIf="isInvalid('billingAddress.phone')">
                                        <i class="fas fa-exclamation-triangle"></i>
                                    </span>
                                    <span class="icon is-small is-right icon-green"
                                        *ngIf="!isInvalid('billingAddress.phone')">
                                        <i class="fas fa-check"></i>
                                    </span>
                                </div>
                                <p class="help is-danger" *ngIf="isInvalid('billingAddress.phone')"
                                    class="help is-danger">
                                    Cell
                                    is required</p>
                            </div>
                            <div class="field">
                                <div class="control has-icons-right">
                                    <input class="input" type="text" placeholder="Email" formControlName="email"
                                        [textMask]="{mask: mask}">
                                    <span class="icon is-small is-right icon-red"
                                        *ngIf="isInvalid('billingAddress.email')">
                                        <i class="fas fa-exclamation-triangle"></i>
                                    </span>
                                    <span class="icon is-small is-right icon-green"
                                        *ngIf="!isInvalid('billingAddress.email')">
                                        <i class="fas fa-check"></i>
                                    </span>
                                </div>
                                <p class="help is-danger" *ngIf="hasError('billingAddress.email', 'required')"
                                    class="help is-danger">
                                    Email is required</p>
                                <p class="help is-danger" *ngIf="hasError('billingAddress.email', 'email')"
                                    class="help is-danger">
                                    Email is invalid</p>
                            </div>

                        </div>

                    </div>
                    <div class="field">
                        <div class="control">
                            <label class="checkbox">
                                <input type="checkbox" formControlName="shippingIsTheSame"
                                    (change)="toggleShipppingAddress()">
                                Please check this, if the Shipping Address is the same as Billing Address
                            </label>
                        </div>
                    </div>
                </div>
                <div class="divider">SHIPPING INFORMATION</div>
                <div [formGroupName]="'shippingAddress'">
                    <div class="field is-horizontal">
                        <div class="field-body">
                            <div class="field">
                                <div class="control has-icons-right">
                                    <input class="input" type="text" placeholder="First name"
                                        formControlName="firstName">
                                    <span class="icon is-small is-right icon-red"
                                        *ngIf="isInvalid('shippingAddress.firstName')">
                                        <i class="fas fa-exclamation-triangle"></i>
                                    </span>
                                    <span class="icon is-small is-right icon-green"
                                        *ngIf="!isInvalid('shippingAddress.firstName')">
                                        <i class="fas fa-check"></i>
                                    </span>
                                </div>
                                <p class="help is-danger" *ngIf="isInvalid('shippingAddress.firstName')"
                                    class="help is-danger">
                                    First name is required</p>
                            </div>
                            <div class="field">
                                <div class="control has-icons-right">
                                    <input class="input" type="text" placeholder="Last name" formControlName="lastName">
                                    <span class="icon is-small is-right icon-red"
                                        *ngIf="isInvalid('shippingAddress.lastName')">
                                        <i class="fas fa-exclamation-triangle"></i>
                                    </span>
                                    <span class="icon is-small is-right icon-green"
                                        *ngIf="!isInvalid('shippingAddress.lastName')">
                                        <i class="fas fa-check"></i>
                                    </span>
                                </div>
                                <p class="help is-danger" *ngIf="isInvalid('shippingAddress.lastName')"
                                    class="help is-danger">
                                    Last name is required</p>
                            </div>
                        </div>
                    </div>
                    <div class="field">
                        <div class="control has-icons-right">
                            <input class="input" type="text" placeholder="Address" formControlName="address">
                            <span class="icon is-small is-right icon-red" *ngIf="isInvalid('shippingAddress.address')">
                                <i class="fas fa-exclamation-triangle"></i>
                            </span>
                            <span class="icon is-small is-right icon-green"
                                *ngIf="!isInvalid('shippingAddress.address')">
                                <i class="fas fa-check"></i>
                            </span>
                        </div>
                        <p class="help is-danger" *ngIf="isInvalid('shippingAddress.address')" class="help is-danger">
                            Adress
                            is required</p>
                    </div>
                    <div class="field is-horizontal">
                        <div class="field-body">
                            <div class="field" *ngIf="form.value.shippingAddress?.zip?.length >= 5">
                                <div class="control has-icons-right">
                                    <input class="input" type="text" readonly placeholder="City" formControlName="city">
                                    <span class="icon is-small is-right icon-red"
                                        *ngIf="isInvalid('shippingAddress.city')">
                                        <i class="fas fa-exclamation-triangle"></i>
                                    </span>
                                    <span class="icon is-small is-right icon-green"
                                        *ngIf="!isInvalid('shippingAddress.city')">
                                        <i class="fas fa-check"></i>
                                    </span>
                                </div>
                                <p class="help is-danger" *ngIf="isInvalid('shippingAddress.city')"
                                    class="help is-danger">
                                    City
                                    is required</p>
                            </div>
                            <div class="field" *ngIf="form.value.shippingAddress?.zip?.length >= 5">
                                <div class="control has-icons-right">
                                    <input class="input" type="text" readonly placeholder="State"
                                        formControlName="state">
                                    <span class="icon is-small is-right icon-red"
                                        *ngIf="isInvalid('shippingAddress.state')">
                                        <i class="fas fa-exclamation-triangle"></i>
                                    </span>
                                    <span class="icon is-small is-right icon-green"
                                        *ngIf="!isInvalid('shippingAddress.state')">
                                        <i class="fas fa-check"></i>
                                    </span>
                                </div>
                                <p class="help is-danger" *ngIf="isInvalid('shippingAddress.state')"
                                    class="help is-danger">
                                    State is required</p>
                            </div>
                            <div class="field">
                                <div class="control has-icons-right">
                                    <input class="input" type="text" placeholder="ZIP" formControlName="zip"
                                        [mask]="'00000'"
                                        (keyup)="checkZipCode('shippingAddress.zip', 'shippingAddress.city', 'shippingAddress.state')">
                                    <span class="icon is-small is-right icon-red"
                                        *ngIf="isInvalid('shippingAddress.zip')">
                                        <i class="fas fa-exclamation-triangle"></i>
                                    </span>
                                    <span class="icon is-small is-right icon-green"
                                        *ngIf="!isInvalid('shippingAddress.zip')">
                                        <i class="fas fa-check"></i>
                                    </span>
                                </div>
                                <p class="help is-danger" *ngIf="isInvalid('shippingAddress.zip')"
                                    class="help is-danger">
                                    ZIP
                                    is required</p>
                            </div>

                        </div>

                    </div>
                    <div class="field">
                        <div class="control has-icons-right">
                            <input class="input" type="text" placeholder="Receiving Phone Number"
                                formControlName="phone" [mask]="'(000) 000-0000'">
                            <span class="icon is-small is-right icon-red" *ngIf="isInvalid('shippingAddress.phone')">
                                <i class="fas fa-exclamation-triangle"></i>
                            </span>
                            <span class="icon is-small is-right icon-green" *ngIf="!isInvalid('shippingAddress.phone')">
                                <i class="fas fa-check"></i>
                            </span>
                        </div>
                        <p class="help is-danger" *ngIf="isInvalid('shippingAddress.phone')" class="help is-danger">
                            Phone
                            is required</p>
                    </div>
                    <div class="field is-horizontal">
                        <div class="field-label">
                            <label class="label">Receiving Hours</label>
                        </div>
                        <div class="field-body">
                            <div class="field">
                                <div class="control has-icons-right">
                                    <label class="radio">
                                        <input type="radio" formControlName="hours" [value]="'9-5 (5)'">
                                        9-5 (5)
                                    </label>
                                    <label class="radio">
                                        <input type="radio" formControlName="hours" [value]="'24 Hours (5)'">
                                        24 Hours (5)
                                    </label>
                                    <label class="radio">
                                        <input type="radio" formControlName="hours" [value]="'24 Hours (6 - Saturday)'">
                                        24 Hours (6 - Saturday)
                                    </label>
                                    <span class="icon is-small is-right icon-red"
                                        *ngIf="isInvalid('shippingAddress.hours')">
                                        <i class="fas fa-exclamation-triangle"></i>
                                    </span>
                                    <span class="icon is-small is-right icon-green"
                                        *ngIf="!isInvalid('shippingAddress.hours')">
                                        <i class="fas fa-check"></i>
                                    </span>
                                </div>
                                <p class="help is-danger" *ngIf="isInvalid('shippingAddress.hours')"
                                    class="help is-danger">Hours
                                    are required</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="control">
                <label class="checkbox">
                    <input type="checkbox" formControlName="isCliaPending" (change)="udpateClia()">
                    Please check this, if the CLIA is pending
                </label>
            </div>
            <div class="control has-icons-right" *ngIf="!form.value.isCliaPending">
                <input class="input" type="text" autofocus maxlength="10" minlength="10"
                    placeholder="CLIA Certificate # (H, M, W)" formControlName="clia">
                <span class="icon is-small is-right icon-red" *ngIf="isInvalid('clia')">
                    <i class="fas fa-exclamation-triangle"></i>
                </span>
                <span class="icon is-small is-right icon-green" *ngIf="!isInvalid('clia')">
                    <i class="fas fa-check"></i>
                </span>

                <p class="help is-danger" *ngIf="hasError('clia', 'expiredClia')" class="help is-danger">CLIA # is
                    expired
                </p>
                <p class="help is-danger" *ngIf="hasError('clia', 'invalidClia')" class="help is-danger">CLIA # is
                    invalid
                </p>
            </div>

        </div>
        <div class="column">
            <div class="divider">CUSTOMER TYPE
                <span class="icon is-small is-right icon-red" *ngIf="isInvalid('customerType')">
                    <i class="fas fa-exclamation-triangle"></i>
                </span>
                <span class="icon is-small is-right icon-green" *ngIf="!isInvalid('customerType')">
                    <i class="fas fa-check"></i>
                </span>
            </div>
            <div class="columns">
                <div class="column" *ngFor="let start of [0, 4, 8]">
                    <div class="control" *ngFor="let accountType of accountTypes.slice(start, start+4);let i = index">
                        <label class="radio" for="type{{start+i}}">
                            <input type="radio" id="type{{start+i}}" formControlName="customerType"
                                [value]="accountType" (change)="otherType.value = '';others.checked=false">
                            {{accountType}}
                        </label>
                        <p class="help is-danger" *ngIf="hasError('customerType', 'requred')" class="help is-danger">
                            Customer Type is invalid</p>
                    </div>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <div class="control">
                        <label class="radio" for="accountTypeOther"
                            (click)="others.checked=true;form.get('customerType').setValue('Others')">
                            <input type="radio" id="accountTypeOther" name="accountTypeOther" #others
                                (click)="others.checked=true;form.get('customerType').setValue('Others')">
                            Others
                        </label>
                        <input class="field control input" #otherType [disabled]="!others.checked"
                            (keyup)="form.get('customerType').setValue($event.target.value)" type="text" />
                    </div>

                </div>
            </div>
            <div class="columns">
                <div class="column" *ngIf="customer">
                    <app-payment-methods-editor #paymentMethodsEditor style="width:100%"  
                    [customerId$]="customerId$"
                    [billingAddress]="form.value.billingAddress"
                    ></app-payment-methods-editor> 
                </div>
            </div>
        </div>
    </div>
    <div class="columns is-centered">
        <div class="column is-2">
            <button class="button "
                [ngClass]="{'is-success': form.valid, 'is-danger': form.invalid, 'is-loading':loading}"
                [disabled]="form.invalid" (click)="create()"><strong>SAVE CUSTOMER</strong></button>
        </div>
    </div>
</div>