import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { RepsService } from 'src/app/services/reps.service';

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.scss']
})
export class CustomerListComponent implements OnInit {
  filterByVal = '';
  filtered = false;
  repsList = null;

  constructor(
    private _router: Router,
    private _spinner: SpinnerVisibilityService,
    private _activatedRoute: ActivatedRoute,
    private _repService: RepsService
  ) { }

  async ngOnInit() {
    try {
      this.repsList = await this._repService.getReps();
    }
    catch(err) {
      console.log(err);
      this._spinner.hide();
    }
  }

}
