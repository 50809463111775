<div class="is-title-bar" style="height: 50px;">
    <div class="level">
      <div class="level-left">
        <h1 class="title is-4">Commissions Report for {{getCurrentMonth()}}</h1>
      </div>
      <div class="level-right">
        <div class="info" style="margin-right: 120px;">
          <!--<div class="field has-addons">
            <p class="control">
              <a class="button is-info"
               (click)="export()">Export</a>
            </p>
            <p class="control">
              <span class="select">
                <select [(ngModel)]="monthFilter" (ngModelChange)="getOrdersList()" attr.selected="true">
                  <option value="0">This month</option>
                  <option value="2">Last 3 months</option>
                  <option value="5">Last 6 months</option>
                </select>
              </span>
            </p>
            <p class="control">
              <span class="select">
                <select [(ngModel)]="filterByVal" *ngIf="repsList">
                  <option value="">Filter by rep</option>
                  <option *ngFor="let item of repsList" [value]="item.email">{{item.firstName + ' ' + item.lastName}} ({{item.email}})</option>
                </select>
              </span>
            </p>
            <p class="control">
              <a class="button is-info" (click)="filterOrders()">
                Filter
              </a>
            </p>
            <p class="control" *ngIf="filtered">
              <a class="button is-danger" (click)="clearFilter()">
                Clear
              </a>
            </p>
          </div>
        </div>-->
      </div>
    </div>
  </div>
  
    <table class="table is-fullwidth">
      <thead>
        <tr>
          <th>Rep Name</th>
          <th>Rep Email</th>
          <!--<th>Paid</th>-->
          <th>Commission</th>
          <!--<th>Action</th>-->
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of commissions">
          <td>{{getRepName(item)}}</td>
          <td>{{item.repEmail}}</td>
          <!--<td style="font-weight:600" [ngStyle]="{'color': item.shipmentType == 'Single' ? 'green' : 'red'}">{{item.shipmentType}}</td>-->
          <td>{{item.totalCommission | currency }}</td>
          <!--<td>
            <button class="button is-success" (click)="payRep(item.repEmail)">Pay</button>
          </td>-->
        </tr>
        <tr *ngIf="commissions && commissions.length <= 0">
          <td colspan="7">No records found</td>
        </tr>
      </tbody>
    </table>
  
  
  <div class="modal is-active" *ngIf="order">
    <div class="modal-background"></div>
    <div class="modal-card" style="width: 90% !important;">
      <section class="modal-card-body">
        <div class="container" >
          <table class="table is-fullwidth">
            <tr>
              <td><img src="../../../assets/dsg_logo.png"></td>
              <td></td>
              <td class="has-text-right" style="vertical-align:middle">
                <!-- <a class="button is-info" (click)="print()">Print</a><br /><br>
                <a class="button is-info" (click)="close()">Close & Return</a> -->
              </td>
            </tr>
            <tr>
              <td>
                <h1 class="title is-3">Order Details {{order.id}}</h1>
              </td>
            </tr>
          </table>
          <table class="table is-fullwidth">
            <tr style="background-color: #ccc">
              <th>Order Date</th>
              <th>Shipment Type</th>
            </tr>
            <tr>
              <td>{{order.date | date}}</td>
              <td>{{order.shipmentType}}</td>
            </tr>
          </table>
          <table class="table is-fullwidth">
            <tr style="background-color: #ccc">
              <th>Ship To</th>
              <th>Bill To</th>
              <th>Payment</th>
            </tr>
            <tr>
              <td>
                {{order.shipping.firstName + ' ' + order.shipping.lastName}}<br />
                {{order.shipping.address }}<br />
                {{order.shipping.city + ', ' + order.shipping.state + ' ' + order.shipping.zip }} <br>
                USA<br />
                {{order.customer.billingAddress.email}}<br />
                Customer phone:{{order.customer.phone ?? order.shipping.phone}}
              </td>
              <td>
                {{order.customer.billingAddress.firstName + ' ' + order.customer.billingAddress.lastName}}<br />
                {{order.shipping.address }}<br />
                {{order.shipping.city + ', ' + order.shipping.state + ' ' + order.shipping.zip }} <br>
                USA<br />
                {{order.shipping.phone}}<br />
              </td>
              <td>
                {{order.addPaymentToInvoice ? 'Credit card payment via the invoice': ''}}
                <br />
                {{ order?.payment?.result?.transactionResponse && !order.addPaymentToInvoice ? 'Card Type: ' + order?.payment?.result?.transactionResponse?.accountType : ''}}<br />
                {{ order?.payment?.result?.transactionResponse && !order.addPaymentToInvoice ? 'Card Number: ' + order?.payment?.result?.transactionResponse?.accountNumber : ''}}<br />
                {{ order?.payment?.result?.transactionResponse && !order.addPaymentToInvoice ? 'Amount Charged:' + getFormatedOrderTotal(order) : '' }}
              </td>
            </tr>
          </table>
          <table class="table is-fullwidth">
            <tr style="background-color: #ccc">
              <th colspan="2" class="has-text-centered">Product</th>
              <th class="has-text-centered">Qty</th>
              <th class="has-text-centered">Unit Selling Price</th>
              <th class="has-text-centered" style="width: 150px">Total</th>
            </tr>
            <tr *ngFor="let item of order.orderProducts">
              <td class="has-text-centered">
                <!--<img src="../../../assets/{{ item.name.toLowerCase().replace(' ', '_')}}.png" style="width: 250px"/>-->
              </td>
              <td style="vertical-align: middle;" class="has-text-centered">{{item.name}} Box of {{item.quantity}}</td>
              <td style="vertical-align: middle;" class="has-text-centered">{{item.sellingQuantity}}</td>
              <td style="vertical-align: middle;" class="has-text-centered">{{item.sellingPrice | currency}}</td>
              <td style="vertical-align: middle;" class="has-text-centered">{{getOrderLineTotal(order.customer, item) | currency }}</td>
            </tr>
           <!-- <tr style="background-color: #ccc">
              <td colspan="5"><br></td>
            </tr>
            <tr>
              <th colspan="4" class="has-text-right">Subtotal:</th>
              <td class="has-text-right">{{order.orderTotal | currency}}</td>
            </tr>
            <tr>
              <td colspan="3"></td>
              <th style="background-color: #ccc" class="has-text-right">Shipping & Handling:</th>
              <td style="background-color: #ccc" class="has-text-right">{{order.shippingTotal | currency}}</td>
            </tr>
            <tr>
              <th colspan="4" class="has-text-right">Sales Tax:</th>
              <td class="has-text-right">{{0 | currency}}</td>
            </tr>
            <tr>
              <td colspan="3"></td>
              <th style="background-color: #ccc" class="has-text-right">Total</th>
              <td style="background-color: #ccc" class="has-text-right">{{(order.orderTotal + order.shippingTotal) | currency}}</td>
            </tr>-->
            <!-- <tr>
              <td colspan="5">
                <h1 class="title is-2 has-text-centered">Thank you for your order!</h1>
              </td>
            </tr> -->
          <tr>
            <td colspan="3"></td>
            <td style="background-color: #fff; text-align: center; font-weight: 600;">Shipping</td>
            <td style="background-color: #fff; text-align: center">{{order.shipmentRate?.shippingAmount?.amount | currency}}</td>
          </tr>
        </table>
        </div>
      </section>
      <footer class="modal-card-foot">
        <!-- <button class="button is-success">Save changes</button> -->
        <button class="button" (click)="closeOrder()">Close</button>
      </footer>
    </div>
  </div>
  