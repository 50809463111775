<ng-container *ngIf="!isHcp">
  <div class="has-text-centered">
    <a [routerLink]="['/']" routerLinkActive="router-link-active">
      <img src="/assets/dsg_logo_large.png" style="width:600px">
    </a>
      
  </div>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
  
      <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
  
    <div id="navbarBasicExample" class="navbar-menu">
      <div class="navbar-start">
        <a class="navbar-item" [routerLink]="['/']" routerLinkActive="router-link-active">
          <strong>News</strong>
        </a>
  
        <a class="navbar-item" [routerLink]="['/rep/customers']" routerLinkActive="router-link-active" *ngIf="isLoggedIn">
          <strong>Customer Registration</strong>
        </a>
  
        <a class="navbar-item" [routerLink]="['/rep/order']" routerLinkActive="router-link-active" *ngIf="isLoggedIn">
          <strong>Enter Orders</strong>
        </a>
  
        <a class="navbar-item disabled-link" style="cursor: not-allowed;" *ngIf="isLoggedIn">
          <strong> Send Order to Customer</strong>
        </a>
  
        <a class="navbar-item" [routerLink]="['/products-info']" routerLinkActive="router-link-active">
          <strong> Products Information</strong>
        </a>
  
        <a class="navbar-item disabled-link" style="cursor: not-allowed;" *ngIf="isLoggedIn">
          <strong>  Check Order Status</strong>
        </a>
  
        <a class="navbar-item disabled-link" style="cursor: not-allowed;" *ngIf="isLoggedIn">
          <strong> Compensation Monitoring</strong>
        </a>
  
        
      </div>
  
      <div class="navbar-end">
        <div class="navbar-item">
          <div class="buttons" *ngIf="!isLoggedIn && !isSubRepOrder">
            <a class="button is-primary" [routerLink]="['/login']" routerLinkActive="router-link-active">
              <strong> Rep Login </strong>
            </a>
          </div>
        
          <div class="buttons" *ngIf="isLoggedIn">
            <a class="button is-primary" [routerLink]="['/admin']" *ngIf="isAdmin">
              <strong> Admin</strong>
            </a>
            <a class="button is-primary" [routerLink]="['/rep/details']" *ngIf="!isAdmin">
              <strong> My Profile</strong>
            </a>
            <a class="button is-primary" (click)="logout()">
              <strong> Logout</strong>
            </a>
          </div>
        </div>
      </div>
    </div>
  </nav>
  <div class="app">
    <router-outlet></router-outlet>
  </div>
</ng-container>

<ng-container *ngIf="isHcp">
  <div class="hero">
    <div class="hero-head" style="padding-top: 1rem;">
      <div class="has-text-centered">
        <img src="../../../assets/dsg_logo.png">
      </div>
      <!--<div class="has-text-centered" style="margin-top: 1.5rem;">
        <a href="https://www.diagnosticsolutionsgroup.com" target="_blank" style="font-size: 25px;">About DSG</a>
      </div>-->
    </div>
    <div class="hero-body" style="padding-top: 1rem;">
      <div class="container">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</ng-container>

<ng-http-loader [spinner]="spinnerType" [filteredUrlPatterns]="['/form']"></ng-http-loader>