import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import emailMask from 'text-mask-addons/dist/emailMask';
import { OrdersService } from 'src/app/services/orders.service';
import { CustomersService } from 'src/app/services/customers.service';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/services/users.service';
import { CmsDataService } from 'src/app/services/cms-data.service';
import { CognitoUser } from '@aws-amplify/auth';
import { BaseFormComponent } from '../shared/base-form/base-form.component';
import { debounceTime, delay, map, switchMap } from 'rxjs/operators';
import { from, of, Subject } from 'rxjs';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { Customer } from 'src/app/models/customer.model';


@Component({
  selector: 'app-customer-editor',
  templateUrl: 'customer-editor.component.html',
  styleUrls: ['./customer-editor.component.scss']
})
export class CustomerEditorComponent extends BaseFormComponent implements OnInit, AfterViewInit {

  @ViewChild('others') other: ElementRef;
  @ViewChild('otherType') otherType: ElementRef;

  customer: Customer = null;
  mask = emailMask;

  form = new FormGroup({
    id: new FormControl(''),
    active: new FormControl(''),
    rep: new FormControl(''),
    QBCustomerId: new FormControl(''),
    isCliaPending: new FormControl(false),

    legalName: new FormControl('', { validators: [Validators.required] }),
    dba: new FormControl(''),
    customerType: new FormControl('', { validators: [Validators.required] }),
    clia: new FormControl('', { validators: [Validators.required, Validators.minLength(10), Validators.maxLength(10)], asyncValidators: [this.validateClia(this.cmsDataService)] }),

    billingAddress: new FormGroup({
      firstName: new FormControl('', { validators: [Validators.required] }),
      lastName: new FormControl('', { validators: [Validators.required] }),
      title: new FormControl('', { validators: [Validators.required] }),
      address: new FormControl('', { validators: [Validators.required] }),
      city: new FormControl({ value: '', disabled: false }, { validators: [Validators.required] }),
      state: new FormControl({ value: '', disabled: false }, { validators: [Validators.required] }),
      zip: new FormControl('', { validators: [Validators.required, Validators.minLength(5), Validators.maxLength(5)] }),
      phone: new FormControl('', { validators: [Validators.required, Validators.minLength(10), Validators.maxLength(11)] }),
      email: new FormControl('', { validators: [Validators.required, Validators.email] }),
      shippingIsTheSame: new FormControl('')
    }),

    shippingAddress: new FormGroup({
      firstName: new FormControl('', { validators: [Validators.required] }),
      lastName: new FormControl('', { validators: [Validators.required] }),
      address: new FormControl('', { validators: [Validators.required] }),
      city: new FormControl({ value: '', disabled: false }, { validators: [Validators.required] }),
      state: new FormControl({ value: '', disabled: false }, { validators: [Validators.required] }),
      zip: new FormControl('', { validators: [Validators.required, Validators.minLength(5), Validators.maxLength(5)] }),
      phone: new FormControl('', { validators: [Validators.required, Validators.minLength(10), Validators.maxLength(11)] }),
      hours: new FormControl('', { validators: [Validators.required] }),
    })
  });
  accountTypes = [
    "Hospitals",
    "GPOs",
    "Urgent Care Centers",
    "Nursing Home / LTC",
    "Municipalities",
    "Schools",
    "Colleges",
    "Sport Teams",
    "Group Practices",
    "Sole practitioners",
    "Physical Therapy Centers",
    "Corporations"
  ];
  private readonly pendingCLIA = "0123456789";

  loading = false;
  user: CognitoUser;
  customerId$ = new Subject<string>();
  constructor(protected ordersService: OrdersService,
    private usersService: UsersService,
    private router: Router,
    private customersService: CustomersService,
    private cmsDataService: CmsDataService) {
    super(ordersService);
    this.customer = <Customer>this.router.getCurrentNavigation().extras.state;
    if(!this.customer.QBCustomerId) this.customer.QBCustomerId = '';
  }
  ngAfterViewInit(): void {
    if (this.customer) {
      this.customerId$.next(this.customer.id);
      if(this.customer.customerType && !this.accountTypes.includes(this.customer.customerType)) {
        this.other.nativeElement.checked = true;
        this.otherType.nativeElement.value = this.customer.customerType;
      } else if(this.customer.customerType == 'Others') {
        this.other.nativeElement.value = true;
      }
    }
  }

  async ngOnInit() {
    if (this.customer) {
      this.form.setValue(this.customer);
    }
  }

  isInvalid(controlName) {
    return this.form.get(controlName)?.invalid;
  }

  hasError(controlName, error) {
    return this.form.get(controlName)?.hasError(error);
  }

  async create() {
    this.toggleLoading();
    const customer = this.form.getRawValue();
    if(!customer.rep) customer.rep = (await this.usersService.getCurrentUser()).getUsername();
    customer.active = true;

    from(this.customersService.put(customer)).pipe(switchMap(c => {
      this.customerId$.next(c.id);
      return of({})// from(this.paymentMethodsEditor.addPaymentMethod())
    })).subscribe(() => this.router.navigate(['/rep/customers']), e => {
      this.toggleLoading();
      console.log(e);
      alert('Failed to save the customer, please try again.');
    });
  }

  toggleLoading() {
    this.loading = !this.loading;
  }

  validateClia(cmsDataService: CmsDataService): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      return of(control.value).pipe(debounceTime(500), switchMap(c => cmsDataService.lookupByCLIA(c)),
        map((results: any[]) => {
          // Added due to some customers still with a CLIA pending status
          const customer = this.form.getRawValue();
          if (customer.isCliaPending && customer.clia === this.pendingCLIA) { return null }
          
          if (!results || results.length == 0) return { 'invalidClia': 'CLIA # is invalid' };
          if (results && moment(results[0].TRMNTN_EXPRTN_DT).isBefore()) return { 'expiredClia': 'CLIA # is expired' }
          return null;
        }));
    }
  };

  udpateClia() {
    if (this.form.value.isCliaPending) {
      this.form.patchValue({clia: this.pendingCLIA});
    } else {
      this.form.patchValue({clia: ''});
    }
  }

  toggleShipppingAddress() {
    const billingAddress = this.form.value.billingAddress;
    if(billingAddress.shippingIsTheSame) {
      this.form.get('shippingAddress').patchValue(Object.assign({}, billingAddress));
    } else {
      this.form.get('shippingAddress').reset();
    }
  }
}
