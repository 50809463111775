import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountingService } from 'src/app/services/accounting.service';

@Component({
  selector: 'app-quickbooks-admin',
  templateUrl: './quickbooks-admin.component.html',
  styles: ['']
})
export class QuickbooksAdminComponent implements OnInit {

  constructor(private qbService: AccountingService, private activatedRoute: ActivatedRoute) { }

  async ngOnInit() {
    this.activatedRoute.queryParams.subscribe(async(params) => {
      console.log(params);
      if(params['code'] && params['realmId'] && params['state']) {
        const result = await this.qbService.getToken(window.location.href);
        console.log(result);
      }
    })
  }

  async connectToQB() {
    const response = await this.qbService.getUrl();
    console.log(response);
    window.location.href = response.url;
  }
}
