import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Auth } from 'aws-amplify'
import * as moment from 'moment';

import { RepsService } from '../../../services/reps.service';
import { OrdersService } from '../../../services/orders.service';
import { CustomersService } from '../../../services/customers.service';
import { Rep } from '../../../models/rep.model';
import { environment } from '../../../../environments/environment';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-rep-list',
  templateUrl: './rep-list.component.html',
  styleUrls: ['./rep-list.component.scss']
})
export class RepListComponent implements OnInit {
  originalRepsList = null;
  originalOrdersList = null;
  ordersList;
  order;
  repsList;

  customersList = [];

  selectedRep = null;
  searched = false;
  searchByField = '';
  searchByValue = '';

  selectedTab = 'profile'


  userData = null;

  constructor(
    private _router: Router,
    private _toastr: ToastrService,
    private _orderService: OrdersService,
    private _repService: RepsService,
    private _customerService: CustomersService,
    private _usersService: UsersService
  ) { }

  async ngOnInit(){
    this.userData = await Auth.currentAuthenticatedUser();
    await this.getRepsList();
    await this.getOrdersList();
    this.originalRepsList = Object.assign([], this.repsList);
  }

  async allowOneTimeDiscout(rep) {
    rep.allowOneTimeDiscout = true;
    rep.oneTimeDiscoutTimeStamp = new Date();
    rep.oneTimeDiscountAllowedBy = await this._usersService.getCurrentUserEmail();
    try {
      await this._repService.updateRep(rep); 
      this._toastr.success('One-time discount saved');
      
    } catch (error) {
      this._toastr.error('Failed to save a one-time discount saved');
    }
  }

  async removeOneTimeDiscout(rep) {
    rep.allowOneTimeDiscout = undefined;
    rep.oneTimeDiscoutTimeStamp = undefined;
    rep.oneTimeDiscountAllowedBy = undefined;
    try {
      await this._repService.updateRep(rep); 
      
    } catch (error) {
      this._toastr.error('Failed to update a rep');
    }
  }

  async getRepsList() {
    this.repsList = await this._repService.getReps();
  }

  async getOrdersList() {
    const original  = await this._orderService.getOrders();
    this.originalOrdersList = [...original.sort((a, b) => b.date - a.date).filter(o => o.customer?.customerId)]; //To get rid of old orders
  }

  async getCustomersList(repId) {
    this.customersList = await this._customerService.list(repId);
  }

  async selectRep(rep) {
    this.selectedRep = rep;
    console.log(this.selectedRep);
    this.ordersList = this.originalOrdersList.filter(o => o.repEmail === this.selectedRep.email)

    await this.getCustomersList(this.selectedRep.repId);
  }

  openOrder(selectedOrder) {
    this.selectedTab = 'details';
    this.order = selectedOrder;
  }

  closeProfile() {
    this.selectedRep = null;
  }

  searchBy() {
    if (!this.searchByField) {
      this._toastr.error('Select the field to search');
      return;
    }
    if (!this.searchByValue) {
      this._toastr.error('Type the search term');
      return;
    }
    this.repsList = this.originalRepsList.filter(i => i[this.searchByField].toLowerCase().includes(this.searchByValue));
    this.searched = true;
  }

  clearSearch() {
    this.searchByField = '';
    this.searchByValue = '';
    this.repsList = Object.assign([], this.originalRepsList);
    this.searched = false;
  }
}
