import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  constructor(
    private http: HttpClient
  ) { }

  processZipCode(zipCode) {
    const url = `https://api.zip-codes.com/ZipCodesAPI.svc/1.0/QuickGetZipCodeDetails/${zipCode}?key=${environment.zipCodeApiKey}`;
    // const url = `https://api.zip-codes.com/ZipCodesAPI.svc/1.0/QuickGetZipCodeDetails/${zipCode}?key=DEMOAPIKEY`;
		return this.http.get(url).toPromise();
  }

  /*sendForm(data) {
    const url = `${environment.apiUrl}/form`;
    data.id = data.id ?? this._newGuid();
    data.date = data.date ?? Date.now();
    return this.http.post(url, data).toPromise<any>();
  }

  sendFormEmail(data) {
    const url = `${environment.apiUrl}/email`;
    const to = data.email;
    const cc = [];
    const emailHtml = `
      <h2>Thank you for your order. You will be receiving an invoice and tracking information shortly.</h2>
      <p>If you have any questions, please contact Israel Matos at 347-352-6754.</p>
      <table class="table is-fullwidth" style="border: none; width: 50%;">
        <tr>
          <td><b>Order Id.</b></td>
          <td>${data.id}</td>
        </tr>
        <tr>
          <td><b>Name</b></td>
          <td>${data.firstname} ${data.lastname}</td>
        </tr>
        <tr>
          <td><b>Email</b></td>
          <td>${data.email}</td>
        </tr>
        <tr>
          <td><b>CLIA #</b></td>
          <td>${data.clia}</td>
        </tr>
        <tr>
          <td><b>Order Quantit</b></td>
          <td>${data.quantity} boxes of 25 tests each</td>
        </tr>
        <tr>
          <td colspan="2"><b>Billing Address</b></td>
        </tr>
        <tr>
          <td colspan="2">
            <p style="margin-left: 30px">${data.billingAddress.address}<br>
            ${data.billingAddress.city}, ${data.billingAddress.state} ${data.billingAddress.zip}</p>
          </td>
        </tr>
        <tr>
          <td colspan="2"><b>Shipping Address</b></td>
        </tr>
        <tr>
          <td colspan="2">
          <p style="margin-left: 30px">${data.shippingAddress.address}<br>
          ${data.shippingAddress.city}, ${data.shippingAddress.state} ${data.shippingAddress.zip}</p>
        </td>
        </tr>        
      </table>`

    return this.http.post(url, {to: to, cc: cc, html: emailHtml, subject: 'Confirmation New Form'}).toPromise();
  }
  sendFormEmailStaff(data) {
    const url = `${environment.apiUrl}/email`;
    const to = environment.hcpOrderAdmin;
    const cc = environment.hcpOrderCC;
    const emailHtml = `
      <h2>Thank you for your order. You will be receiving an invoice and tracking information shortly.</h2>
      <p>If you have any questions, please contact Israel Matos at 347-352-6754.</p>
      <table class="table is-fullwidth" style="border: none; width: 50%;">
        <tr>
          <td><b>Order Id.</b></td>
          <td>${data.id}</td>
        </tr>
        <tr>
          <td><b>Name</b></td>
          <td>${data.firstname} ${data.lastname}</td>
        </tr>
        <tr>
          <td><b>Company</b></td>
          <td>${data.company}</td>
        </tr>
        <tr>
          <td><b>Email</b></td>
          <td>${data.email}</td>
        </tr>
        <tr>
          <td><b>CLIA #</b></td>
          <td>${data.clia}</td>
        </tr>
        <tr>
          <td><b>Order Quantit</b></td>
          <td>${data.quantity} boxes of 25 tests each</td>
        </tr>
        <tr>
          <td colspan="2"><b>Billing Address</b></td>
        </tr>
        <tr>
          <td colspan="2">
            <p style="margin-left: 30px">${data.billingAddress.address}<br>
            ${data.billingAddress.city}, ${data.billingAddress.state} ${data.billingAddress.zip}</p>
          </td>
        </tr>
        <tr>
          <td colspan="2"><b>Shipping Address</b></td>
        </tr>
        <tr>
          <td colspan="2">
          <p style="margin-left: 30px">${data.shippingAddress.address}<br>
          ${data.shippingAddress.city}, ${data.shippingAddress.state} ${data.shippingAddress.zip}</p>
        </td>
        </tr>        
      </table>`

    return this.http.post(url, {to: to, cc: environment.hcpOrderCC, html: emailHtml, subject: 'Confirmation New Form - Admin'}).toPromise();
  }*/

  private _newGuid() {
    return 'xxx4xxyx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
}
