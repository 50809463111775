import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment } from '../../environments/environment';

@Injectable({providedIn: 'root'})
export class CmsDataService {
  constructor(private httpClient: HttpClient) { }
  
  lookupByCLIA(clia: string) {
    return this.httpClient.get(`${environment.cmsDataUrl}?filter[PRVDR_NUM]=${clia}`);
  }
}