import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { PaymentMethod } from 'src/app/models/customer.model';
import { PaymentsService } from 'src/app/services/payments.service';

@Component({
  selector: 'app-payment-methods-list',
  templateUrl: './payment-methods-list.component.html',
  styleUrls: ['./payment-methods-list.component.scss']
})
export class PaymentMethodsListComponent implements OnInit {
  @Input() customerId$ = new Subject<string>();
  @Input() refresh$ = new Subject<void>();
  @Input() resetPaymentMethod$ = new Subject<void>();

  @Input() allowBankAccounts = false;
  @Input() allowCreditCards = true;
  @Input() showDeleteButton = false;
  @Input() showMethodSecretCode = false;

  @Output() onPaymentMethodSelected = new EventEmitter<{ details: PaymentMethod, secret: string, initial: boolean }>();

  private destroy$ = new Subject<void>();
  cardCode = '';

  availablePaymentMethods$: Observable<PaymentMethod[]>;
  customerId: string;
  paymentMethod: any;

  constructor(private paymentsService: PaymentsService) { }

  ngOnInit(): void {
    this.customerId$
      .pipe(
        filter(c => c != null),
        tap(id => this.customerId = id),
        switchMap(() => this.updatePaymentMethods())
      )
      .subscribe();

    this.refresh$.pipe(switchMap(() => this.updatePaymentMethods())).subscribe();

    this.resetPaymentMethod$.subscribe({
      next: () => {
        this.paymentMethod = null;
        this.cardCode = '';
      }
    });
  }

  updatePaymentMethods() {
    return this.paymentsService.getPaymentMethods(this.customerId, this.allowCreditCards, this.allowBankAccounts)
      .pipe(tap(methods => {
        this.availablePaymentMethods$ = of(methods);
        const defaultMethod = methods?.find(m => m.isDefault);
        if(defaultMethod) this.onMethodChanged(defaultMethod);
      }), takeUntil(this.destroy$));
  }

  async delete(id) {
    await this.paymentsService.deleteCard(id, this.customerId);
    this.availablePaymentMethods$ = this.paymentsService.getPaymentMethods(this.customerId, this.allowCreditCards, this.allowBankAccounts);
  }

  isInvalidCardCode() {
    return !this.cardCode || this.cardCode?.length < 3;
  }

  canShowCode(method) {
    return this.showMethodSecretCode && method.type == 'card' && method.id == this.paymentMethod?.id;
  }

  onCardCodeChanged(code) {
    this.onPaymentMethodSelected.emit({ details: this.paymentMethod, secret: code, initial: false });
  }

  onMethodChanged(method, initial = true) {
    this.cardCode = '';
    this.paymentMethod = method;
    this.onPaymentMethodSelected.emit({ details: method, secret: '', initial });
  }
}
