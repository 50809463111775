<section class="section is-small">
  <aw-wizard #wizard [disableNavigationBar]="true">
    <aw-wizard-step [canExit]="orderEntry?.orderCommission >= 0">
      <app-order-entry-form [nextButtonText]="'To Shipping'" [showRepsCustomerList]="true"
      (customerChange)="customerChanged($event)"
      (orderEntryReady)="onOrderReady($event)"></app-order-entry-form>
    </aw-wizard-step>
    <aw-wizard-step>
      <app-customer-shipping-details #shipDetails
       [customer$]="customer$$" 
       [allowPickShipmentService]="true" 
       [isNewCustomer]="false"
       [orderEntry$]="orderEntry$$"
       [showCommission]="true"
       [nextButtonLabel]="'Go To Payment'"
       (onShipDetailsReady)="goToPayment($event)">

      </app-customer-shipping-details>
    </aw-wizard-step>
    <aw-wizard-step awOptionalStep>
      <div class="columns is-centered is-multiline" *ngIf="lessThan15000()">

        <div class="column is-6">

          <div class="column is-narrow has-text-centered">Payment (Credit Card)</div>
          <div class="columns is-centered is-size-4">
            <div class="field is-horizontal is-align-items-center">
              <div class="field-body" [formGroup]="form">
                <div class="field  is-large">
                  <div class="control has-icons-right">
                    <label class="checkbox is-size-4">
                      <input type="checkbox" formControlName="addPaymentToInvoice">
                      Credit card payment via the invoice
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="box" [ngStyle]="{'display':form.value.addPaymentToInvoice? 'none': 'block'}" >
              <app-payment-form
              [orderId$]="orderId$$"
              [customerId$]="customerId$"
              [reset$]="resetPaymentMethod$"
              [allowBankAccounts]="false"
              [allowCreditCards]="true"
              [amount]="calculateOrderAmount()"
              (onPaymentCompleted)="paymentCompletedHandler($event)"
              >
              </app-payment-form>
          </div>
        </div>
      </div>

      <div class="columns is-centered">
        <div class="column is-narrow" *ngIf="(!paymentResults && paymentResults?.success) || !form.value.addPaymentToInvoice">
          <button class="button is-info m-3" type="button" [disabled]="order || paymentResults?.success" awPreviousStep>Back To Shipping</button>
          <!--<button class="button is-success m-3" type="button" [awGoToStep]="{ stepIndex: 4 }"
            (click)="createOrder()">Next</button>-->
        </div>
        <div class="column is-narrow" *ngIf="(paymentResults && paymentResults.success) || form.value.addPaymentToInvoice">
          <button class="button is-success m-3" type="button" [awGoToStep]="{ stepIndex: 4 }"
            (click)="createOrder()">Next</button>
        </div>
      </div>
    </aw-wizard-step>
    <aw-wizard-step awOptionalStep>
      <div class="columns is-centered" *ngIf="!lessThan15000()">
        <div class="column is-6">
          <div class="box">
            <p class="title">Please call (347) 352-6754 for details</p>
            <!--<app-payment-form
              [customerId$]="customerId$"
              [reset$]="resetPaymentMethod$"
              [allowBankAccounts]="true"
              [allowCreditCards]="false"
              [amount]="calculateOrderAmount()"
              (onPaymentCompleted)="paymentCompletedHandler($event)"
              >
              </app-payment-form>-->
        </div>
        </div>
      </div>
      <div class="columns is-centered">
        <div class="column is-narrow">
          <button class="button is-info m-3" type="button" [disabled]="order || paymentResults?.success" [awGoToStep]="{ stepIndex: 1 }">Back To Shipping</button>
          <button class="button is-success m-3" type="button" (click)="createOrder()" [awGoToStep]="{ stepIndex: 4 }">Checkout</button>
        </div>
      </div>
    </aw-wizard-step>
    <aw-wizard-step>
      <div class="columns is-centered">
        <div class="column is-narrow">
          <div class="box">
            THE ORDER HAS BEEN SENT! THANK YOU!
            <table class="table">
              <thead>
                <tr>
                  <th>Summary</th>
                  <th></th>
                  <th *ngIf="form.value.billShipmentTo == 'rep'"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Order Total:</td>
                  <td>{{this.orderEntry?.orderExtension|currency}}</td>
                </tr>
                <tr>
                  <td>Base Cost Total:</td>
                  <td>-{{this.orderEntry?.orderBaseCost|currency}}</td>
                </tr>
                <tr *ngIf="form.value.billShipmentTo == 'rep'">
                  <td>Freight Charges:</td>
                  <td>-{{form.value.shipmentRate?.shippingAmount?.amount|currency}}</td>
                </tr>
                <tr>
                  <td>Commission Earned:</td>
                  <td> {{this.orderEntry?.orderCommission - (form.value.billShipmentTo == 'rep' ?
                    form.value.shipmentRate?.shippingAmount?.amount : 0)|currency}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="columns is-centered">
        <div class="column is-narrow">
          <button class="button is-success m-3" type="button" awResetWizard (finalize)="finish()">CLOSE</button>
        </div>
      </div>
    </aw-wizard-step>
  </aw-wizard>
</section>