<app-base-payment-methods [customerId$]="customerId$" [newMethodLabel]="'Pay via'" 
    [showCustomerPaymentMethods]="showCustomerPaymentMethods"
    [allowUpdateDefaultPaymentMethod]="allowUpdateDefaultPaymentMethod"
    [allowBankAccounts]="allowBankAccounts" [allowCreditCards]="allowCreditCards" [showCancelButton]="false"
    [showMethodSecretCode]="true"
    [formType]="formType"
    [position]="'bottom'"
    [reset$]="reset$"
    [resetExistingPaymentMethod$]="resetExistingPaymentMethod$"
    [cardCodeDisabled]="false" [showActionButton]="hasValidPaymentMethod() && !paymentResult?.success" [showDeleteButton]="false" [actionButtonLabel]="'Pay Now ' + (amount|currency)"
    (onActionClicked)="pay($event)" (onPaymentMethodChange)="onPaymentMethodDetailsChanged($event)"
    [billingAddress]="billingAddress">
</app-base-payment-methods>


<p class="has-text-centered" *ngIf="paymentResult && paymentResult?.unknown">
    <strong>Payment failed, verify payment details and try again.</strong><br/>
    If the issue persists, please call us at (212) 385-4547
</p>

<p class="has-text-centered" *ngIf="paymentResult && !paymentResult?.unknown && !paymentResult?.success && paymentResult?.result?.transactionResponse?.accountType != 'eCheck'">
    <strong>Payment failed, verify payment details(Card Number, Expiry Date, Card Security Code and ZIP) and try again.</strong><br/>
    If the issue persists, please call us at (212) 385-4547
</p>

<div class="card" *ngIf="paymentResult && !paymentResult?.unknown && !paymentResult?.success && paymentResult?.result.transactionResponse?.accountType != 'eCheck'">
    <header class="card-header">
        <p class="card-header-title">
            Details
        </p>
        <a (click)="toggleErrorsDetails()" data-action="collapse" class="card-header-icon is-hidden-fullscreen" aria-label="more options">
            <span class="icon">
                <i class="fas fa-angle-down" aria-hidden="true"></i>
            </span>
        </a>
    </header>
    <div [hidden]="!showErrorsDetails" class="is-collapsible">
        <div class="card-content">
            <div [ngSwitch]="paymentResult?.result.transactionResponse?.cvvResultCode">
                <p>Card code verification:
                    <span class="is-success" *ngSwitchCase="'M'">Matched</span>
                    <span class="is-error" *ngSwitchCase="'N'">CVV did not match</span>
                    <span class="is-error" *ngSwitchCase="'P'">CVV was not processed</span>
                    <span class="is-error" *ngSwitchCase="'S'">CVV should have been present but was not indicated</span>
                    <span class="is-error" *ngSwitchCase="'U'"> The issuer was unable to process the CVV check</span>
                </p>
            </div>

            <div [ngSwitch]="paymentResult?.result.transactionResponse?.cavvResultCode">
                <p>Card code verification:
                    <span class="is-success" *ngSwitchCase="0">CAVV was not validated because erroneous data was submitted</span>
                    <span class="is-error" *ngSwitchCase="1">CAVV failed validation</span>
                    <span class="is-error" *ngSwitchCase="2">CAVV passed validation</span>
                    <span class="is-error" *ngSwitchCase="3">CAVV validation could not be performed; issuer attempt incomplete</span>
                    <span class="is-error" *ngSwitchCase="4">CAVV validation could not be performed; issuer system error</span>
                    <span class="is-error" *ngSwitchCase="5">{{paymentResult?.result.transactionResponse?.cavvResultCode}}</span>
                    <span class="is-error" *ngSwitchCase="6">{{paymentResult?.result.transactionResponse?.cavvResultCode}}</span>
                    <span class="is-error" *ngSwitchCase="7">CAVV failed validation, but the issuer is available. Valid for U.S.-issued card submitted to non-U.S acquirer</span>
                    <span class="is-error" *ngSwitchCase="8">CAVV passed validation and the issuer is available. Valid for U.S.-issued card submitted to non-U.S. acquirer</span>
                    <span class="is-error" *ngSwitchCase="9">CAVV failed validation and the issuer is unavailable. Valid for U.S.-issued card submitted to non-U.S acquirer</span>
                    <span class="is-error" *ngSwitchCase="'A'">CAVV passed validation but the issuer unavailable. Valid for U.S.-issued card submitted to non-U.S acquirer</span>
                    <span class="is-error" *ngSwitchCase="'B'">CAVV passed validation, information only, no liability shift</span>
                </p>
            </div>

            <div [ngSwitch]="paymentResult?.result.transactionResponse?.avsResultCode">
                <p>Address verification:
                    <span class="is-success" *ngSwitchCase="'A'">The street address matched, but the postal code did not</span>
                    <span class="is-error" *ngSwitchCase="'B'">No address information was provided</span>
                    <span class="is-error" *ngSwitchCase="'E'">The AVS check returned an error</span>
                    <span class="is-error" *ngSwitchCase="'G'">The card was issued by a bank outside the U.S. and does not support AVS</span>
                    <span class="is-error" *ngSwitchCase="'N'">Neither the street address nor postal code matched</span>
                    <span class="is-success" *ngSwitchCase="'P'">AVS is not applicable for this transaction</span>
                    <span class="is-error" *ngSwitchCase="'R'">Retry — AVS was unavailable or timed out</span>
                    <span class="is-error" *ngSwitchCase="'S'">AVS is not supported by card issuer</span>
                    <span class="is-error" *ngSwitchCase="'U'">Address information is unavailable</span>
                    <span class="is-error" *ngSwitchCase="'W'">The US ZIP+4 code matches, but the street address does not</span>
                    <span class="is-error" *ngSwitchCase="'X'">Both the street address and the US ZIP+4 code matched</span>
                    <span class="is-error" *ngSwitchCase="'Y'">The street address and postal code matched</span>
                    <span class="is-error" *ngSwitchCase="'Z'">The postal code matched, but the street address did not</span>
                </p>
            </div>

            <p>TransactionId: {{paymentResult?.result.transactionResponse?.transId ?? 'N/A'}}</p>
            <p>Result Code: {{paymentResult?.result.messages.resultCode}}</p>
            <div *ngFor="let item of paymentResult?.result.messages.message">
                <p>Response Code: {{item.code}}</p>
                <p>Description: {{item.text}}</p>
                <br/>
                </div>
            <br/>

            <div [ngSwitch]="paymentResult?.result.transactionResponse?.responseCode">
                <p>Transaction status:
                    <span *ngSwitchCase="1">Approved</span>
                    <span *ngSwitchCase="2">Declined</span>
                    <span *ngSwitchCase="3">Error</span>
                    <span *ngSwitchCase="4">Held for Review</span>
                </p>
            </div>

            <div *ngFor="let item of paymentResult?.result.transactionResponse?.errors.error">
               <p>Error Code: {{item.errorCode}}</p>
               <p>Error description: {{item.errorText}}</p>
               <br/>
            </div>
        </div>
    </div>
</div>

<p class="has-text-centered" *ngIf="paymentResult && !paymentResult?.unknown &&  !paymentResult?.success && paymentResult?.result.transactionResponse?.accountType == 'eCheck'">
    <strong>Payment failed, verify payment details(Account Number, Rounting Number and Account Type) and try again.</strong><br/>
    If the issue persists, please call us at (212) 385-4547
</p>

<div class="card" *ngIf="paymentResult && !paymentResult?.unknown && !paymentResult?.success && paymentResult?.result.transactionResponse?.accountType == 'eCheck'">
    <header class="card-header">
        <p class="card-header-title">
            Details
        </p>
        <a (click)="toggleErrorsDetails()" data-action="collapse" class="card-header-icon is-hidden-fullscreen" aria-label="more options">
            <span class="icon">
                <i class="fas fa-angle-down" aria-hidden="true"></i>
            </span>
        </a>
    </header>
    <div [hidden]="!showErrorsDetails" class="is-collapsible">
        <div class="card-content">
            <p>TransactionId: {{paymentResult?.result.transactionResponse?.transId ?? 'N/A'}}</p>
            <p>Result Code: {{paymentResult?.result.messages.resultCode}}</p>
            <div *ngFor="let item of paymentResult?.result.messages.message">
                <p>Response Code: {{item.code}}</p>
                <p>Description: {{item.text}}</p>
                <br/>
                </div>
            <br/>

            <div [ngSwitch]="paymentResult?.result.transactionResponse?.responseCode">
                <p>Transaction status:
                    <span *ngSwitchCase="1">Approved</span>
                    <span *ngSwitchCase="2">Declined</span>
                    <span *ngSwitchCase="3">Error</span>
                    <span *ngSwitchCase="4">Held for Review</span>
                </p>
            </div>

            <div *ngFor="let item of paymentResult?.result.transactionResponse?.errors.error">
               <p>Error Code: {{item.errorCode}}</p>
               <p>Error description: {{item.errorText}}</p>
               <br/>
            </div>
        </div>
    </div>
</div>


<p class="has-text-centered" *ngIf="paymentResult && paymentResult?.success">
    SUCESSFULL PAYMENT!
</p>