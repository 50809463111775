<div class="columns" *ngFor="let method of availablePaymentMethods$ | async; let i = index">
    <div class="column">
        <div *ngIf="i==0" class="divider">Existing payment methods</div>

        <div class="columns">
            <div class="column" *ngIf="method.type=='card' else achItemTemplate">
                <div class="columns">
                    <div class="column is-narrow">
                        <div>
                            <label class="radio" for="method-{{method.id}}">
                                <input type="radio"
                                    [checked]="method.isDefault"
                                    name="paymentMethod" #idv (change)="onMethodChanged(method, false)"
                                    id="method-{{method?.id}}">
                            </label>
                        </div>
                    </div>
                    <div class="column is-3">
                        <p>Credit Card</p>
                    </div>
                    <div class="column is-2">
                        {{method.isDefault ? 'Default': ''}}
                    </div>
                    <div class="column is-narrow">
                        {{method.cardNumber}}
                    </div>
                    <div class="column is-narrow" *ngIf="showDeleteButton">
                        <button class="button is-small is-danger" (click)="delete(method.id)">Delete</button>
                    </div>
                    <div class="column is-narrow"
                        *ngIf="canShowCode(method)">
                        <div class="field is-horizontal">
                            <div class="field-body">
                                <div class="field">
                                    <div class="control  has-icons-right">
                                        <input class="input" [(ngModel)]="cardCode" (ngModelChange)="onCardCodeChanged($event)" autofocus type="password" placeholder="Card security code"
                                            autocomplete="cc-cvc" ccCvc>
                                        <span class="icon is-small is-right icon-red"
                                            *ngIf="isInvalidCardCode()">
                                            <i class="fas fa-exclamation-triangle"></i>
                                        </span>
                                        <span *ngIf="isInvalidCardCode()" class="help is-danger">Code is required</span>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <ng-template #achItemTemplate>
                <div class="column">
                    <div class="columns">
                        <div class="column is-narrow">
                            <div>
                                <label class="radio" for="bank-{{method.id}}">
                                    <input type="radio"
                                        [checked]="method.isDefault"
                                        name="paymentMethod" #idv (change)="onMethodChanged(method, false)" id="bank-{{method?.id}}">
                                </label>
                            </div>
                        </div>
                        <div class="column is-3">
                            <p>Bank Account</p>
                        </div>
                        <div class="column is-2">
                            {{method.isDefault ? 'Default': ''}}
                        </div>
                        <div class="column is-narrow">
                            {{method.accountNumber}}
                        </div>
                        <div class="column is-narrow" *ngIf="showDeleteButton">
                            <button class="button is-small is-danger" (click)="delete(method.id)">Delete</button>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>