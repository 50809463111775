import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import Amplify, { Auth, Storage  } from 'aws-amplify';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import awsmobile from "../aws-exports";
import { ToastrModule } from 'ngx-toastr';
import { NgxMaskModule } from 'ngx-mask';
import { TextMaskModule } from 'angular2-text-mask';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { AppRoutingModule } from './app-routing.module';
import AppComponent from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './components/reps/login/login.component';
import { HomeComponent } from './components/home/home.component';


import { OrderListComponent } from './components/admin/order-list/order-list.component';
import { UsersListComponent } from './components/users-list/users-list.component';


import { FilterPipe } from './helpers/filter.pipe';

import { NgSelectModule } from '@ng-select/ng-select';
import { CustomersListComponent } from './components/customers-list/customers-list.component';
import { ProductInfoComponent } from './components/product-info/product-info.component';
import { RepRegistrationComponent } from './components/reps/rep-registration/rep-registration.component';
import { RepListComponent } from './components/admin/rep-list/rep-list.component';

import { ModalComponent } from './components/shared/modal/modal.component';
import { CustomerEditorComponent } from './components/customer-editor/customer-editor.component';
import { RepDetailsComponent } from './components/reps/rep-details/rep-details.component';
import { HomeAdminComponent } from './components/admin/home-admin/home-admin.component';
import { BaseFormComponent } from './components/shared/base-form/base-form.component';
import { CustomerListComponent } from './components/admin/customer-list/customer-list.component';
import { ArchwizardModule } from 'angular-archwizard';
import { RepOrderWizardComponent } from './components/reps/rep-order-wizard/rep-order-wizard.component';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { NgxCurrencyModule } from "ngx-currency";
import { CCCvcFormatDirective } from './directives/cc-cvc-format.directive';
import { CCExpiryFormatDirective } from './directives/cc-expiry-format.directive';
import { CCNumberFormatDirective } from './directives/cc-number-format.directive';
import { PaymentMethodsEditorComponent } from './components/payments/payment-methods-editor/payment-methods-editor.component';
import { PaymentDetailsComponent } from './components/payments/payment-details/payment-details.component';
import { PaymentMethodsListComponent } from './components/payments/payment-methods-list/payment-methods-list.component';
import { PaymentFormComponent } from './components/payments/payment-form/payment-form.component';
import { BasePaymentMethodsComponent } from './components/payments/base-payment-methods/base-payment-methods.component';
import { AmplifyAngularModule, AmplifyService } from 'aws-amplify-angular';
import { OrderEntryFormComponent } from './components/reps/order-entry-form/order-entry-form.component';
import { CustomerOrderFormComponent } from './components/reps/customer-order-form/customer-order-form.component';
import { EmailService } from './services/email.service';
import { CustomerShippingDetailsComponent } from './components/reps/customer-shipping-details/customer-shipping-details.component';
import { ProductEditorComponent } from './components/admin/product-editor/product-editor.component';
import { ProductsListComponent } from './components/admin/products-list/products-list.component';
import { DialogModule } from '@ngneat/dialog';
import { HcpOrderFormComponent } from './components/hcp-order-form/hcp-order-form.component';
import { QuickbooksAdminComponent } from './components/admin/quickbooks-admin/quickbooks-admin.component';
import { RepsCommissionsComponent } from './components/admin/reps-commissions/reps-commissions.component';
import { InputMaskModule } from '@ngneat/input-mask';

Amplify.configure(awsmobile);
Amplify.API.configure(awsmobile);

const CC_DIRECTIVES = [
	CCNumberFormatDirective,
	CCExpiryFormatDirective, 
	CCCvcFormatDirective
];

export const customCurrencyMaskConfig = {
  align: "right",
  allowNegative: false,
  allowZero: true,
  decimal: ".",
  precision: 2,
  prefix: "$ ",
  suffix: "",
  thousands: ",",
  nullable: true
};

  
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    OrderListComponent,
    UsersListComponent,
    FilterPipe,
    CustomersListComponent,
    ProductInfoComponent,
    CustomerEditorComponent,
    RepRegistrationComponent,
    ModalComponent,
    RepListComponent,
    RepDetailsComponent,
    HomeAdminComponent,
    BaseFormComponent,
    CustomerListComponent,
    RepOrderWizardComponent,
    CC_DIRECTIVES,
    PaymentMethodsEditorComponent,
    PaymentDetailsComponent,
    PaymentMethodsListComponent,
    PaymentFormComponent,
    BasePaymentMethodsComponent,
    OrderEntryFormComponent,
    CustomerOrderFormComponent,
    CustomerShippingDetailsComponent,
    ProductEditorComponent,
    ProductsListComponent,
    HcpOrderFormComponent,
    QuickbooksAdminComponent,
    RepsCommissionsComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgHttpLoaderModule.forRoot(),
    ToastrModule.forRoot({
      closeButton: true,
      enableHtml: true
    }), 
    NgSelectModule,
    NgxMaskModule.forRoot(),
    TextMaskModule,
    AmplifyAngularModule,
    NgxDatatableModule.forRoot({ messages: {
      emptyMessage: 'No data to display', // Message to show when array is presented, but contains no values
      totalMessage: 'total', // Footer total message
      selectedMessage: 'selected' // Footer selected message
    }}),
    ArchwizardModule.forRoot(),
    NgxUsefulSwiperModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    DialogModule.forRoot(),
    InputMaskModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "en-US" },
    AmplifyService,
    EmailService

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
