import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Auth } from 'aws-amplify';
import { SpinnerVisibilityService } from 'ng-http-loader';

import { Order } from 'src/app/models/order.model';
import { CustomersService } from '../../services/customers.service';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { UsersService } from 'src/app/services/users.service';
import { Customer } from 'src/app/models/customer.model';

@Component({
  selector: 'app-customers-list',
  templateUrl: './customers-list.component.html',
  styleUrls: ['./customers-list.component.scss']
})
export class CustomersListComponent implements OnInit {
  ColumnMode = ColumnMode;
  @ViewChild('myTable') table: any;
  expanded: any = {};

  userId: string;
  isAdmin: boolean;
  customers: Customer[];
  newCustomer: Customer = {
    active: true,
    clia: '',
    isCliaPending: false,
    billingAddress: {
      firstName: '',
      lastName: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      email: '',
      phone: '',
      title: ''
    },
    customerType: '',
    dba: '',
    id: '',
    legalName: '',
    rep: '',
    shippingAddress: {
      firstName: '',
      lastName: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      phone: '',
      hours: '',
    },
    card: {
      id: '',
      type: 'card',
      holderName: '',
      billTo: {
        zip: ''
      },
      isDefault: true,
      cardNumber: '',
      cardExpiryYear: '',
      cardExpiryMonth: '',
      isBusiness: ''
    },
    QBCustomerId: '',
  }
  
  constructor(
    private router: Router,
    private userService: UsersService,
    private activatedRoute: ActivatedRoute,
    private customersService: CustomersService) { }

  async ngOnInit() {
      this.userId = (await this.userService.getCurrentUser()).getUsername();
      this.isAdmin = await this.userService.isAdmin();
      await this.getCustomers();
  }

  async getCustomers() {
      const customers = await this.customersService.list(this.isAdmin ? 'all': this.userId);
      this.customers = this.isAdmin ? customers : customers.filter(c => c.active);
  }

  toggleExpandRow(row) {
    console.log('Toggled Expand Row!', row);
    this.table.rowDetail.toggleExpandRow(row);
  }

  onDetailToggle(event) {
    console.log('Detail Toggled', event);
  }

  onPage(event) {
  
  }

  addNewCustomer() {
    this.router.navigate(['/rep/customers/add'], {state: this.newCustomer});
  }

  goToEdit(row) {
    if (row.isCliaPending === undefined) {
      row.isCliaPending = false;
    }
    this.router.navigate(['/rep/customers/add'], {state: row});
  }

  async delete(customer) {
    await this.customersService.delete(customer);
    await this.getCustomers();
  }

}
