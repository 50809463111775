import { Injectable } from '@angular/core';
import * as LogRocket from 'logrocket';
import { UsersService } from './users.service';

@Injectable({providedIn: 'root'})
export class LogRocketService {
    constructor(private usersService: UsersService) { }
    async identifyUser() {
        const user = await this.usersService.getCurrentUser();
        const email = await this.usersService.getCurrentUserEmail();
        if(!user && !email) return;
    
        LogRocket.identify(email, {
          userId: user.getUsername()
        });
      }
}