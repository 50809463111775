<div class="has-text-centered is-size-3">March Specials</div>
<div class="has-text-centered is-size-3">Online Order Form</div>
<aw-wizard #wizard [disableNavigationBar]="true" navBarLayout="large-empty-symbols">
  <aw-wizard-step [navigationSymbol]="{ symbol: '1' }">
    <ng-template awWizardStepTitle let-wizardStep="wizardStep">
      {{ wizardStep.completed ? "ORDER FORM (✔)" : "ORDER FORM" }}
    </ng-template>
    <div [formGroup]="form">
      <div class="columns is-vcentered" *ngFor="let product of products">
        <div class="column is-2">
          <div class="image">
            <img class="" [attr.src]="product.image" />
          </div>
        </div>
        <div class="column is-7">
          <div class="is-size-5">{{product.name}}</div>
        </div>
        <div class="column is-1">
          <div class="ml-3 control">
            <input class="input" min="0" step="1" type="number" [mask]="'99'" (change)="updateQuantity($event, product)" (keypress)="stripDecimalSeparator($event,product)">
            <span class="is-size-7"># of boxes</span>
           <!--<span class="icon is-small is-right icon-red" *ngIf="product.sellingQuantity?.toString()?.includes('.') || product.sellingQuantity?.toString()?.includes(',')">
              <i class="fas fa-exclamation-triangle"></i>
            </span>
             <span class="icon is-small is-right icon-green"
              *ngIf="!form.get('quantity').pristine && !isInvalid('quantity')">
              <i class="fas fa-check"></i>
            </span>-->
          </div>
        </div>
        <div class="column is-2">
          <div class="control" *ngIf="product.sellingQuantity > 0">
            <input class="input has-text-centered" type="text" step="1" readonly
              [attr.value]="getProductSubTotal(product) | currency">
            <div class="has-text-centered is-fullwidth">
              <span class="is-size-7">Total $</span>
            </div>
          </div>
        </div>
      </div>

      <!--<div class="field">
        <label class="label">Email *</label>
        <div class="control has-icons-right half-width">
          <input class="input" #em type="email" placeholder="Email Address" [formControlName]="'email'">
          <span class="icon is-small is-right icon-red" *ngIf="isInvalid('email')">
            <i class="fas fa-exclamation-triangle"></i>
          </span>
          <span class="icon is-small is-right icon-green" *ngIf="!form.get('email').pristine && !isInvalid('email')">
            <i class="fas fa-check"></i>
          </span>
        </div>
      </div>-->

      <div class="field">
        <label class="label">CLIA # *</label>
        <div class="control has-icons-right half-width">
          <input class="input" type="text" placeholder="CLIA #" [formControlName]="'clia'">
          <span class="icon is-small is-right icon-red" *ngIf="isInvalid('clia')">
            <i class="fas fa-exclamation-triangle"></i>
          </span>
          <span class="icon is-small is-right icon-green" *ngIf="!form.get('clia').pristine && !isInvalid('clia')">
            <i class="fas fa-check"></i>
          </span>
          <p class="help is-danger" *ngIf="hasError('clia', 'expiredClia')" class="help is-danger">CLIA # is
            expired
          </p>
          <p class="help is-danger" *ngIf="hasError('clia', 'invalidClia')" class="help is-danger">CLIA # is
            invalid
          </p>
        </div>
      </div>

      <div class="columns mt-5">
        <div class="column" *ngIf="rates$|async as rates"
          style="border: 2px solid #3e8ed0;border-radius: 20px;margin: 1rem;padding: 1.5rem 2rem 1.5rem 1.5rem;">
          <h4 class="subtitle has-text-centered">Shipping Options</h4>
          <div *ngIf="!form.get('shipmentRate').valid" class="has-text-center sub-title">Pick available shipment method</div>
          <div class="column is-narrow">
            <div *ngIf="rates.error">
              Failed to fetch rates:
              <button class="button is-info" (click)="getRates()">Try again</button>
            </div>
            <div *ngIf="!rates">Loading...</div>
            <div *ngIf="rates.length == 0">No available delivery time slots. Please call your DSG specialist at (347) 352-6754</div>
            <!--<div class="control">
                <span class="icon is-small is-left icon-red" *ngIf="isInvalid('shipmentRate')">
                    <i class="fas fa-exclamation-triangle"></i>
                </span>
                <span class="icon is-small is-left icon-green" *ngIf="!isInvalid('shipmentRate')">
                    <i class="fas fa-check"></i>
                </span>
            </div>-->
            <table class="table" *ngIf="!rates.error && rates.length > 0">
              <thead>
                <tr>
                  <th></th>
                  <th>Service</th>
                  <th>Estimated Date</th>
                  <th>Shipping Cost</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let rate of rates">
                  <td>
                    <label class="radio">
                      <input type="radio" formControlName="shipmentRate" name="shipmentRate" [value]="rate">

                    </label>
                  </td>
                  <td>{{rate.serviceType}}</td>
                  <td>{{rate.estimatedDeliveryDate| date:'EEEE, MMMM d, y, h:mm:ss a'}}</td>
                  <td>{{rate.shippingAmount.amount | currency}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="column"
          style="border: 2px solid #3e8ed0;border-radius: 20px;margin: 1rem;padding: 1.5rem 1.5rem 1.5rem 2rem;">
          <h4 class="subtitle has-text-centered">Shipping Address</h4>
          <div class="field is-horizontal">

            <div class="field-body">
              <div class="field">
                <label class="label">First Name *</label>
                <div class="control has-icons-right">
                  <input class="input" type="text" placeholder="First Name" [formControlName]="'firstName'">
                  <span class="icon is-small is-right icon-red" *ngIf="isInvalid('firstName')">
                    <i class="fas fa-exclamation-triangle"></i>
                  </span>
                  <span class="icon is-small is-right icon-green"
                    *ngIf="!form.get('firstName').pristine && !isInvalid('firstName')">
                    <i class="fas fa-check"></i>
                  </span>
                </div>
              </div>

              <div class="field">
                <label class="label">Last Name *</label>
                <div class="control has-icons-right">
                  <input class="input" type="text" placeholder="Last Name" [formControlName]="'lastName'">
                  <span class="icon is-small is-right icon-red" *ngIf="isInvalid('lastName')">
                    <i class="fas fa-exclamation-triangle"></i>
                  </span>
                  <span class="icon is-small is-right icon-green"
                    *ngIf="!form.get('lastName').pristine && !isInvalid('lastName')">
                    <i class="fas fa-check"></i>
                  </span>
                </div>
              </div>

            </div>
          </div>

          <div class="field">
            <label class="label">Phone *</label>
            <div class="control has-icons-right">
              <input class="input" #em type="tel" maxlength="10" placeholder="Phone" [formControlName]="'phone'">
              <span class="icon is-small is-right icon-red" *ngIf="isInvalid('phone')">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
              <span class="icon is-small is-right icon-green"
                *ngIf="!form.get('phone').pristine && !isInvalid('phone')">
                <i class="fas fa-check"></i>
              </span>
            </div>
          </div>
          <div [formGroupName]="'shippingAddress'">
            <div class="field">
              <label class="label">Address *</label>
              <div class="control has-icons-right">
                <input class="input" type="text" placeholder="Shipping Address" [formControlName]="'address'">
                <span class="icon is-small is-right icon-red" *ngIf="isInvalid('shippingAddress.address')">
                  <i class="fas fa-exclamation-triangle"></i>
                </span>
                <span class="icon is-small is-right icon-green"
                  *ngIf="!form.get('shippingAddress.address').pristine && !isInvalid('shippingAddress.address')">
                  <i class="fas fa-check"></i>
                </span>
              </div>
            </div>
            <div class="field" *ngIf="form.get('shippingAddress.city')?.value">
              <label class="label">City *</label>
              <div class="control has-icons-right">
                <input class="input" type="text" readonly placeholder="Shipping Address City"
                  [formControlName]="'city'">
                <span class="icon is-small is-right icon-red" *ngIf="isInvalid('shippingAddress.city')">
                  <i class="fas fa-exclamation-triangle"></i>
                </span>
                <span class="icon is-small is-right icon-green"
                  *ngIf="!form.get('shippingAddress.city').pristine && !isInvalid('shippingAddress.city')">
                  <i class="fas fa-check"></i>
                </span>
              </div>
            </div>
            <div class="field" *ngIf="form.get('shippingAddress.state')?.value">
              <label class="label">State *</label>
              <div class="control has-icons-right">
                <input class="input" type="text" readonly placeholder="Shipping Address State"
                  [formControlName]="'state'">
                <span class="icon is-small is-right icon-red" *ngIf="isInvalid('shippingAddress.state')">
                  <i class="fas fa-exclamation-triangle"></i>
                </span>
                <span class="icon is-small is-right icon-green"
                  *ngIf="!form.get('shippingAddress.state').pristine && !isInvalid('shippingAddress.state')">
                  <i class="fas fa-check"></i>
                </span>
              </div>
            </div>
            <div class="field">
              <label class="label">Zip *</label>
              <div class="control has-icons-right">
                <input class="input" #spc type="text" placeholder="Shipping Address Zip Code"
                  [formControlName]="'zip'">
                <span class="icon is-small is-right icon-red" *ngIf="isInvalid('shippingAddress.zip')">
                  <i class="fas fa-exclamation-triangle"></i>
                </span>
                <span class="icon is-small is-right icon-green"
                  *ngIf="!form.get('shippingAddress.zip').pristine && !isInvalid('shippingAddress.zip')">
                  <i class="fas fa-check"></i>
                </span>
                <p class="help is-danger" *ngIf="hasError('shippingAddress.zip', 'invalidZip')" class="help is-danger">Invalid ZIP
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="columns is-centered">
              <div *ngIf="form.valid && calculateOrderSubTotal() > 0" class="column is-narrow is-centered">
        <div class="box">
          <table class="table">
            <thead>
              <tr>
                <th>Order Summary</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr class="has-text-weight-bold">
                <td>Order Total:</td>
                <td> {{calculateOrderSubTotal()|currency}}</td>
              </tr>
              <tr>
                <td>Shipping:</td>
                <td>{{form.value.shipmentRate?.shippingAmount?.amount|currency}}</td>
              </tr>
              <tr>
                <td>Taxable Subtotal:</td>
                <td>{{calculateOrderTaxableSubTotal() |currency}}</td>
              </tr>
              <tr>
                <td>Tax:</td>
                <td>{{calculateTax()|currency}}</td>
              </tr>
              <tr>
                <td>Total:</td>
                <td>{{calculateOrderTotal()|currency}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      </div>


      <div class="field is-grouped has-text-centered">
        <div class="control" style="margin: 0 auto">
          <button [attr.disabled]="form.valid ? null : true" class="button is-info is-medium"
            [ngClass]="{'is-loading': isSending}" (click)="goToPayment()">Confirm Order & Pay</button>
        </div>
      </div>
      <div class="modal is-active" *ngIf="showModal">
        <div class="modal-background"></div>
        <div class="modal-card">
          <!-- <header class="modal-card-head">
            <p class="modal-card-title">Modal title</p>
            <button class="delete" aria-label="close"></button>
          </header> -->
          <section class="modal-card-body">
            <article class="message is-primary">
              <div class="message-body">
                <h2 class="title is-4" style="font-weight: 100;">Thank you for your order.
                  <b>Order #{{order?.orderNumber}}</b>
                </h2>
                <h2 class="title is-4" style="font-weight: 100;">If you have any questions please contact Joe Scrocco at (212)
                  385-4547 or email at <a
                    href="mailto:imatos@diagnosticsolutionsgroup.com">jscrocco@diagnosticsolutionsgroup.com</a></h2>
              </div>
            </article>
          </section>
          <footer class="modal-card-foot">
            <!-- <button class="button is-success">Save changes</button> -->
            <button class="button" (click)="showModal = false">Close</button>
          </footer>
        </div>

      </div>
    </div>
  </aw-wizard-step>
  <aw-wizard-step [navigationSymbol]="{ symbol: '2' }">
    <ng-template awWizardStepTitle let-wizardStep="wizardStep">
      {{ wizardStep.completed ? "PAYMENT (✔)" : "PAYMENT" }}
    </ng-template>
    <div class="columns is-centered is-multiline">
      <div class="column is-6 is-centered">
        <div class="column is-narrow has-text-centered">Payment</div>
        <div class="columns is-centered is-size-4">
          <div class="field is-horizontal is-align-items-center">
            <div class="field-body" [formGroup]="form">
              <div class="field  is-large">
                <div class="control has-icons-right">
                  <label class="checkbox is-size-4">
                    <input type="checkbox" formControlName="addPaymentToInvoice">
                    Payment via the invoice
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="box" [ngStyle]="{'display':form.value.addPaymentToInvoice? 'none': 'block'}">
          <app-payment-form [showCustomerPaymentMethods]="false" [allowUpdateDefaultPaymentMethod]="false"
            [formType]="'card'" [reset$]="resetPaymentMethod$" [allowBankAccounts]="false" [allowCreditCards]="true" [orderId$]="orderId$"
            [amount]="calculateOrderTotal()" (onPaymentCompleted)="paymentCompletedHandler($event)">
          </app-payment-form>
        </div>
      </div>
    </div>
    <div class="columns is-centered">
      <div class="column is-narrow">
        <!--<button class="button is-info m-3" type="button" (click)="wizardComponent?.goToPreviousStep()">Back to the
          Form</button>-->
      </div>
      <div [ngStyle]="{'display':form.value.addPaymentToInvoice? 'block': 'none'}" class="column is-narrow">
        <button class="button is-success m-3" type="button" (click)="submitOrderWithPaymentByInvoice()">
          Submit Order</button>
      </div>
    </div>
  </aw-wizard-step>
  <aw-wizard-step [navigationSymbol]="{ symbol: '3' }">
    <ng-template awWizardStepTitle let-wizardStep="wizardStep">
      {{ wizardComponent?.currentStepIndex == 2 ? "CONFIRMATION (✔)" : "CONFIRMATION" }}
    </ng-template>
    <article class="mt-6">
      <div class=" has-text-centered">
        <h2 class="title is-4" style="font-weight: 100;">Thank you for your order.
          <b>Order #{{order?.orderNumber}}</b>
        </h2>
        <h2 class="title is-4" style="font-weight: 100;">If you have any questions please contact Joe Scrocco at (212)
          385-4547 or email at <a
            href="mailto:jscrocco@diagnosticsolutionsgroup.com">jscrocco@diagnosticsolutionsgroup.com</a></h2>
      </div>
    </article>
    <div class="field is-grouped has-text-centered">
      <div class="control" style="margin: 0 auto">
        <button class="button is-success mt-6" (click)="finish()">Close</button>
      </div>
    </div>
  </aw-wizard-step>
</aw-wizard>