<!-- <h2 class="title is-2 has-text-centered"></h2> -->
<div class="is-title-bar" style="height: 50px;">
  <div class="level">
    <div class="level-left">
      <h1 class="title is-4">Reps List</h1>
    </div>
    <div class="level-right">
      <div class="info" style="margin-right: 120px;">
        <div class="field has-addons">
          <p class="control">
            <span class="select">
              <select [(ngModel)]="searchByField">
                <option value="">Search by...</option>
                <option value="firstName">First Name</option>
                <option value="email">Email</option>
                <option value="cell">Cell</option>
              </select>
            </span>
          </p>
          <p class="control">
            <input class="input" type="text" placeholder="Search term..." [(ngModel)]="searchByValue">
          </p>
          <p class="control">
            <a class="button is-info" (click)="searchBy()">
              Search
            </a>
          </p>
          <p class="control" *ngIf="searched">
            <a class="button is-danger" (click)="clearSearch()">
              Clear Search
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<table class="table is-striped is-hoverable is-fullwidth" *ngIf="repsList">
  <thead>
    <tr>
      <th>Email</th>
      <th>One-Time discount</th>
      <th>Name</th>
      <th>Cell</th>
      <th>City</th>
      <th>State</th>
      <th>Zip</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of repsList">
      <td><a (click)="selectRep(item)">{{item.email}}</a></td>
      <td>
        <div [ngSwitch]="item.allowOneTimeDiscout">
          <div class="has-text-centered" *ngSwitchCase="true">
            <span class="m-2 has-text-weight-bold">YES</span>
            <button class="button is-warning" (click)="removeOneTimeDiscout(item)">Cancel</button>
          </div>
          <div *ngSwitchDefault class="has-text-centered">
            <button class="button is-danger" (click)="allowOneTimeDiscout(item)">Allow</button>
          </div>
        </div>
      </td>
      <td>{{item.firstName + ' ' + item.lastName}}</td>
      <td>+1{{item.cell}}</td>
      <td>{{item.city}}</td>
      <td>{{item.state}}</td>
      <td>{{item.zip}}</td>
    </tr>
    <tr *ngIf="repsList.length <= 0">
      <td colspan="6"><p>No records found</p></td>
    </tr>
  </tbody>
</table>

<app-modal 
  *ngIf="selectedRep !== null"
  [showOkButton]=false
  cancelButtonText="Close"
  (onCancel)="closeProfile()"
>
  <div>
    <h1 class="title is-3">Rep Profile</h1>
    <!-- <a [routerLink]="['/admin/orders-list']" [queryParams]="{email: selectedRep.email}">See rep orders</a> -->
  </div>
  <div class="tabs">
    <ul>
      <li (click)="selectedTab = 'profile'" [ngClass]="{'is-active': selectedTab === 'profile'}"><a>Profile</a></li>
      <li (click)="selectedTab = 'customers'" [ngClass]="{'is-active': selectedTab === 'customers'}"><a>Customers</a></li>
      <li (click)="selectedTab = 'orders'" [ngClass]="{'is-active': selectedTab === 'orders' || selectedTab === 'details'}"><a>Orders</a></li>
    </ul>
  </div>
  <div *ngIf="selectedTab === 'profile' && !!selectedRep">
    <div class="tile is-ancestor">
      <div class="tile is-6 is-parent">
        <div class="tile card is-child notification">
          <table class="table is-fullwidth">
            <tr>
              <td>Full Name:</td>
              <td>{{selectedRep.firstName + ' ' + selectedRep.lastName}}</td>
            </tr>
            <tr>
              <td>Address:</td>
              <td>{{selectedRep.address}}</td>
            </tr>
            <tr>
              <td colspan="2">City: {{selectedRep.city}} / State: {{selectedRep.state}} / Zip: {{selectedRep.zip}}</td>
              <td></td>
            </tr>
            <tr>
              <td>Cell:</td>
              <td>{{selectedRep.cell}}</td>
            </tr>
            <tr>
              <td>Email:</td>
              <td>{{selectedRep.email}}</td>
            <tr>
              <td>Trading-as Nmae or LLC Name:</td>
              <td>{{selectedRep.tradingLlcName}}</td>
            <tr>
              <td>SS# or EIN:</td>
              <td>{{selectedRep.ssnEin}}</td>
            </tr>
            <tr>
              <td>Banking Account:</td>
              <td>{{selectedRep.bankAccountNumber}}</td>
            <tr>
              <td>Banking Routing:</td>
              <td>{{selectedRep.bankRoutingNumber}}</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="tile is-6 is-parent">
        <div class="tile card is-child notification">
          <table class="table is-fullwidth">
            <tr>
              <td>Years in healthcare:</td>
              <td>{{selectedRep.yearsInHealthcare}}</td>
            </tr>
            <tr>
              <td>Level of experience with diagnostics:</td>
              <td>{{selectedRep.experienceDiagnostics}}</td>
            </tr>
            <tr>
              <td>Level of experience with COVID-19:</td>
              <td>{{selectedRep.experienceCovid}}</td>
            </tr>
            <tr>
              <td>USA territory scope:</td>
              <td>{{selectedRep.territoryScope}}</td>
            </tr>
            <tr>
              <td>Types of accounts:</td>
              <td>{{selectedRep.typeAccount.join(', ')}}</td>
            </tr>
            <tr>
              <td>Structure:</td>
              <td>{{selectedRep.structure}}</td>
            </tr>
            <tr>
              <td>Reach:</td>
              <td>{{selectedRep.reach}}</td>
            </tr>
          </table>
        </div>
      </div>
      
    </div>
    <div class="tile is-ancestor">
      <div class="tile is-6 is-parent" *ngIf="selectedRep.regionalTerritoryList.length > 0">
        <div class="tile card is-child notification">
          <h4 class="title is-4">Territories List</h4>
          <ul class="list">
            <li *ngFor="let item of selectedRep.regionalTerritoryList">{{item}}</li>
          </ul>
        </div>
      </div>
      <div class="tile is-6 is-parent" *ngIf="selectedRep.internationalReach.length > 0">
        <div class="tile card is-child notification">
          <h4 class="title is-4">International Reach</h4>
          <ul class="list">
            <li *ngFor="let item of selectedRep.internationalReach">{{item}}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="selectedTab === 'orders' || selectedTab === 'details'">
    <buttton class="button is-small" *ngIf="selectedTab === 'details'" (click)="selectedTab='orders'">
      <span class="icon">
        <i class="fas fa-angle-left"></i>
      </span>
      <span>Back</span>
    </buttton>
    <table class="table is-fullwidth" *ngIf="selectedTab === 'orders'">
      <thead>
        <tr>
          <th>Rep Email</th>
          <th>Order Date</th>
          <th>Ship To</th>
          <th>Shipment Type</th>
          <th>Tests Count</th>
          <th>Rep Margin</th>
          <th>Order Total</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of ordersList">
          <td><a (click)="openOrder(item)" style="cursor: pointer;">{{item.repEmail}}</a></td>
          <td>{{item.date | date}}</td>
          <td>{{item.customer.firstName + ' ' + item.customer.lastName}}</td>
          <td style="font-weight:600" [ngStyle]="{'color': item.shipmentType == 'Single' ? 'green' : 'red'}">{{item.shipmentType}}</td>
          <td>{{item.orderProducts[0].sellingQuantity * 25}}</td>
          <td>{{((item.orderProducts[0].quantity * item.orderProducts[0].sellingQuantity * item.orderProducts[0].sellingPrice) - (item.orderProducts[0].quantity * item.orderProducts[0].sellingQuantity * item.orderProducts[0].cost)) | currency }}</td>
          <td>{{(item.orderProducts[0].quantity * item.orderProducts[0].sellingQuantity * item.orderProducts[0].sellingPrice) | currency }}</td>
          <!-- <td>{{order | json}}</td> -->
        </tr>
        <tr *ngIf="ordersList && ordersList.length <= 0">
          <td colspan="7">No records found</td>
        </tr>
      </tbody>
    </table>

    <div class="container" *ngIf="selectedTab === 'details'">
      <table class="table is-fullwidth">
        <tr>
          <td>
            <h1 class="title is-3">Order Details {{order.id}}</h1>
          </td>
        </tr>
      </table>
      <table class="table is-fullwidth">
        <tr style="background-color: #ccc">
          <th>Order Date</th>
          <th>Shipment Type</th>
          <th>Unit Selling Quantity</th>
        </tr>
        <tr>
          <td>{{order.date | date}}</td>
          <td>{{order.shipmentType}}</td>
          <td>{{order.orderProducts[0].sellingQuantity}}</td>
        </tr>
      </table>
      <table class="table is-fullwidth">
        <tr style="background-color: #ccc">
          <th>Ship To</th>
          <th>Bill To</th>
          <th>Payment</th>
        </tr>
        <tr>
          <td>
            {{order.customer.firstName + ' ' + order.customer.lastName}}<br />
            {{order.shipping.address }}<br />
            {{order.shipping.city + ', ' + order.shipping.state + ' ' + order.shipping.zip }} <br>
            USA<br />
            {{order.shipping.phone}}
            {{order.customer.email}}<br />
            Customer phone:{{order.customer.phone}}
          </td>
          <td>
            {{order.customer.firstName + ' ' + order.customer.lastName}}<br />
            {{order.shipping.address }}<br />
            {{order.shipping.city + ', ' + order.shipping.state + ' ' + order.shipping.zip }} <br>
            USA<br />
            {{order.shipping.phone}}<br />
          </td>
          <td>

          </td>
        </tr>
      </table>
      <table class="table is-fullwidth">
        <tr style="background-color: #ccc">
          <th colspan="2" class="has-text-centered">Product</th>
          <th class="has-text-centered">Qty</th>
          <th class="has-text-centered">Unit Selling Price</th>
          <th class="has-text-centered" style="width: 150px">Total</th>
        </tr>
        <tr *ngFor="let item of order.orderProducts">
          <td class="has-text-centered">
            <!--<img src="../../../assets/{{ item.name.toLowerCase().replace(' ', '_')}}.png" style="width: 250px"/>-->
          </td>
          <td style="vertical-align: middle;" class="has-text-centered">{{item.name}} Box of {{item.quantity}}</td>
          <td style="vertical-align: middle;" class="has-text-centered">{{item.sellingQuantity}}</td>
          <td style="vertical-align: middle;" class="has-text-centered">{{item.sellingPrice | currency}}</td>
          <td style="vertical-align: middle;" class="has-text-centered">{{(item.sellingQuantity * item.sellingPrice * item.quantity) | currency }}</td>
        </tr>
      </table>
    </div>
  </div>
  <div *ngIf="selectedTab === 'customers'">
    <table class="table is-fullwidth" *ngIf="selectedTab === 'customers'">
      <thead>
        <tr>
          <th>Contact Name</th>
          <th>Legal Name</th>
          <th>DBA</th>
          <th>Type</th>
          <th>CLIA #</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of customersList">
          <td>{{ item.billingAddress.firstName + ' ' + item.billingAddress.lastName }}</td>
          <td>{{ item.legalName }}</td>
          <td>{{ item.dba }}</td>
          <td>{{ item.customerType }}</td>
          <td>{{ item.clia }}</td>
        </tr>
        <tr *ngIf="customersList && customersList.length <= 0">
          <td colspan="5">No records found</td>
        </tr>
      </tbody>
    </table>
  </div>
</app-modal>