import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API } from 'aws-amplify';
import { from, Observable, of } from 'rxjs';
import { catchError, retry, switchMap, timeout } from 'rxjs/operators';
import { SpinnerVisibilityService } from 'ng-http-loader';

@Injectable({ providedIn: 'root' })
export class ShippingService {
    

    constructor(private httpClient: HttpClient, private spinner: SpinnerVisibilityService) {
    }

    getRates(form) {
        //console.log(form);
        return of({}).pipe(switchMap(() => from(API.post('CXShipping', '/rates', {body: {
            legalName: form.legalName,
            shippingAddress: form.shippingAddress,
            billingAddress: form.billingAddress,
            package: {
                length:9,
                width: 9,
                height: 6,
                weight: form.order.sellingQuantity > 0 ? (form.order.sellingQuantity/25) : 1,
            }
        }}))),timeout(10000), retry(3));   
    }
}