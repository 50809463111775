<aw-wizard #wizard [disableNavigationBar]="true">
    <aw-wizard-step>
        <app-order-entry-form [nextButtonText]="'Next'" [hideBaseCost]="true" [hideCommission]="true"
        [showOnlyShortProductText]="true" [showAllProducts]="false"
            (orderEntryReady)="onOrderReady($event)"></app-order-entry-form>
    </aw-wizard-step>

    <aw-wizard-step>
        <app-customer-shipping-details #shipDetails
            [allowPickShipmentService]="true"
            [allowPickShippingPayer]="false"
            [isNewCustomer]="true" [nextButtonLabel]="'Next'"
            [orderEntry$]="orderEntry$$" [showCommission]="false" (onShipDetailsReady)="goToPayment($event)">

        </app-customer-shipping-details>
    </aw-wizard-step>

    <aw-wizard-step awOptionalStep>
        <div class="columns is-centered is-multiline">
  
          <div class="column is-6">
  
            <div class="column is-narrow has-text-centered">Payment</div>

            <div class="box">
                <app-payment-form [showCustomerPaymentMethods]="false" 
                [allowUpdateDefaultPaymentMethod]="false"
                [formType]="'card'"
                [reset$]="resetPaymentMethod$"
                [allowBankAccounts]="false"
                [allowCreditCards]="true"
                [amount]="calculateOrderAmount()"
                (onPaymentCompleted)="paymentCompletedHandler($event)">
                </app-payment-form>
            </div>
          </div>
        </div>
  
        <div class="columns is-centered">
          <div class="column is-narrow" *ngIf="(!paymentResults && paymentResults?.success) || !addPaymentToInvoice">
            <button class="button is-info m-3" type="button" [disabled]="order || paymentResults?.success" awPreviousStep>Back To Shipping</button>
          </div>
          <div class="column is-narrow" *ngIf="(paymentResults && paymentResults.success) || addPaymentToInvoice">
            <button class="button is-success m-3" type="button" [awGoToStep]="{ stepIndex: 4 }"
              (click)="submitOrder()">Next</button>
          </div>
        </div>
      </aw-wizard-step>
      <aw-wizard-step awOptionalStep>
        <div class="columns is-centered" *ngIf="!lessThan15000()">
          <div class="column is-6">
            <div class="box">
              <p class="title">Please call (347) 352-6754 for details</p>
          </div>
          </div>
        </div>
        <div class="columns is-centered">
          <div class="column is-narrow">
            <button class="button is-info m-3" type="button" [awGoToStep]="{ stepIndex: 1 }">Back To Shipping</button>
            <button class="button is-success m-3" type="button" (click)="submitOrder()" [awGoToStep]="{ stepIndex: 4 }">Checkout</button>
          </div>
        </div>
      </aw-wizard-step>
      <aw-wizard-step>
        <div class="columns is-centered">
          <div class="column is-narrow">
            <div class="box mt-6">
              THE ORDER HAS BEEN SENT! THANK YOU!
            </div>
          </div>
        </div>
        <div class="columns is-centered">
          <div class="column is-narrow">
            <button class="button is-success m-3" type="button" awResetWizard (finalize)="finish()">CLOSE</button>
          </div>
        </div>
      </aw-wizard-step>
</aw-wizard>