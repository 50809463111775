<div class="is-title-bar" style="height: 50px;">
  <div class="level">
    <div class="level-left">
      <h1 class="title is-4">Admin Module</h1>
    </div>
    <div class="level-right"></div>
  </div>
</div>

<div class="columns">
  <aside class="menu column is-2">
    <p class="menu-label">
      General
    </p>
    <ul class="menu-list">
      <li><a [ngClass]="{'is-active': selectedMenu === 'orders'}" (click)="select('orders')">Orders</a></li>
      <li><a [ngClass]="{'is-active': selectedMenu === 'reps'}" (click)="select('reps')">Reps</a></li>
      <li><a [ngClass]="{'is-active': selectedMenu === 'commissions'}" (click)="select('commissions')">Commissions</a></li>
      <li><a [ngClass]="{'is-active': selectedMenu === 'products'}" (click)="select('products')">Products</a></li>
      <!--<li><a [ngClass]="{'is-active': selectedMenu === 'quickbooks'}" (click)="select('quickbooks')">QuickBooks</a></li>-->
    </ul>
  </aside>

  <div class="column">
    <app-rep-list *ngIf="selectedMenu === 'reps'"></app-rep-list>
    <app-reps-commissions *ngIf="selectedMenu === 'commissions'"></app-reps-commissions>
    <app-order-list *ngIf="selectedMenu === 'orders'"></app-order-list>
    <app-products-list *ngIf="selectedMenu === 'products'"></app-products-list>
    <!--<app-quickbooks-admin *ngIf="selectedMenu === 'quickbooks'"></app-quickbooks-admin>-->
  </div>
</div>
