<div class="login-container">
  <section class="hero is-fullheight">
    <div class="hero-body">
      <div class="container has-text-centered">
        <div class="column is-6 is-offset-3">
          <h3 class="title has-text-grey">Login</h3>
          <p class="subtitle has-text-grey">Please login to proceed.</p>
          <div class="box">
            <form name="form">
              <div class="field">
                <div class="control has-icons-left has-icons-right">
                  <input type="text" class="input is-large" name="userName" [(ngModel)]="userName" required
                    placeholder="Your Email" autofocus="" />
                  <span class="icon is-small is-left">
                    <i class="fa fa-user"></i>
                  </span>
                  <!-- <div *ngIf="f.submitted && !userName.valid" class="help is-danger">Username is required</div> -->
                </div>
              </div>
              <div class="field has-addons">
                <div class="control is-expanded has-icons-left has-icons-right">
                  <input class="input" type="{{ showPassword ? 'password' : 'text' }}" placeholder="Password"
                    [(ngModel)]="password" name="password" required>
                    <span class="icon is-left">
                      <i class="fa fa-lock"></i>
                    </span>
                </div>
       
                <p class="control">
                  <a class="button">
                    <!-- @gmail.com -->
            
                    <span class="icon is-right" (click)="showPassword = !showPassword"
                      style=" color: black; cursor: pointer; z-index: 0;">
                      <i class="fas" [ngClass]="{'fa-eye': !showPassword, 'fa-eye-slash': showPassword}"></i>
                    </span>
                  </a>
                </p>
              </div>
              <!--  <div class="field">
                  <div class="control has-icons-left has-icons-right">
                    <input type="password" class="input is-large" name="password" [(ngModel)]="password" placeholder="Your Password" required />
                    
                    <span class="icon is-small is-left">
                      <i class="fa fa-lock"></i>
                    </span>

                  </div>
                </div>-->
              <button [disabled]="loading" class="button is-block is-primary is-large is-fullwidth"
                [ngClass]="loading && 'is-loading'" (click)="loading = !loading;loginWithCognito()">Login</button>
            </form>

          </div>
          <p class="has-text-grey">
            <a [routerLink]="['/rep/register']">Sign up</a> &nbsp;·&nbsp;
            <a (click)="showForgotPassword = !showForgotPassword">Forgot Password</a>
            <!-- &nbsp;·&nbsp; -->
            <!-- <a href="../">Need Help?</a> -->
          </p>
        </div>
      </div>
    </div>
  </section>
</div>

<div class="modal is-active" *ngIf="showForgotPassword">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Forgot password</p>
    </header>
    <section class="modal-card-body">
      <div class="box" *ngIf="!showNextForgotPassword">
        <form name="form">
          <p>We will send you a validation code to your email which you will use in the next screen.</p>
          <div class="field">
            <div class="control has-icons-left has-icons-right">
              <input type="text" class="input is-large" name="userName" [(ngModel)]="userName" required
                placeholder="Your Email" autofocus="" />
              <span class="icon is-small is-left">
                <i class="fa fa-lock"></i>
              </span>
              <!-- <div *ngIf="f.submitted && !userName.valid" class="help is-danger">Username is required</div> -->
            </div>
          </div>
        </form>
      </div>
      <div class="box" *ngIf="showNextForgotPassword">
        <form name="form">
          <div class="field">
            <div class="control has-icons-left has-icons-right">
              <input type="text" class="input is-large" name="userName" [(ngModel)]="userName" required
                placeholder="Your Email" autofocus="" />
              <span class="icon is-small is-left">
                <i class="fa fa-lock"></i>
              </span>
            </div>
          </div>

          <div class="field">
            <div class="control has-icons-left has-icons-right">
              <input type="password" class="input is-large" name="newPassword" [(ngModel)]="newPassword" required
                placeholder="New Password" autofocus="" />
              <span class="icon is-small is-left">
                <i class="fa fa-lock"></i>
              </span>
            </div>
          </div>

          <div class="field">
            <div class="control has-icons-left has-icons-right">
              <input type="password" class="input is-large" name="validationCode" [(ngModel)]="validationCode"
                placeholder="Validation code..." required />
              <span class="icon is-small is-left">
                <i class="fa fa-phone"></i>
              </span>
              <!-- <div *ngIf="f.submitted && !password.valid" class="help is-danger">Password is required</div>
              <div *ngIf="errorMessage !== ''" class="help is-danger">{{errorMessage}}</div> -->
            </div>
          </div>
        </form>
      </div>
    </section>
    <footer class="modal-card-foot">
      <button class="button is-success" (click)="forgotPasswordSendEmail()" *ngIf="!showNextForgotPassword">Send
        Email</button>
        <button class="button is-success" (click)="forgotPasswordSendEmail()" *ngIf="showResendCode">Resend
          new code</button>
      <button class="button is-success" (click)="forgotPassword()" *ngIf="showNextForgotPassword">Save changes</button>
      <!-- <button class="button">Cancel</button> -->
    </footer>
  </div>
</div>

<div class="modal is-active" *ngIf="showNewPassword">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Change your password</p>
      <!-- <button class="delete" aria-label="close"></button> -->
    </header>
    <section class="modal-card-body">
      <div class="box">
        <form name="form">
          <div class="field">
            <div class="control has-icons-left has-icons-right">
              <input type="password" class="input is-large" name="userName" [(ngModel)]="newPassword" required
                placeholder="Your Password" autofocus="" />
              <span class="icon is-small is-left">
                <i class="fa fa-lock"></i>
              </span>
            </div>
          </div>

          <div class="field">
            <div class="control has-icons-left has-icons-right">
              <input type="password" class="input is-large" name="password" [(ngModel)]="newPasswordConfirm"
                placeholder="Confirm Password" required />
              <span class="icon is-small is-left">
                <i class="fa fa-lock"></i>
              </span>
              <!-- <div *ngIf="f.submitted && !password.valid" class="help is-danger">Password is required</div>
              <div *ngIf="errorMessage !== ''" class="help is-danger">{{errorMessage}}</div> -->
            </div>
          </div>
        </form>
      </div>
    </section>
    <footer class="modal-card-foot">
      <button class="button is-success" (click)="updatePassword()">Save changes</button>
      <!-- <button class="button">Cancel</button> -->
    </footer>
  </div>
</div>

<div class="modal is-active" *ngIf="showSMSMFA">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Two-Factor Validation</p>
      <!-- <button class="delete" aria-label="close"></button> -->
    </header>
    <section class="modal-card-body">
      <div class="box">
        <form name="form">
          <div class="field">
            <div class="control has-icons-left has-icons-right">
              <p>We sent you an SMS to: {{phoneNumber}}</p>
            </div>
          </div>

          <div class="field">
            <div class="control has-icons-left has-icons-right">
              <input type="password" class="input is-large" name="password" [(ngModel)]="validationCode"
                placeholder="Validation code..." required />
              <span class="icon is-small is-left">
                <i class="fa fa-phone"></i>
              </span>
              <!-- <div *ngIf="f.submitted && !password.valid" class="help is-danger">Password is required</div>
              <div *ngIf="errorMessage !== ''" class="help is-danger">{{errorMessage}}</div> -->
            </div>
          </div>
        </form>
      </div>
    </section>
    <footer class="modal-card-foot">
      <button class="button is-success" (click)="validateSMS()">Validate Code</button>
      <!-- <button class="button">Cancel</button> -->
    </footer>
  </div>
</div>