<div class="columns is-centered" [formGroup]="form">
    <div class="column gray-back">
        <div class="columns is-centered">
            <div class="column  mt-6 mx-6">
                <h1 class="title is-4 expedia-font">Billing Information</h1>
                <div>
                    <div class="field is-horizontal">
                        <div class="field-body">
                            <div class="field">
                                <div class="control  has-icons-right is-expanded">
                                    <input class="input" type="text" placeholder="Legal Name"
                                        formControlName="legalName">
                                    <span class="icon is-small is-right icon-red" *ngIf="isInvalid('legalName')">
                                        <i class="fas fa-exclamation-triangle"></i>
                                    </span>
                                    <span class="icon is-small is-right icon-green" *ngIf="!isInvalid('legalName')">
                                        <i class="fas fa-check"></i>
                                    </span>
                                    <p class="help is-danger" *ngIf="isInvalid('legalName')" class="help is-danger">
                                        Legal name is required</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="field is-horizontal">
                        <div class="field-body">
                            <div class="field" [formGroupName]="'billingAddress'">
                                <div class="control  has-icons-right is-expanded">
                                    <input class="input" type="text" placeholder="Title" formControlName="title">
                                    <span class="icon is-small is-right icon-red"
                                        *ngIf="isInvalid('billingAddress.title')">
                                        <i class="fas fa-exclamation-triangle"></i>
                                    </span>
                                    <span class="icon is-small is-right icon-green"
                                        *ngIf="!isInvalid('billingAddress.title')">
                                        <i class="fas fa-check"></i>
                                    </span>
                                    <p class="help is-danger" *ngIf="isInvalid('billingAddress.title')"
                                        class="help is-danger">
                                        Title is required</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="field is-horizontal" [formGroupName]="'billingAddress'">
                        <div class="field-body">
                            <div class="field">
                                <div class="control has-icons-right">
                                    <input class="input" type="text" placeholder="First name"
                                        formControlName="firstName">
                                    <span class="icon is-small is-right icon-red"
                                        *ngIf="isInvalid('billingAddress.firstName')">
                                        <i class="fas fa-exclamation-triangle"></i>
                                    </span>
                                    <span class="icon is-small is-right icon-green"
                                        *ngIf="!isInvalid('billingAddress.firstName')">
                                        <i class="fas fa-check"></i>
                                    </span>
                                </div>
                                <p class="help is-danger" *ngIf="isInvalid('billingAddress.firstName')"
                                    class="help is-danger">
                                    First name is required</p>
                            </div>
                            <div class="field">
                                <div class="control has-icons-right">
                                    <input class="input" type="text" placeholder="Last name" formControlName="lastName">
                                    <span class="icon is-small is-right icon-red"
                                        *ngIf="isInvalid('billingAddress.lastName')">
                                        <i class="fas fa-exclamation-triangle"></i>
                                    </span>
                                    <span class="icon is-small is-right icon-green"
                                        *ngIf="!isInvalid('billingAddress.lastName')">
                                        <i class="fas fa-check"></i>
                                    </span>
                                </div>
                                <p class="help is-danger" *ngIf="isInvalid('billingAddress.lastName')"
                                    class="help is-danger">
                                    Last name is required</p>
                            </div>
                        </div>
                    </div>
                    <div class="field is-horizontal" [formGroupName]="'billingAddress'">
                        <div class="field-body">
                            <div class="field">
                                <div class="control has-icons-right is-expanded">
                                    <input class="input" type="text" formControlName="address"
                                        placeholder="305 Main Street" />
                                    <span class="icon is-small is-right icon-red"
                                        *ngIf="isInvalid('billingAddress.address')">
                                        <i class="fas fa-exclamation-triangle"></i>
                                    </span>
                                    <span class="icon is-small is-right icon-green"
                                        *ngIf="!isInvalid('billingAddress.address')">
                                        <i class="fas fa-check"></i>
                                    </span>
                                    <p class="help is-danger" *ngIf="isInvalid('billingAddress.address')"
                                        class="help is-danger">
                                        Address is required</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="field is-horizontal" [formGroupName]="'billingAddress'">
                        <div class="field-body">
                            <div class="field">
                                <div class="control has-icons-right is-expanded">
                                    <input class="input" type="text" readonly formControlName="city"
                                        placeholder="New York">
                                    <span class="icon is-small is-right icon-red"
                                        *ngIf="isInvalid('billingAddress.city')">
                                        <i class="fas fa-exclamation-triangle"></i>
                                    </span>
                                    <span class="icon is-small is-right icon-green"
                                        *ngIf="!isInvalid('billingAddress.city')">
                                        <i class="fas fa-check"></i>
                                    </span>
                                </div>
                            </div>
                            <div class="field">
                                <div class="control has-icons-right is-expanded">
                                    <input class="input" type="text" formControlName="state" placeholder="NY">
                                    <span class="icon is-small is-right icon-red"
                                        *ngIf="isInvalid('billingAddress.state')">
                                        <i class="fas fa-exclamation-triangle"></i>
                                    </span>
                                    <span class="icon is-small is-right icon-green"
                                        *ngIf="!isInvalid('billingAddress.state')">
                                        <i class="fas fa-check"></i>
                                    </span>
                                </div>
                            </div>
                            <div class="field">
                                <div class="control has-icons-right is-expanded">
                                    <input class="input" type="text" formControlName="zip" [mask]="'00000'"
                                        placeholder="ZIP"
                                        (input)="checkZipCode('billingAddress.zip', 'billingAddress.city', 'billingAddress.state')">
                                    <span class="icon is-small is-right icon-red"
                                        *ngIf="isInvalid('billingAddress.zip')">
                                        <i class="fas fa-exclamation-triangle"></i>
                                    </span>
                                    <span class="icon is-small is-right icon-green"
                                        *ngIf="!isInvalid('billingAddress.zip')">
                                        <i class="fas fa-check"></i>
                                    </span>
                                    <p class="help is-danger" *ngIf="isInvalid('billingAddress.zip')"
                                        class="help is-danger">
                                        ZIP is required</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="field is-horizontal">
                        <div class="field-body">
                            <div class="field">
                                <div class="control has-icons-right is-expanded">
                                    <input class="input" type="text" formControlName="clia" placeholder="CLIA #">
                                    <span class="icon is-small is-right icon-red" *ngIf="isInvalid('clia')">
                                        <i class="fas fa-exclamation-triangle"></i>
                                    </span>
                                    <span class="icon is-small is-right icon-green" *ngIf="!isInvalid('clia')">
                                        <i class="fas fa-check"></i>
                                    </span>
                                    <p class="help is-danger" *ngIf="isInvalid('clia')" class="help is-danger">
                                        CLIA # is required</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="field is-horizontal" [formGroupName]="'billingAddress'">
                        <div class="field-body">
                            <div class="field">
                                <div class="control has-icons-right is-expanded">
                                    <input class="input" type="text" formControlName="phone"
                                        placeholder="(212) 123-4567" [mask]="'(000) 000-0000'">
                                    <span class="icon is-small is-right icon-red"
                                        *ngIf="isInvalid('billingAddress.phone')">
                                        <i class="fas fa-exclamation-triangle"></i>
                                    </span>
                                    <span class="icon is-small is-right icon-green"
                                        *ngIf="!isInvalid('billingAddress.phone')">
                                        <i class="fas fa-check"></i>
                                    </span>
                                    <p class="help is-danger" *ngIf="isInvalid('billingAddress.phone')"
                                        class="help is-danger">
                                        Phone is required</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="field is-horizontal" [formGroupName]="'billingAddress'">
                        <div class="field-body">
                            <div class="field">
                                <div class="control has-icons-right is-expanded">
                                    <input class="input" type="text" [textMask]="{mask: mask}" formControlName="email">
                                    <span class="icon is-small is-right icon-red"
                                        *ngIf="isInvalid('billingAddress.email')">
                                        <i class="fas fa-exclamation-triangle"></i>
                                    </span>
                                    <span class="icon is-small is-right icon-green"
                                        *ngIf="!isInvalid('billingAddress.email')">
                                        <i class="fas fa-check"></i>
                                    </span>
                                    <p class="help is-danger" *ngIf="isInvalid('billingAddress.email')"
                                        class="help is-danger">
                                        Email is required</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column mt-6 mx-6">
                <h1 class="title is-4">Shipping Information</h1>
                <div>
                    <div class="field is-horizontal" *ngIf="showUpdateShippingCheckbox">
                        <div class="field-body">
                            <div class="field">
                                <div class="control has-icons-right">
                                    <label class="checkbox">
                                        <input type="checkbox"
                                            [attr.checked]="confirmUpdateShipping ? 'checked' : null">
                                        Make this as the new default customer shipping address
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div [formGroupName]="'shippingAddress'">
                        <div class="field is-horizontal">
                            <div class="field-body">
                                <div class="field">
                                    <div class="control has-icons-right">
                                        <input class="input" type="text" placeholder="First name"
                                            formControlName="firstName">
                                        <span class="icon is-small is-right icon-red"
                                            *ngIf="isInvalid('shippingAddress.firstName')">
                                            <i class="fas fa-exclamation-triangle"></i>
                                        </span>
                                        <span class="icon is-small is-right icon-green"
                                            *ngIf="!isInvalid('shippingAddress.firstName')">
                                            <i class="fas fa-check"></i>
                                        </span>
                                    </div>
                                    <p class="help is-danger" *ngIf="isInvalid('shippingAddress.firstName')"
                                        class="help is-danger">
                                        First name is required</p>
                                </div>
                                <div class="field">
                                    <div class="control has-icons-right">
                                        <input class="input" type="text" placeholder="Last name"
                                            formControlName="lastName">
                                        <span class="icon is-small is-right icon-red"
                                            *ngIf="isInvalid('shippingAddress.lastName')">
                                            <i class="fas fa-exclamation-triangle"></i>
                                        </span>
                                        <span class="icon is-small is-right icon-green"
                                            *ngIf="!isInvalid('shippingAddress.lastName')">
                                            <i class="fas fa-check"></i>
                                        </span>
                                    </div>
                                    <p class="help is-danger" *ngIf="isInvalid('shippingAddress.lastName')"
                                        class="help is-danger">
                                        Last name is required</p>
                                </div>
                            </div>
                        </div>
                        <div class="field">
                            <div class="control has-icons-right">
                                <input class="input" type="text" placeholder="Address" formControlName="address">
                                <span class="icon is-small is-right icon-red"
                                    *ngIf="isInvalid('shippingAddress.address')">
                                    <i class="fas fa-exclamation-triangle"></i>
                                </span>
                                <span class="icon is-small is-right icon-green"
                                    *ngIf="!isInvalid('shippingAddress.address')">
                                    <i class="fas fa-check"></i>
                                </span>
                            </div>
                            <p class="help is-danger" *ngIf="isInvalid('shippingAddress.address')"
                                class="help is-danger">
                                Adress
                                is required</p>
                        </div>
                        <div class="field is-horizontal">
                            <div class="field-body">
                                <div class="field">
                                    <div class="control has-icons-right">
                                        <input class="input" type="text" readonly placeholder="City"
                                            formControlName="city">
                                        <span class="icon is-small is-right icon-red"
                                            *ngIf="isInvalid('shippingAddress.city')">
                                            <i class="fas fa-exclamation-triangle"></i>
                                        </span>
                                        <span class="icon is-small is-right icon-green"
                                            *ngIf="!isInvalid('shippingAddress.city')">
                                            <i class="fas fa-check"></i>
                                        </span>
                                    </div>
                                    <p class="help is-danger" *ngIf="isInvalid('shippingAddress.city')"
                                        class="help is-danger">
                                        City
                                        is required</p>
                                </div>
                                <div class="field">
                                    <div class="control has-icons-right">
                                        <input class="input" type="text" readonly placeholder="State"
                                            formControlName="state">
                                        <span class="icon is-small is-right icon-red"
                                            *ngIf="isInvalid('shippingAddress.state')">
                                            <i class="fas fa-exclamation-triangle"></i>
                                        </span>
                                        <span class="icon is-small is-right icon-green"
                                            *ngIf="!isInvalid('shippingAddress.state')">
                                            <i class="fas fa-check"></i>
                                        </span>
                                    </div>
                                    <p class="help is-danger" *ngIf="isInvalid('shippingAddress.state')"
                                        class="help is-danger">
                                        State is required</p>
                                </div>
                                <div class="field">
                                    <div class="control has-icons-right">
                                        <input class="input" type="text" [mask]="'00000'" placeholder="ZIP"
                                            formControlName="zip"
                                            (input)="checkZipCode('shippingAddress.zip', 'shippingAddress.city', 'shippingAddress.state')">
                                        <span class="icon is-small is-right icon-red"
                                            *ngIf="isInvalid('shippingAddress.zip')">
                                            <i class="fas fa-exclamation-triangle"></i>
                                        </span>
                                        <span class="icon is-small is-right icon-green"
                                            *ngIf="!isInvalid('shippingAddress.zip')">
                                            <i class="fas fa-check"></i>
                                        </span>
                                    </div>
                                    <p class="help is-danger" *ngIf="isInvalid('shippingAddress.zip')"
                                        class="help is-danger">
                                        ZIP
                                        is required</p>
                                </div>

                            </div>

                        </div>
                        <div class="field">
                            <div class="control has-icons-right">
                                <input class="input" type="text" placeholder="Receiving Phone Number"
                                    formControlName="phone" [mask]="'(000) 000-0000'">
                                <span class="icon is-small is-right icon-red"
                                    *ngIf="isInvalid('shippingAddress.phone')">
                                    <i class="fas fa-exclamation-triangle"></i>
                                </span>
                                <span class="icon is-small is-right icon-green"
                                    *ngIf="!isInvalid('shippingAddress.phone')">
                                    <i class="fas fa-check"></i>
                                </span>
                            </div>
                            <p class="help is-danger" *ngIf="isInvalid('shippingAddress.phone')" class="help is-danger">
                                Phone
                                is required</p>
                        </div>
                        <div class="field is-horizontal" *ngIf="!isNewCustomer">
                            <div class="field-label">
                                <label class="label">Receiving Hours</label>
                            </div>
                            <div class="field-body">
                                <div class="field">
                                    <div class="control has-icons-right">
                                        <label class="radio">
                                            <input type="radio" formControlName="hours" [value]="'9-5 (5)'">
                                            9-5 (5)
                                        </label>
                                        <label class="radio">
                                            <input type="radio" formControlName="hours" [value]="'24 Hours (5)'">
                                            24 Hours (5)
                                        </label>
                                        <label class="radio">
                                            <input type="radio" formControlName="hours"
                                                [value]="'24 Hours (6 - Saturday)'">
                                            24 Hours (6 - Saturday)
                                        </label>
                                        <span class="icon is-small is-right icon-red"
                                            *ngIf="isInvalid('shippingAddress.hours')">
                                            <i class="fas fa-exclamation-triangle"></i>
                                        </span>
                                        <span class="icon is-small is-right icon-green"
                                            *ngIf="!isInvalid('shippingAddress.hours')">
                                            <i class="fas fa-check"></i>
                                        </span>
                                    </div>
                                    <p class="help is-danger" *ngIf="isInvalid('shippingAddress.hours')"
                                        class="help is-danger">Hours
                                        are required</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="form.get('shippingAddress').valid">
            <div *ngIf="false" class="columns is-centered m-6 is-size-4">
                <div class="field is-horizontal is-align-items-center">
                    <span class="icon is-small is-right icon-red" *ngIf="isInvalid('shipmentType')">
                        <i class="fas fa-exclamation-triangle"></i>
                    </span>
                    <span class="icon is-small is-right icon-green" *ngIf="!isInvalid('shipmentType')">
                        <i class="fas fa-check"></i>
                    </span>
                    <div class="field-label">
                        <label class="label" style="width:200px">Shipment Type</label>
                    </div>
                    <div class="field-body">
                        <div class="field  is-large">
                            <div class="control has-icons-right">
                                <label class="radio is-size-4">
                                    <input type="radio" formControlName="shipmentType" name="shipmentType"
                                        [value]="'Single'">
                                    Single
                                </label>
                                <label class="radio  is-size-4">
                                    <input type="radio" formControlName="shipmentType" name="shipmentType" disabled
                                        [value]="'Multiple'">
                                    Multiple
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="allowPickShippingPayer" class="columns is-centered m-6">
                <div class="field is-horizontal is-align-items-center">
                    <span class="icon is-small is-left icon-red" *ngIf="isInvalid('billShipmentTo')">
                        <i class="fas fa-exclamation-triangle"></i>
                    </span>
                    <span class="icon is-small is-left icon-green" *ngIf="!isInvalid('billShipmentTo')">
                        <i class="fas fa-check"></i>
                    </span>
                    <div class="field-body">
                        <div class="field">
                            <div class="control has-icons-left">
                                <label class="radio is-size-3">
                                    <input type="radio" formControlName="billShipmentTo" name="billShipmentTo"
                                        [value]="'customer'">
                                    Add shipping to invoice
                                </label>
                                <label class="radio is-size-3">
                                    <input type="radio" formControlName="billShipmentTo" name="billShipmentTo"
                                        [value]="'rep'">
                                    I will pay shipping out of my commission
                                </label>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="columns is-centered">
                <div class="column is-narrow">
                    <article class="message is-info">
                        <div class="message-header">
                          <p>Info</p>
                        </div>
                        <div class="message-body">
                          <strong>If you require another shipping option please call 347 352 6754</strong>
                        </div>
                      </article>
                </div>
            </div>
            <div *ngIf="allowPickShipmentService" class="columns is-centered m-6">
                <div *ngIf="rates$|async as rates" class="column is-narrow">
                    <div *ngIf="rates.error">
                        Failed to fetch rates:
                        <button class="button is-info" (click)="getRates()">Try again</button>
                    </div>
                    <div *ngIf="rates.length == 0">No available delivery time slots. Please call your DSG specialist at

                        (347) 352-6754</div>
                    <div class="control">
                        <span class="icon is-small is-left icon-red" *ngIf="isInvalid('shipmentRate')">
                            <i class="fas fa-exclamation-triangle"></i>
                        </span>
                        <span class="icon is-small is-left icon-green" *ngIf="!isInvalid('shipmentRate')">
                            <i class="fas fa-check"></i>
                        </span>
                    </div>
                    <table class="table" *ngIf="!rates.error && rates.length > 0">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Service</th>
                                <th>Estimated Date</th>
                                <th>Shipping Cost</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let rate of rates">
                                <td>
                                    <label class="radio">
                                        <input type="radio" formControlName="shipmentRate" name="shipmentRate"
                                            [value]="rate">

                                    </label>
                                </td>
                                <td>{{rate.serviceType}}</td>
                                <td>{{rate.estimatedDeliveryDate| date:'EEEE, MMMM d, y, h:mm:ss a'}}</td>
                                <td>{{rate.shippingAmount.amount | currency}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="column is-narrow">
                    <div class="box">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Order Summary</th>
                                    <th></th>
                                    <th *ngIf="form.value.billShipmentTo == 'customer'"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Product Total:</td>
                                    <td>{{orderEntry?.orderExtension|currency}}</td>
                                </tr>
                                <tr *ngIf="form.value.billShipmentTo == 'customer'">
                                    <td>Freight Charges:</td>
                                    <td>+{{form.value.shipmentRate?.shippingAmount?.amount|currency}}</td>
                                </tr>
                                <tr class="has-text-weight-bold">
                                    <td>Order Total:</td>
                                    <td> {{round((orderEntry?.orderExtension + (form.value.billShipmentTo == 'customer'
                                        ?
                                        form.value.shipmentRate?.shippingAmount?.amount : 0)), 0)|currency}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="box" *ngIf="showCommission">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Commission Summary</th>
                                    <th></th>
                                    <th *ngIf="form.value.billShipmentTo == 'rep'"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Order Total:</td>
                                    <td>{{orderEntry?.orderExtension|currency}}</td>
                                </tr>
                                <tr>
                                    <td>Base Cost Total:</td>
                                    <td>-{{orderEntry?.orderBaseCost|currency}}</td>
                                </tr>
                                <tr *ngIf="form.value.billShipmentTo == 'rep'">
                                    <td>Freight Charges:</td>
                                    <td>-{{form.value.shipmentRate?.shippingAmount?.amount|currency}}</td>
                                </tr>
                                <tr class="has-text-weight-bold">
                                    <td>Commission Earned:</td>
                                    <td> {{round((orderEntry?.orderCommission - (form.value.billShipmentTo == 'rep' ?
                                        form.value.shipmentRate?.shippingAmount?.amount : 0)), 0)|currency}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<div class="columns is-centered">
    <div class="column is-narrow">
        <button class="button is-info m-3" type="button" awPreviousStep>Back To Order Entry</button>
        <button class="button is-success m-3" type="button" [disabled]="!canGoNext()"
            (click)="next()">{{nextButtonLabel}}</button>
    </div>
</div>

<app-modal 
  *ngIf="toggleWarning"
  [showOkButton]="true"
  [showCancelButton]="false"
  [okButtonText]="'OK'"
  (onOk)="toggleWarning = false"
>
<strong>Please note: Add 2-3 days for shipping time; No ground shipments allowed; Only 2 Day Shipping.</strong>
</app-modal>