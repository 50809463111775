import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { Subject, Observable } from 'rxjs';
import { tap, takeUntil } from 'rxjs/operators';
import { BillingAddress, PaymentMethod } from 'src/app/models/customer.model';
import { PaymentsService } from 'src/app/services/payments.service';
import { BasePaymentComponent } from './base-payment.component';

@Component({
  selector: 'app-base-payment-methods',
  templateUrl: './base-payment-methods.component.html',
  styleUrls: ['./base-payment-methods.component.scss']
})
export class BasePaymentMethodsComponent extends BasePaymentComponent implements OnInit, OnDestroy {

  @Input() newMethodLabel = '';
  @Input() actionButtonLabel = '';

  @Input() showCancelButton = true;
  @Input() cardCodeDisabled = true;
  @Input() showActionButton = false;
  @Input() showDeleteButton = false;
  @Input() position: 'middle'| 'bottom';
  @Input() resetExistingPaymentMethod$ = new Subject<void>();
  @Input() showCustomerPaymentMethods = true;
  @Input() allowUpdateDefaultPaymentMethod = true;
  @Input() formType = null;

  @Output() onPaymentMethodChange = new EventEmitter<any>();
  @Output() onActionClicked = new EventEmitter<any>();


  private isLoading = false;
  
  constructor(protected paymentsService: PaymentsService, protected spinner: SpinnerVisibilityService) {
    super(spinner);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  onPaymentMethodSelectedOrChanged(paymentMethod) {
    this.onPaymentMethodChange.emit(paymentMethod);
  }

  onActionButtonClick() {
    this.onActionClicked.emit();
  }

  canShowActionButton() {
    return this.showActionButton;
  }
}
