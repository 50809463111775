import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';


@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private usersList;

  constructor() { }

  async getCurrentUser(): Promise<CognitoUser> {

    try {
      return await Auth.currentAuthenticatedUser();
    } catch (error) {
      return undefined;
    }
  }

  async getCurrentUserEmail() {
    const user = await this.getCurrentUser();
    if (user) {
      return user.getSignInUserSession().getIdToken().payload['email'];
    }
    return null;
  }

  async getCurrentUserGroups() {
    const user = await this.getCurrentUser();
    if (!user) return [];
    const group = <string[]>user.getSignInUserSession().getIdToken().payload["cognito:groups"];
    return group ? group : [];
  }

  async isAdmin() {
    return await (await this.getCurrentUserGroups()).includes('Admin');
  }
}
