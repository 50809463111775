import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { WizardComponent } from 'angular-archwizard';
import { EMPTY, Observable, of, Subject } from 'rxjs';

import { FormService } from '../../services/form.service';
import { EmailService } from 'src/app/services/email.service';
import { AbstractControl, AsyncValidatorFn, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { catchError, debounceTime, delay, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ProductsService } from 'src/app/services/products.service';
import { Product } from 'src/app/models/product.model';
import { ShippingService } from 'src/app/services/shipping.service';
import { CmsDataService } from 'src/app/services/cms-data.service';
import * as moment from 'moment';
import { OrdersService } from 'src/app/services/orders.service';
import { ProductInformation } from 'src/app/models/view/product-information.viewmodel';
import { createMask } from '@ngneat/input-mask';

@Component({
  selector: 'app-hcp-order-form',
  templateUrl: './hcp-order-form.component.html',
  styleUrls: ['./hcp-order-form.component.scss']
})
export class HcpOrderFormComponent implements OnInit {
  @ViewChild('wizard') wizardComponent: WizardComponent;
  numberInputMask = createMask('9{1,3}');
  
  form = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl('', [Validators.required, Validators.pattern('[0-9]{10}')]),
    clia: new FormControl('', { validators: [Validators.required, Validators.minLength(10), Validators.maxLength(10)], asyncValidators: [this.validateClia(this.cmsDataService)] }),
    //billingAddress: new FormGroup({
    //  address: new FormControl('', [Validators.required]),
    //  city: new FormControl('', [Validators.required]),
    //  state: new FormControl('', [Validators.required]),
    //  zip: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(5)]),
    //}),
    shippingAddress: new FormGroup({
      address: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      zip: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(5)], this.validateZip(this.formService)),
    }),
    addPaymentToInvoice: new FormControl(false),
    shipmentRate: new FormControl(undefined, { validators: [Validators.required] }),
  });

  showModal = false;
  isSending = false;

  addPaymentToInvoice = false;
  resetPaymentMethod$ = new Subject<void>();
  orderId$ = new Subject<string>();
  destroy$ = new Subject<void>();
  products = [{
    image: "/assets/status_hcpform.png",
    itemsPerBox: 25,
    name: "Status COVID-19 + Flu A/B at $14.95 ($373.75 per box of 25)",
    productName: "Status COVID-19 + Flu A/B",
    baseCost: 14.95,
    sellingPrice: 14.95,
    minQuantity: 25,
    sellingQuantity: 0,
    wmsSku: "Status COVID & Flu A/B"
  },
  {
    image: "/assets/sienna.png",
    itemsPerBox: 25,
    name: "Sienna COVID-19 at $4.95 ($123.75 per box of 25)",
    productName: "Sienna COVID-19",
    baseCost: 4.95,
    sellingPrice: 4.95,
    sellingQuantity: 0,
    minQuantity: 25,
    wmsSku: "Sienna-Clarity COVID-19"
  },
  {
    image: "/assets/quickview-rsv.png",
    itemsPerBox: 25,
    name: "QuickView RSV at $12.50 ($312.50 per box of 25)",
    productName: "QuickView RSV",
    baseCost: 12.50,
    sellingPrice: 12.50,
    sellingQuantity: 0,
    minQuantity: 25,
    wmsSku: ""
  }];
  rates$: Observable<any>;
  dsgWebsiteUrl = "https://diagnosticsolutionsgroup.com/";
  order: any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formService: FormService,
    private shippingService: ShippingService,
    private spinner: SpinnerVisibilityService,
    private cmsDataService: CmsDataService,
    private ordersService: OrdersService
  ) { }

  ngOnInit(): void {
    this.form.reset();
    this.order = null;
    this.activatedRoute.params.subscribe(async params => {
      this.spinner.show();
      if (!params.email) {
        window.location.href = this.dsgWebsiteUrl;
        return;
      } else {
        this.form.get('email').setValue(params.email);
        const details = await this.ordersService.getHcpDetails(params.email);
        if(details){
          this.form.get('firstName').setValue(details.firstName);
          this.form.get('lastName').setValue(details.lastName);
          this.form.get('shippingAddress.zip').setValue(details.zip);
          this.form.get('shippingAddress.address').setValue(details.address);
        }
      }
      this.spinner.hide();

    });

    this.form.get('shippingAddress').statusChanges.pipe(debounceTime(1000)).subscribe({
      next: status => {
        if(this.form.get('shippingAddress').valid) {
          this.getRates();
        } else {
          this.form.get('shipmentRate').patchValue(undefined);
        }
      }
    })
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
  stripDecimalSeparator(event, product) {
    if((event.key == '.') || (event.key == ",") || (event.key == "-")) {
      event.preventDefault();
      return false;
    }

    //event.target.value = event.target.value.replace(".", "").replace(",", "");

  }

  getRates() {
    const form = this.form.getRawValue();
    if (!this.form.get('shippingAddress').valid || this.getTotalSellingQuantity() == 0) {
      this.rates$ = EMPTY;
      return;
    }

    this.form.get('shipmentRate').reset();
    this.rates$ = of({}).pipe(
      debounceTime(1000),
      switchMap(o => {
        this.spinner.show();
        return this.shippingService.getRates({
        legalName: form.legalName,
        shippingAddress: form.shippingAddress,
        billingAddress: form.shippingAddress,
        order: { sellingQuantity: this.getTotalSellingQuantity() }
      })}),
      map(r => r['rateResponse'].rates.sort((a, b) => a.estimatedDeliveryDate > b.estimatedDeliveryDate ? 1 : -1)),
      map(r => {
        r.forEach(rate => {
          const baseCost = rate?.otherAmount?.amount + rate?.shippingAmount?.amount;
          rate.shippingAmount.amount = baseCost + baseCost * 0.1;
        });
        return r;
      }),
      map(rates => rates.filter(r => r.serviceCode.includes("fedex_2day") || r.serviceCode.includes("fedex_ground"))),
      tap(() => {
        this.spinner.hide();
        this.form.updateValueAndValidity();
      }),
      catchError(s => {
        this.spinner.hide();
        return of({ error: s.message });
      }),
      take(1));
  }

  private getTotalSellingQuantity = () => this.products[0].sellingQuantity + this.products[1].sellingQuantity + this.products[2].sellingQuantity

  async goToPayment() {
    if (this.form.valid) {
      await this.submitUnpaidOrder();
      this.wizardComponent.goToNextStep()
    } else {
      this.updateState(this.form.controls);
      this.updateState(this.form.get('billingAddress')['controls']);
      this.updateState(this.form.get('shippingAddress')['controls']);


    }
  }


  updateState(controls: { [key: string]: AbstractControl; }) {
    for (const [key, control] of Object.entries(controls)) {
      control.markAllAsTouched();
      control.markAsDirty();
      control.updateValueAndValidity();
    }
  }

  updateQuantity(event, product) {
    event.preventDefault();
    product.sellingQuantity = Math.abs(event.target.valueAsNumber) * product.itemsPerBox;
    this.getRates();
  }


  //display order payment info
  //email is sent once order updated with payment confirmation
  //backend changes - updated payment with order id, update order with payment result, send email once order hcp order paid, don't sent hcp orders to WMS
  async paymentCompletedHandler(paymentResult) {
    this.spinner.show();
    this.order = await this.ordersService.getHcpOrderById(this.order.id);
    if (this.order.payment?.result.messages.resultCode == 'Ok'
      && this.order.payment?.result.transactionResponse?.responseCode == 1
      && !this.order.payment?.result.transactionResponse?.errors) {
      await this.sendOrderEmail();
      this.wizardComponent.goToNextStep();
    }
    this.spinner.hide();
  }

  async sendOrderEmail() {
    this.spinner.show();
    await this.ordersService.sendHcpOrderEmail(this.order);
    setTimeout(() => {
      this.spinner.hide();
    }, 5000);
  }

  calculateOrderSubTotal() {
    return this.products
      .map(p => this.getProductSubTotal(p))
      .reduce((p, c) => p + c);
  }

  calculateOrderTaxableSubTotal() {
    return this.calculateOrderSubTotal() + this.form.value.shipmentRate?.shippingAmount?.amount;
  }

  calculateOrderTotal() {
    return this.calculateOrderTaxableSubTotal() + this.calculateTax();
  }

  calculateTax() {
    return 0;
  }

  getProductSubTotal(product) {
    return product.sellingPrice * product.sellingQuantity;
  }

  async submitUnpaidOrder() {
    this.spinner.show();

    const form = this.form.getRawValue();
    const address = {
      email: form.email,
      firstName: form.firstName,
      lastName: form.lastName,
      phone: `+1${form.phone}`,
      ...form.shippingAddress
    };
    this.order = {
      id: '',
      date: parseInt(moment().format('x')),
      shippingTotal: this.round(form.shipmentRate.shippingAmount.amount),
      shipping: address,
      customer: {
        id: '',
        billingAddress: {
          ...address,
          shippingIsTheSame: true
        }
      },
      orderProducts: this.getOrderedProducts(),
      shipmentType: 'Single',
      shipmentRate: form.shipmentRate ?? { shippingAmount: { amount: 0 } },
      repEmail: 'hcp-order@diagnosticsolutionsgroup.com',
      repId: 'hcp-order',
      billShipmentTo: 'customer',
      orderExtension: this.calculateOrderSubTotal(),
      orderCommission: 0,
      orderBaseCost: this.calculateOrderSubTotal(),
      reference: ''
    };

    try {
      this.order = await this.ordersService.processHcpOrder(this.order);
      if (this.order.orderNumber) {
        this.orderId$.next(this.order.id);
      }
      this.spinner.hide();
    }
    catch (err) {
      console.log(err);
      this.spinner.hide();
    }
  }

  async submitOrderWithPaymentByInvoice() {
    this.spinner.show();
    this.order.addPaymentToInvoice = true;
    try {
      this.order = await this.ordersService.processHcpOrder(this.order);
      await this.sendOrderEmail();
      this.wizardComponent.goToNextStep();
    }
    catch (err) {
      console.log(err);
      this.spinner.hide();
    }
  }

  getOrderedProducts() {
    const products = this.products.filter(p => p.sellingQuantity > 0);
    return products.map(p => {
      return {
        baseCost: p.baseCost,
        commission: 0,
        name: p.productName,
        quantity: p.itemsPerBox,
        sellingPrice: p.sellingPrice,
        sellingQuantity: p.sellingQuantity,
        sellingPriceExtension: this.getProductSubTotal(p),
        wmsSku: p.wmsSku
      };
    })
  }

  finish() {
    window.location.assign(this.dsgWebsiteUrl);
  }


  isInvalid(controlName) {
    return this.form.get(controlName).dirty && this.form.get(controlName)?.invalid;
  }

  getOrderId() {
    return this.form.getRawValue().id;
  }

  round(v) {
    return +v.toFixed(2);
  }

  validateClia(cmsDataService: CmsDataService): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      return of(control.value).pipe(debounceTime(500), switchMap(c => cmsDataService.lookupByCLIA(c)),
        map((results: any[]) => {
          // if (!results || results.length == 0) return { 'invalidClia': 'CLIA # is invalid' };
          // if (results && moment(results[0].TRMNTN_EXPRTN_DT).isBefore()) return { 'expiredClia': 'CLIA # is expired' }
          return null;
        }));
    }
  };

  validateZip(formService: FormService): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      return of(control.value).pipe(debounceTime(500), switchMap(c => c.length == 5 ? formService.processZipCode(c) : null),
        map((data: any) => {
          if (!data || !data.ZipCode) {
            this.rates$ = EMPTY;
            control.parent.parent.get('shipmentRate').reset();
            control.parent.get('city').reset();
            control.parent.get('state').reset();
            return { 'invalidZip': 'Invalid Zip' };
          }
          control.parent.get('city').patchValue(data.City);
          control.parent.get('state').patchValue(data.State);
          return null;
        }));
    }
    //const group = this.form.get(groupName);
    //if (zip.length >= 5) {
    //  const data: any = await this.formService.processZipCode(zip);
    //  group.get('city').patchValue(data.City);
    //  group.get('state').patchValue(data.State);
    //} else if (zip.length == 0) {
    //  group.get('city').reset('');
    //  group.get('state').reset('');
    //  this.form.get('shipmentRate').reset();
    //}
  }

  hasError(controlName, error) {
    return this.form.get(controlName)?.hasError(error);
  }

}