import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API } from 'aws-amplify';
import { from, Observable, of } from 'rxjs';
import { retry, switchMap, timeout } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AccountingService {


  constructor(private httpClient: HttpClient) {
  }

  /*createSalesTax(customerId, shipDetails, products, isFinal) {
    return of({}).pipe(switchMap(() => from(this.putTransaction(customerId, shipDetails, products, isFinal))), timeout(10000), retry(3));
  }

  private putTransaction(customerId, shipDetails, products, isFinal) {
    return API.post('CXSalesTaxAPI', '/salesTax', {
      body:
      {
        dsgOrderId: shipDetails.id,
        customerId: customerId,
        //repEmail: form.rep,
        shipTo: {
          line1: shipDetails.shippingAddress.address,
          zipCode: shipDetails.shippingAddress.zip,
          state: shipDetails.shippingAddress.state,
          city: shipDetails.shippingAddress.city
        },
        isFinal: isFinal,
        orderLines: products.filter(p => p.sellingQuantity > 0).map(p => {
          return {
            amount: p.sellingPriceExtension,
            quantity: p.sellingQuantity / p.quantity,
            description: p.name,
            itemCode: ''
          };
        })
      }
    })
  }*/

  async getUrl() {
    return await API.get('CXAccountingAPI', '/accounting/qbo/auth', {});
  }

  async getProducts() {
    return await API.get('CXAccountingAPI', '/accounting/qbo/products', {});
  }

  async getToken(url) {
    return await API.post('CXAccountingAPI', '/accounting/qbo/token', {
      body: {
        url:url
      }
    })
  }
}