import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';


import { OrderListComponent } from './components/admin/order-list/order-list.component';
import { UsersListComponent } from './components/users-list/users-list.component';

import { RepOrderWizardComponent } from './components/reps/rep-order-wizard/rep-order-wizard.component';
import { CustomersListComponent } from './components/customers-list/customers-list.component';
import { CustomerEditorComponent } from './components/customer-editor/customer-editor.component';
import { AuthGuard } from './services/auth.guard';
import { CanDeactivateGuard } from './services/deactivate.guard';
import { ProductInfoComponent } from './components/product-info/product-info.component';

import { LoginComponent } from './components/reps/login/login.component';
import { RepRegistrationComponent } from './components/reps/rep-registration/rep-registration.component';
import { RepListComponent } from './components/admin/rep-list/rep-list.component';
import { RepDetailsComponent } from './components/reps/rep-details/rep-details.component';
import { HomeAdminComponent } from './components/admin/home-admin/home-admin.component';
import { CustomerOrderFormComponent } from './components/reps/customer-order-form/customer-order-form.component';
import { ProductsListComponent } from './components/admin/products-list/products-list.component';
import { HcpOrderFormComponent } from './components/hcp-order-form/hcp-order-form.component';

const routes: Routes = [

  { path: '', component: HomeComponent },
  
  { path: 'users/list', component: UsersListComponent, canActivate:[AuthGuard] },
  { path: 'products', component: ProductsListComponent, canActivate:[AuthGuard] },
  { path: 'products-info', component: ProductInfoComponent, canActivate:[AuthGuard]  },
  
  { path: 'login', component: LoginComponent },
  { path: 'rep/register', component: RepRegistrationComponent },
  { path: 'rep/details', component: RepDetailsComponent/*, canActivate:[AuthGuard] */},

  { path: 'order/:repId', component: CustomerOrderFormComponent },

  { path: 'rep/order', component: RepOrderWizardComponent, canActivate:[AuthGuard] },
  { path: 'rep/customers', component: CustomersListComponent, canActivate:[AuthGuard] },
  { path: 'rep/customers/add', component: CustomerEditorComponent, canActivate:[AuthGuard], canDeactivate:[CanDeactivateGuard] },

  { path: 'hcp/order/:email', component: HcpOrderFormComponent },

  { path: 'admin', component: HomeAdminComponent, canActivate:[AuthGuard] },
  { path: 'admin/orders-list', component: OrderListComponent, canActivate:[AuthGuard] },
  { path: 'admin/reps-list', component: RepListComponent, canActivate:[AuthGuard] },
  { path: 'admin/callback', component: HomeAdminComponent, canActivate:[AuthGuard] }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
