import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { ToastrService } from 'ngx-toastr';

import { RepsService } from '../../../services/reps.service';
import { Rep } from '../../../models/rep.model';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-rep-details',
  templateUrl: './rep-details.component.html',
  styleUrls: ['./rep-details.component.scss']
})
export class RepDetailsComponent implements OnInit {
  selectedTab = 'profile';
  repData = null;
  userData = null;

  accountTypes = [
    'Hospitals',
    'GPOs',
    'Urgent Care Centers',
    'Nursing Home / LTC',
    'Municipalities',
    'Schools', 
    'Colleges',
    'Sport Teams',
    'Group Practices',
    'Sole practitioners',
    'Physical Therapy Centers',
    'Corporations',
    'Others'
  ];
  stateList = environment.stateList;
  countryList = environment.countryList;

  constructor(
    private _router: Router,
    private _repService: RepsService,
    private _toastr: ToastrService
  ) { }

  async ngOnInit() {
    this.userData = await Auth.currentAuthenticatedUser();
    const email = this.userData.attributes.email;
    this.repData = await this._repService.getRep(email);
    console.log(this.userData);
  }

  async saveRepData() {
    try {
      const temp = await this._repService.createRep(this.repData);
      this._toastr.success('Data updated successfully')
    }
    catch(err) {
      this._toastr.error('There was an error updating your information', 'Please try aggin')
    }
  }

  updateTerritoryList(val) {
    if (this.repData.regionalTerritoryList.includes(val)) {
      this.repData.regionalTerritoryList.splice(this.repData.regionalTerritoryList.indexOf(val), 1)
    } else {
      this.repData.regionalTerritoryList.push(val);
    }
  }

  updateCountryList(val) {
    if (this.repData.internationalReach.includes(val)) {
      this.repData.internationalReach.splice(this.repData.internationalReach.indexOf(val), 1)
    } else {
      this.repData.internationalReach.push(val);
    }
  }

  updateAccounts(val) {
    if (this.repData.typeAccount.includes(val)) {
      this.repData.typeAccount.splice(this.repData.internationalReach.indexOf(val), 1)
    } else {
      this.repData.typeAccount.push(val);
    }
  }

  updateTerritoryScope(val) {
    if (val === 'National') {
      this.repData.regionalTerritoryList.length = 0;
    }
  }

}
