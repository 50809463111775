import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { Subject } from 'rxjs';
import { BillingAddress } from 'src/app/models/customer.model';
import { PaymentsService } from '../../../services/payments.service';
import { BasePaymentMethodsComponent } from '../base-payment-methods/base-payment-methods.component';
import { BasePaymentComponent } from '../base-payment-methods/base-payment.component';

@Component({
  selector: 'app-payment-methods-editor',
  templateUrl: './payment-methods-editor.component.html',
  styleUrls: ['./payment-methods-editor.component.scss']
})
export class PaymentMethodsEditorComponent extends BasePaymentComponent implements OnInit {
  @Input() customerId$ = new Subject<string>();
  @Input() billingAddress: BillingAddress;
  refresh$ = new Subject<void>();
  paymentMethod: any;
  

  constructor(protected paymentsService: PaymentsService, protected spinner: SpinnerVisibilityService) {
    super(spinner);
  }

  ngOnInit(): void {
    super.ngOnInit();
    //this.reset$.pipe(takeUntil(this.destroy$)).subscribe(r => {
    //  this.editorForm.reset({type: this.editorType});
    //  this.customerPaymentMethod = null;
    //  this.paymentResult = null;
    //  this.billingAddress = null;
    //  this.amount = null;
    //});
  }

  //unify events data format between payment components
  async onAddButtonClick() {
    this.spinner.show();
    if(!this.paymentMethod.valid) return Promise.resolve(false);
    const response = await this.paymentsService.add(this.customerId, this.paymentMethod.details);

    if(response) {
      this.refresh$.next();
    }
    this.spinner.hide();
  }

  async updateDefaultMethod(method) {
    this.paymentMethod = method;
    if(!method.details?.id || method.initial) return;
    this.spinner.show();
    const response = await this.paymentsService.updateCustomerDefaultPaymentMethod(method.details.id, this.customerId);
      if(response.data.success) {
        this.refresh$.next();
      } else {
        alert('Failed to change default payment method');
      }
    this.spinner.hide();
  }

  canShowActionButton() {
    return this.paymentMethod?.valid;
  }
}
