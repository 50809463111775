import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';



@Injectable({providedIn: 'root'})
export class CanDeactivateGuard<T> implements CanDeactivate<T> {
  canDeactivate(component: T, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const form = component['form'];
    const customer = component['customer'];
    const loading = component['loading'];
    const order = component['order'];
    if ((form.dirty || customer) && !loading && !order) {
      return window.confirm('Do you really want to cancel?');
    }
    return true;
  }

}
