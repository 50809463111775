import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Auth } from 'aws-amplify';
import { ToastrService } from 'ngx-toastr';

import { RepsService } from '../../../services/reps.service';
import { Rep } from '../../../models/rep.model';
import { environment } from '../../../../environments/environment';
import { OrdersService } from 'src/app/services/orders.service';

enum RegisterState {
  RepDataForm,
  RepDetailsForm,
  RepInternationalForm
}

@Component({
  selector: 'app-rep-registration',
  templateUrl: './rep-registration.component.html',
  styleUrls: ['./rep-registration.component.scss']
})
export class RepRegistrationComponent implements OnInit {
  repData: Rep = null;
  accountTypes = [
    'Hospitals',
    'GPOs',
    'Urgent Care Centers',
    'Nursing Home / LTC',
    'Municipalities',
    'Schools', 
    'Colleges',
    'Sport Teams',
    'Group Practices',
    'Sole practitioners',
    'Physical Therapy Centers',
    'Corporations',
    'Others'
  ];
  stateList = environment.stateList;
  countryList = environment.countryList;

  repRegState = RegisterState;
  currentState;
  showPassword = true;

  other1 = '';
  other2 = '';
  password = '';
  password2 = '';

  registerResult = false
  showRegisterResultModal = false;
  errorMessage = '';

  constructor(
    private _router: Router,
    private _repService: RepsService,
    private _orderService: OrdersService,
    private _toastr: ToastrService
  ) {
    this.currentState = RegisterState.RepDataForm;
   }

  async ngOnInit() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      this._router.navigate(['./']);
    }
    catch (err) {
      this.repData = {
        firstName: '',
        lastName: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        cell: '',
        email: '',
        tradingLlcName: '',
        ssnEin: '',
        yearsInHealthcare: '',
        experienceDiagnostics: '',
        experienceCovid: '',
        territoryScope: '',
        regionalTerritoryList: [],
        typeAccount: [],
        internationalReach: [],
        reach: '',
        structure: '',
        structureNumber: '',
        bankRoutingNumber: '',
        bankAccountNumber: '',
        relatedTests: '', 
        repId: ''
      }
    }
  }

  updateAccounts(val) {
    if (this.repData.typeAccount.includes(val)) {
      this.repData.typeAccount.splice(this.repData.typeAccount.indexOf(val), 1)
    } else {
      this.repData.typeAccount.push(val);
    }
  }

  updateTerritoryScope(val) {
    if (val === 'National') {
      this.repData.regionalTerritoryList.length = 0;
    }
  }

  updateTerritoryList(val) {
    if (this.repData.regionalTerritoryList.includes(val)) {
      this.repData.regionalTerritoryList.splice(this.repData.regionalTerritoryList.indexOf(val), 1)
    } else {
      this.repData.regionalTerritoryList.push(val);
    }
  }
  updateCountryList(val) {
    if (this.repData.internationalReach.includes(val)) {
      this.repData.internationalReach.splice(this.repData.internationalReach.indexOf(val), 1)
    } else {
      this.repData.internationalReach.push(val);
    }
  }

  next() {
    switch(this.currentState) {
      case RegisterState.RepDataForm:
        this.validateForm();
        break;
      case RegisterState.RepDetailsForm:
        this.validateDetails();
        break;
      case RegisterState.RepInternationalForm: 
        this.validateInternational();
        break;
    }
  }

  back() {
    switch(this.currentState) {
      case RegisterState.RepDetailsForm:
        this._updateState(RegisterState.RepDataForm);
        break;
      case RegisterState.RepInternationalForm: 
        this._updateState(RegisterState.RepDetailsForm);
        break;
    }
  }

  validateForm() {
    let isValid = true;
    const errorMessages = [];
    if (this.password === '') {
      isValid = false;
      errorMessages.push('- Password missing.')
    }
    if (this.password2 === '') {
      isValid = false;
      errorMessages.push('- Confirmation password missing.')
    }
    if ((this.password !== this.password2) && (this.password !== '' && this.password2 !=='')){
      isValid = false;
      errorMessages.push('Password and the confirmation should be the same.')
    } else {
      if (this.password.length < 8) {
        isValid = false;
        errorMessages.push('Password should be at least 8 characters long.');
      }
    }
    if (this.repData.firstName === '') {
      isValid = false;
      errorMessages.push('- First Name missing.');
    }
    if (this.repData.lastName === '') {
      isValid = false;
      errorMessages.push('- Last Name missing.');
    }
    if (this.repData.city === '') {
      isValid = false;
      errorMessages.push('- City missing.');
    }
    if (this.repData.state === '') {
      isValid = false;
      errorMessages.push('- State missing.');
    }
    if (this.repData.zip === '') {
      isValid = false;
      errorMessages.push('- Zip Code missing.');
    }
    if (this.repData.tradingLlcName === '') {
      isValid = false;
      errorMessages.push('Trading-as or LLC Name missing.');
    }
    if (this.repData.ssnEin === '') {
      isValid = false;
      errorMessages.push('- SS# or EIN missing.');
    }
    if (this.repData.bankAccountNumber === '') {
      isValid = false;
      errorMessages.push('- Bank account number missing.');
    }
    if (this.repData.bankRoutingNumber === '') {
      isValid = false;
      errorMessages.push('- Bank routing number missing.');
    }
    
    if (isValid) {
      this._updateState(RegisterState.RepDetailsForm);
    } else {
      this._toastr.error(errorMessages.join('<br>'), 'Validation errors')
    }
  }

  validateDetails() {
    let isValid = true;
    const errorMessages = [];
    if (this.repData.yearsInHealthcare === '') {
      isValid = false;
      errorMessages.push('- Years in healthcare missing.');
    }
    if (this.repData.experienceDiagnostics === '') {
      isValid = false;
      errorMessages.push('- Level of experience with diagnostics missing.');
    }
    if (this.repData.experienceCovid === '') {
      isValid = false;
      errorMessages.push('- Level of experience with COVID-19 missing.');
    }
    if (this.repData.territoryScope === '') {
      isValid = false;
      errorMessages.push('- USA territory scope missing.');
    }
    if (this.repData.structure === '') {
      isValid = false;
      errorMessages.push('- How many reps do you manage missing.');
    }
    if (this.repData.reach === '') {
      isValid = false;
      errorMessages.push('- Reach missing.');
    }
    if (this.repData.typeAccount.length < 1) {
      isValid = false;
      errorMessages.push('- Must select at least one type of account.');
    }

    if (isValid) {
      if (this.repData.reach !== 'USA only') {
        this._updateState(RegisterState.RepInternationalForm);
      } else {
        this._signUpRep();
      }
    } else {
      this._toastr.error(errorMessages.join('<br>'), 'Validation errors')
    }
  }

  validateInternational() {
    this._signUpRep();
  }

  validatePassword() {
    const t = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;

    if (t.test(this.password)) {
      return true;
    }
    return false;
  }

  validatePassword2() {
    const t = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;
    if (t.test(this.password) && this.password === this.password2) {
      return true;
    }
    return false;
  }

  getButtonLabel() {
    let label = 'Next';
    switch(this.currentState) {
      case RegisterState.RepDetailsForm:
        if (this.repData.reach === 'USA only') {
          label = 'Register';
        }
        break;
      case RegisterState.RepInternationalForm: 
        label = 'Register';
        break;
    }

    return label;
  }

  okRegisterModal() {
    if (this.registerResult) {
      this._router.navigate(['login']);
    } else {
      this.showRegisterResultModal = false;
      this.registerResult = false;
    }
  }

  async checkZipCode() {
    if (this.repData.zip && this.repData.zip.toString().length === 5) {
      try {
        const zipData: any = await this._orderService.processZipCode(this.repData.zip);
        if (!!zipData.City || !!zipData.State) {
          this.repData.city = zipData.City;
          this.repData.state = zipData.State;
        } else {
          this.repData.city = '';
          this.repData.state = '';
          this.repData.zip = '';
        }
      }
      catch (err) {
        this.repData.city = '';
        this.repData.state = '';
        this.repData.zip = '';
      }
    }
  }

  private async _signUpRep() {
    try {
      const signUpParams = {
        username: this.repData.email,
        password: this.password,
        attributes: {
          name: this.repData.firstName,
          family_name: this.repData.lastName,
          phone_number: `+1${this.repData.cell}`,
          // phone_number_verified: "true",
          email: this.repData.email,
          // email_verified: "true"
        }
      }

      if (this.other1 !== '') {
        this.repData.otherTypeAccount1 = this.other1;
      }
      if (this.other2 !== '') {
        this.repData.otherTypeAccount2 = this.other2;
      }
      const user = await Auth.signUp(signUpParams);
      
      this.repData.repId = user.userSub;
      const temp = await this._repService.createRep(this.repData);

      this.showRegisterResultModal = true;
      this.registerResult = true;
    }
    catch(err) {
      this.showRegisterResultModal = true;
      this.registerResult = false;
      this.errorMessage = err.message;
      console.log(err);
    }
  }

  private _updateState(newState: RegisterState): void {
    this.currentState = newState;
  }

}
