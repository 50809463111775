import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({providedIn: 'root'})
export class EmailService {
    constructor(private httpClient: HttpClient) { }
    

    async sendOrderRequestToRep(repEmail, shipDetails, order, paymentDetails, addPaymentToInvoice) {
        const emailHtml = await this._generateFormOrderEmail(order, repEmail, shipDetails, paymentDetails, addPaymentToInvoice);
        const url = `https://m47t3zb7ne.execute-api.us-east-1.amazonaws.com/prod/email`;

        return this.httpClient.post(url, {to: repEmail, cc: environment.ccOrderRequest, html: emailHtml}).toPromise();
    }
   
      private async _generateFormOrderEmail(order, repEmail, shipDetails, paymentDetails, addPaymentToInvoice) {
        try {
          const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          
            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
          });
            let totalOrder = 0;
            let subTotalOrder = 0;
            let shippingSubTotal = 0;
            const d = new Date();
            const orderDate = d.toLocaleDateString();
            
            order.orderProducts.forEach(item => {
              totalOrder += (item.sellingQuantity * item.sellingPrice);
              subTotalOrder += (item.sellingQuantity * item.sellingPrice);
              // shippingSubTotal += (item.shippingPrice * 1);
              // totalOrder += (item.shippingPrice * 1);
            });

            shippingSubTotal = shipDetails.shipmentRate.shippingAmount.amount;
            totalOrder += shippingSubTotal;
            const totalBill = shipDetails.billShipmentTo == 'customer' ? totalOrder : order.orderExtension;
            
            let template = `
            <table class="table is-fullwidth" style="border: none; width: 100%;">
              <tr>
                <td  style="border: 1px solid black; padding: 10px;"><img src="https://d336e2ayaeatn5.cloudfront.net/assets/dsg_logo.png"></td>
              </tr>
              <tr>
                <td  style="border: 1px solid black; padding: 10px;">
                  <h1 class="title is-3">Order Confirmation</h1>
                  <h3>Reference:${order.reference ?? ''}</h3>
                </td>
              </tr>
            </table>

            <table class="table is-fullwidth" style="width: 100%;border-collapse: collapse;border: 1px solid black;">
            <tr style="background-color: #ccc">
              <th>Order Date</th>
              <th>Shipping Method</th>
              <th>Ship Via</th>
              <th>Bill Shipment to</th>
            </tr>
            <tr>
              <td style="border: 1px solid black; padding: 10px;">${orderDate}</td>
              <td style="border: 1px solid black; padding: 10px;">${shipDetails.shipmentRate.carrierFriendlyName ?? ''}</td>
              <td style="border: 1px solid black; padding: 10px;">${shipDetails.shipmentRate.serviceType ?? ''}</td>
              <td style="border: 1px solid black; padding: 10px;">${shipDetails.billShipmentTo == 'customer' ? 'CUSTOMER' : 'REP'}</td>
            </tr>
          </table>

            <br/>
            <br/>
            <table class="table is-fullwidth" style="width: 100%;border-collapse: collapse;border: 1px solid black;">
              <tr style="background-color: #ccc">
                <th>Ship To</th>
                <th>Bill To (Customer)</th>
                <th>Payment</th>
              </tr>
              <tr>
                <td style="border: 1px solid black; padding: 10px;">
                  ${shipDetails.shippingAddress.firstName + ' ' + shipDetails.shippingAddress.lastName}<br />
                  ${shipDetails.shippingAddress.address }<br />
                  ${shipDetails.shippingAddress.city + ', ' + shipDetails.shippingAddress.state + ' ' + shipDetails.shippingAddress.zip }<br>
                  USA<br />
                  ${shipDetails.shippingAddress.phone}
                </td>
                <td style="border: 1px solid black; padding: 10px;">
                  ${shipDetails.billingAddress.title + ' ' + shipDetails.billingAddress.firstName + ' ' + shipDetails.billingAddress.lastName}<br />
                  CLIA #:${shipDetails.clia}<br />
                  ${shipDetails.billingAddress.address }<br />
                  ${shipDetails.billingAddress.city + ', ' + shipDetails.billingAddress.state + ' ' + shipDetails.billingAddress.zip }<br>
                  USA<br />
                  ${shipDetails.billingAddress.phone}<br />
                  ${shipDetails.billingAddress.email}<br />
                </td>
                <td style="border: 1px solid black; padding: 10px;">
                ${totalBill > 15000  ? 'ACH' : ''}
                ${totalBill <= 15000 && addPaymentToInvoice ? 'Credit card payment via the invoice': ''}<br />
                ${totalBill <= 15000 && paymentDetails?.result?.transactionResponse && !addPaymentToInvoice ? 'Card Type: ' + paymentDetails?.result?.transactionResponse?.accountType : ''}<br />
                ${totalBill <= 15000 && paymentDetails?.result?.transactionResponse && !addPaymentToInvoice ? 'Card Number: ' + paymentDetails?.result?.transactionResponse?.accountNumber : ''}<br />
                ${totalBill <= 15000 && paymentDetails?.result?.transactionResponse && !addPaymentToInvoice ? 'Amount Charged:' + formatter.format(totalBill) : '' }
              </td>
              </tr>
              <tr>
              </td>
            </table>
            <br/><br/>
            <table class="table is-fullwidth" style="width: 100%;border-collapse: collapse;border: 1px solid black;">
              <tr style="background-color: #ccc">
                <th style="text-align: center;" colspan="2" class="has-text-centered">Product</th>
                <th style="text-align: center;" class="has-text-centered">Qty</th>
                <th style="text-align: center;" class="has-text-centered">Price</th>
                <th style="text-align: center;" class="has-text-centered" style="width: 150px">Total</th>
              </tr>`;
              order.orderProducts.forEach(item => {
                template += `
                <tr *ngFor="let item of order.orderProducts">
                  <td colspan="2" style="vertical-align: middle;text-align: center;" class="has-text-centered">${item.name} Box of ${item.quantity}</td>
                  <td style="vertical-align: middle;text-align: center;" class="has-text-centered">${item.sellingQuantity/item.quantity}</td>
                  <td style="vertical-align: middle;text-align: center;" class="has-text-centered">${formatter.format(item.sellingPrice)}</td>
                  <td style="vertical-align: middle;text-align: center;" class="has-text-centered">${formatter.format((item.sellingQuantity * item.sellingPrice))}</td>
                </tr>`  
              });
              
              template += `
              <tr style="background-color: #ccc">
                <td colspan="5"><br></td>
              </tr>
              <tr>
                <th colspan="4" style="text-align: right" class="has-text-right">Subtotal:</th>
                <td class="has-text-right" style="text-align: right;">${formatter.format(subTotalOrder)}</td>
              </tr>
              <tr>
              <td colspan="3"></td>
              <th style="background-color: #ccc; text-align: right" class="has-text-right">Shipping:</th>
              <td style="background-color: #ccc; text-align: right" class="has-text-right">${formatter.format(shippingSubTotal)}</td>
            </tr>
              <tr>
                <td colspan="3"></td>
                <th style="text-align: right" class="has-text-right">Total</th>
                <td style="text-align: right" class="has-text-right">${formatter.format(totalOrder )}</td>
              </tr>
              <tr>
                <td colspan="5">
                  <h1 style="text-align: center" class="title is-2 has-text-centered">Thank you for your order!</h1>
                </td>
              </tr>
            </table> `;
          
            return template;
        }
        catch(err) {
          throw(err);
        }
      }
}