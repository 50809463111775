import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerVisibilityService } from 'ng-http-loader';

import { Auth } from 'aws-amplify';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit {
  private userData;
  private adminEmails = [
    'acordoba@diagnosticsolutionsgroup.com',
    'jscrocco@diagnosticsolutionsgroup.com',
    'kweller@diagnosticsolutionsgroup.com'
  ];

  constructor(
    private router: Router,
    private spinner: SpinnerVisibilityService,
    private activatedRoute: ActivatedRoute) { }

  async ngOnInit() {
    try {
      this.userData = await Auth.currentAuthenticatedUser();
      // if (this.adminEmails.includes(this.userData.attributes.email)) {
      //   this.getOrdersList();
      // } else {
      //   this.getOrdersList(this.userData.attributes.email);
      // }
      this.spinner.hide();
    }
    catch(err) {
      console.log(err);
      this.spinner.hide();
      this.router.navigate(['']);
    }
  }

}
