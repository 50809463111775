import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { OrdersService } from 'src/app/services/orders.service';

@Component({
  selector: 'app-base-form',
  templateUrl: './base-form.component.html',
  styleUrls: ['./base-form.component.scss']
})
export class BaseFormComponent implements OnInit {

  constructor(protected ordersService: OrdersService) { }

  ngOnInit(): void {
  }
  protected form: FormGroup;

  async checkZipCode(zipControl, cityControl, stateControl) {
    const zipField = this.form.get(zipControl);
    const zip = zipField.value;
    const city = this.form.get(cityControl);
    const state = this.form.get(stateControl);
    if (zip && zip.toString().length === 5) {
      try {
        const zipData: any = await this.ordersService.processZipCode(zip);
        if (!!zipData.City || !!zipData.State) {
          city.setValue(zipData.City);
          state.setValue(zipData.State);
        } else {
          city.setValue('');
          state.setValue('');
          zipField.setValue('');
        }
      }
      catch (err) {
        city.setValue('');
        state.setValue('');
        zipField.setValue('');
      }
    } else {
      city.setValue('');
      state.setValue('');
    }
  }

  isInvalid(controlName) {
    const control = this.form.get(controlName);
    return (control?.disabled && !control?.value) || control?.invalid;
  }

  hasError(controlName, error) {
    return this.form.get(controlName).touched && this.form.get(controlName)?.hasError(error);
  }

  arrayItemHasError(rowIndex, arrayName, controlName, errors: string[]) {
    const group = this.form.get(arrayName)['controls'][rowIndex];
    const control = group?.get(controlName) as FormControl;
    //const quantity = group?.get('sellingQuantity') as FormControl;
    return  errors.map(e => control?.hasError(e)).find(result => result);
  }

  validateQuantity(c: FormControl, value) {
    return c.value % value == 0 ? null : {
      quantity: {
        valid: false
      }
    };
  }

}
